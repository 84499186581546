import React from 'react';
import '../../../styles/flow.css';
import {Modal,Button } from 'react-bootstrap';
import{getParFasList}from "../../../actions/report_actions";
import {get} from "../../../helpers/storage_helper";
import {getCurrencyDetails} from "../../../actions/report_actions";
import { Link} from 'react-router-dom';
import { BasicReactTable } from '../../../helpers/react_table'

import FlowComponent from '../../common/component/core/FlowComponent';
import CommonReactTable from '../../../helpers/commonReact_table';
import DataGridTable from '../../CommonComponent/table';
import { Box } from '@mui/material';
import ExportCsv from '../../common/component/ExportCsv';
import { set_id_for_rows } from '../../../helpers/common_helper';

export default class ViewParFas extends FlowComponent{

    state ={
        modalBox : false,
        rowdatas:[],
        columndatas:[], 
        copy:[],
        currencyDetails:{},
        currencyCode:"",
        currencyValue:"",
        loader : false,
        page: { page: 0, pageSize: 10 },
       
    }

    componentDidMount(){
  
       
    }
   
    handleModelOpen =async()=>{
        this.setState({ modalBox: true });

        this.setState({loader:true})
        await getParFasList({"country_code":this.props.country_code, "acc_prvdr_code":this.props.lender == null ? this.props.acc_prvdr_code : null, "par_day":this.props.parday, "date":this.props.date ,"time_zone":get('market').time_zone,"lender" : this.props.lender})
        .then((response)=>{
            var currency_value= this.props.state_data.country_code=="*" ?this.props.state_data.activeCurrencyVal:null
            var currency_code= this.props.state_data.country_code=="*" ?this.props.state_data.activeCurrency:
            
            ( get('market')?.currency_code)
            this.setState({loader:false})
            if(!response){return }
            if(response.status=="success")
            { 
             
               let rows = response.data?.map((row, index) => ({
                    serial_number: index + 1, 
                    ...row, 
                  }));

                this.setState({rowdatas:rows,copy : set_id_for_rows(rows) ,columndatas:this.columndatas(),par_fa: this.convertExportCsvDatas(response.data, currency_value,currency_code,["par_loan_principal"]),
                header_props:this.convert_header_props(this.columndatas(),this.props.state_data.activeCurrency,["par_loan_principal"])})
				
           }
        })
    }

    handleModalClose = (event) => {
      this.setState({ modalBox: false });
    }
    amount_conversion = (amount, country_code) => {
        const { country_code: propsCountryCode, state_data } = this.props;
    
        if (propsCountryCode === country_code) {
          const currency_code = get('market')?.currency_code;
          return amount.toLocaleString() + " " + currency_code;
        } else{
            const forexRate = state_data.currencyDetails[country_code].forex_rates["USD"];
            return Math.round(amount * Number(forexRate)) + " USD";
            
        }
      
       
      };
      

    columndatas(){
        return[
            {
                field: 'serial_number',
                headerName: 'S. No.',
                headeralign: 'center',align: 'center'},
                
            {
                headerName : "FA ID",
                field: "loan_doc_id", flex:1 , headerAlign: 'center',align: 'center',
                renderCell: cell => {return <div className='text-center'> <Link to={"/fa/view/"+cell.row.loan_doc_id} target="_blank" className="on_going">{cell.row.loan_doc_id}</Link></div>}         
            },
            {
                headerName : "PAR Amount",
                field: "par_loan_principal", flex:1 , headerAlign: 'center',align: 'center',
                renderCell:  cell => {return <><div className='text-center mr-5'>{this.amount_conversion(cell.row.par_loan_principal,cell.row.country_code)}</div></>}      
               
            }
        ]
    }


    render(){


		return(

		<div className="" style={{'padding-left':'0px'}} >
		    <div className="pull">   
                <button type="button" class="btn btn-primary" onClick={this.handleModelOpen} >View</button>	
	        </div>
	    
		   <Modal show={this.state.modalBox} onHide={this.handleModalClose} size="lg">
             	<Modal.Header closeButton show={this.state.modalBox} onHide={this.handleModalClose} >
                 	 <Modal.Title><h4>FAs List</h4></Modal.Title>
              	</Modal.Header>
              	<Modal.Body>
                  <div>
		            
					 <div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
						<p className='no-margin text-white'><b>Total Entries : {this.state.copy.length}</b></p>
						
					</div>
                    {this.state.copy?.length>0 &&
                    <Box display={'flex'} justifyContent={'end'}>
						<ExportCsv
						export_csv_datas={ this.state.copy??[]}
						csvFileName = {'FA Lists'}
						paginationMode= { "client"}
						columns={this.state.columndatas??[]}
					/>
					</Box>}
                    {this.state.loader ? <>
                    <p className='text-light mt-5 text-center' >Loading...</p>
                    <div className="product_takeup_loader "></div></>
                    :
                    // <BasicReactTable className = 'border' row_data={this.state.copy !== undefined ? this.state.copy : []} export_csv_datas={this.state.par_fa}  column_data={this.state.columndatas} csv_file={true} FName="PARfasList" default_page_size={10} pagination={true}    global_search={false}/> 

                    <DataGridTable
					rows={this.state.copy??[]}
											columns={this.state.columndatas??[]}
											paginationMode= { "client"}
											sortingMode={"client"}
											page={this.state.page}
											rowCount={this.state.copy?.length??0}
											setpage={(newPage) => this.setState({ page: newPage })}
											isLoading={false }	
					/>

					
                  }                                       
		        </div>
             	</Modal.Body>
             	<Modal.Footer>
                	<Button variant="secondary" onClick={this.handleModalClose}>Close</Button>
              	</Modal.Footer>
          	</Modal>
		

		    </div>

			);
		}
	
}