import React from 'react';
import FlowContainer from '../../common/container/core/FlowContainer';
import {list_lender_txn_stmts} from '../../../actions/lender_actions';
import $ from 'jquery';
import {rt_algn,lbl_amt} from '../../../helpers/common_helper';
import {format_date_tbl} from '../../../helpers/common_helper';
import {dd_value} from '../../../actions/common_actions';
import {ImArrowUpRight,ImArrowDownLeft} from "react-icons/im";
import {Button,Modal} from 'react-bootstrap';
import { Link} from 'react-router-dom';
import ReconStatus from '../../lender/component/ReconStatus';
import {get_repayment_summary} from '../../../actions/loan_actions';
import {create_capture_payment} from '../../../actions/loan_actions';
import moment from 'moment';
import withRouter from '../../../Router/Withrouter';
import { BasicReactTable } from '../../../helpers/react_table';
import { find_id } from '../../../helpers/product_helper';

class ListTodayAccountStmtTxnContainer extends FlowContainer{

  state = {
    last_updated : null,
    updated_since : 0,
    timer :0,
    buttonName : 'Pause Refresh',
    btn_variant : 'danger',
    timerID : '',
    modalBox : true,
    modal: null,
    end_time:'',
    row_data:[],
    column_data:[],
    loader:false

  }
 
  constructor(){
    super()
    this.REFRESH_IN = 120
  }
 componentWillUnmount() {
    
    clearInterval(this.timerID);
  }
  
  tick() {
      if(this.state.timer !=0){
        this.setState({
          timer: this.state.timer - 1,
          updated_since : this.REFRESH_IN - this.state.timer 
        });
      }else{
      
      this.refreshPaymentsList();
      }
    }

  loan_doc_id(txn){
      const loan_doc_id = txn.loan_doc_id
      const id = txn.id
      const txn_type = txn.stmt_txn_type
      if(loan_doc_id && txn_type == "credit"){
        return <Button variant="danger" id={id} onClick={(e) => this.capture_repayment(e,loan_doc_id)}>Capture Payment</Button>
      }
    }

  handleClose = (event) => {
      this.setState({modalBox: false,selected_payment : null});
  }

  handleSubmit = (event) =>{
      const resp = window.confirm("Are you sure capture the payment?");
      if(resp == false){
        return;
      }
    const request = {loan_doc_id : this.state.selected_payment.loan_doc_id,
                     acc_stmt_txn_id : this.state.selected_payment.acc_stmt_txn_id, 
                     amount : this.state.selected_payment.amount}
    create_capture_payment (this.req(request))
    .then((response)=>{
      
        if(!response){return null;}

        if(response.status === "success"){
            alert('Payment captured successfully')
            this.handleClose()
        }else{
              alert(response.message)
        }
    })
  }

  capture_repayment = (event, loan_doc_id) => {
    const request = {acc_stmt_txn_id : event.target.id, loan_doc_id};
    get_repayment_summary(this.req(request))
        .then((response) => {
            if(!response){return null;}
            if(response.status === "success"){
              this.setState({modalBox: true, selected_payment : response.data});
            }else{
              alert(response.message)
            }
      })
  // loan_doc_id, acc_Stmt_id, amount

  }



    componentWillMount()
    {

     this.refreshPaymentsList();
     this.gettime();

    }

   gettime(){
    // let d1 =this.state.end_time
    // var d2 = new Date();
    // // console.log('d1', this.state.end_time)
    // // var a=Math.floor(new Date()-this.state.end_time)
    // var a=(d2-d1)
    // console.log('a', a)
    // console.log('Math.round(d.getTime()', Math.round(new Date().getTime()))
    // console.log('(time / 60) % 60', (a /1000 * 3600 * 24))


 
   }
    chk_acc_type(cr_amt,dr_amt){
        if (cr_amt === 0 && dr_amt === 0) {
          return null
        }
        else if (cr_amt === 0){
          return <ImArrowUpRight color="red"/>
        }
        else if (dr_amt === 0){
          return <ImArrowDownLeft color = "green"/>
        }else{
          return null
        }

    }

    chk_fa_id(record){

      const loan_doc_id = record.loan_doc_id
      const recon_status = record.recon_status
      const endpoint = find_id(loan_doc_id)
    if(loan_doc_id){
      if (recon_status == "10_capture_payment_pending"){
        return <span ><Link style = {{color:"grey"}} to={endpoint+loan_doc_id} target="_blank" >{loan_doc_id}</Link></span>
      }else if(recon_status == "80_recon_done"){
        return <span ><Link to={endpoint+loan_doc_id} style = {{color:"#007bff"}} target="_blank" >{loan_doc_id}</Link></span>
      }
      return <Link to={endpoint+loan_doc_id} style = {{color:"grey"}}  target="_blank" >{loan_doc_id}</Link>
    }else{
      return "NA"
    } 
    }

   
    pauseRefresh = () => {
        if(this.timerID){
            clearInterval(this.timerID);
            this.timerID = null
            this.setState({buttonName : 'Start Refresh',
                           btn_variant : 'success' })    
        }else{
            this.timerID = setInterval(() => this.tick(),1000);
            this.setState({buttonName : 'Pause Refresh',
                           btn_variant : 'danger'})
        }
        
        

    }

    generate_column_datas = ()=>{
      return [
        {
          Header : <div className='d-flex justify-content-center'>SI No</div>, 
          id: "no",	
          enableSortBy:  false,						       
          Cell: ({row}) => {
            return <div className='d-flex justify-content-center'>{((row.index+1))}</div>;
          }
        },
        {
          Header : <div className='d-flex justify-content-center'>Transaction Type</div>, 
          id: "stmt_txn_type",	
          accessor : 'stmt_txn_type'	,		
          enableSortBy:  false,			       
          Cell: (cell) => {
            return <div className='d-flex justify-content-center'>{dd_value(cell.row.original.stmt_txn_type)}</div>;
          }
        },
        {
          Header : <div className='d-flex justify-content-center'>Credit</div>, 
          id: "cr_amt",	
          accessor : 'cr_amt'	,				       
          Cell: (cell) => {
            return <div className='d-flex justify-content-center'>{lbl_amt(cell.row.original.cr_amt, this.currency_code)}</div>;
          }
        },
        {
          Header : <div className='d-flex justify-content-center'>Debit</div>, 
          id: "dr_amt",	
          accessor : 'dr_amt'	,				       
          Cell: (cell) => {
            return <div className='d-flex justify-content-center'>{lbl_amt(cell.row.original.dr_amt, this.currency_code)}</div>;
          }
        },
        {
          Header : <div className='d-flex justify-content-center'>Balance</div>, 
          id: "balance",	
          accessor : 'balance'	,				       
          Cell: (cell) => {
            return <div className='d-flex justify-content-center'>{lbl_amt(cell.row.original.balance, this.currency_code)}</div>;
          }
        },
        {
          Header : <div className='d-flex justify-content-center'>Transaction ID</div>, 
          id: "stmt_txn_id",	
          accessor : 'stmt_txn_id'	,		
          enableSortBy:  false,			       
          Cell: (cell) => {
            return <div className='d-flex justify-content-center'>{cell.row.original.stmt_txn_id}</div>;
          }
        },
        {
          Header : <div className='d-flex justify-content-center'>Description</div>, 
          id: "descr",	
          accessor : 'descr'	,		
          enableSortBy:  false,			       
          Cell: (cell) => {
            return <div className='d-flex justify-content-center'>{cell.row.original.descr}</div>;
          }
        },
        {
          Header : <div className='d-flex justify-content-center'>Transaction Date</div>, 
          id: "stmt_txn_date",	
          accessor : 'stmt_txn_date'	,				       
          Cell: (cell) => {
            return <div className='d-flex justify-content-center'>{format_date_tbl(cell.row.original.stmt_txn_date, true)}</div>;
          }
        },
        {
          Header : <div className='d-flex justify-content-center'>Matching ID</div>, 
          id: "matching_fa",	
          accessor : 'matching_fa'	,	
          enableSortBy:  false,				       
          Cell: (cell) => {
            return <div className='d-flex justify-content-center'>{this.chk_fa_id(cell.row.original)}</div>;
          }
        },
        {
          Header : <div className='d-flex justify-content-center'>Recon Status</div>, 
          id: "recon_status",	
          accessor : 'recon_status'	,	
          enableSortBy:  false,				       
          Cell: (cell) => {
            return <div className='d-flex justify-content-center'>{<ReconStatus show_btn = {false}  data={cell.row.original} onSync = {this.refreshPaymentsList}/>}</div>;
          }
        },
        {
          Header : <div className='d-flex justify-content-center'>Action</div>, 
          id: "action",	
          accessor : 'action'	,		
          enableSortBy:  false,			       
          Cell: (cell) => {
            return <div className='d-flex justify-content-center'>{<ReconStatus show_btn = {true}  data={cell.row.original} onSync = {this.refreshPaymentsList}/>}</div>;
          }
        },

      ]
    }


    refreshPaymentsList = () =>{
      this.setState({loader:true})
       
        clearInterval(this.timerID);
        var statement_search = {recent_txns : true}
        statement_search.account_id = this.props.params.acc_id;

        list_lender_txn_stmts(this.req({statement_search}))
            .then((response) => {
                // var prev_table = $('#statement_search').DataTable();
                // prev_table.destroy();
                this.setState({loader:false})
                if(!response)
                {
                    return null;
                }

                if(response.status === "success"){

                    var account_num = response.data.acc_txt;
                    const d = new Date(response.server_time);
                    const d2=new Date(response.data.float_acc_stmt_end_time);
                    let diff = ((Math.round(d.getTime()-d2.getTime()))/(1000*60));
                    this.setState({time_diff:Math.round(diff)})
                    this.setState({end_time : response.data.float_acc_stmt_end_time})
                    // const lenderTxnStmts = response.data.account_txns.map((txn,i)=>
                    //       <tr key={i}>
                    //       <td>{++i}</td>
                    //       <td > {dd_value(txn.stmt_txn_type)}&nbsp;&nbsp;{this.chk_acc_type(txn.cr_amt,txn.dr_amt)}</td>
                    //       <td > {rt_algn(lbl_amt(txn.cr_amt, this.currency_code))}</td>
                    //       <td > {rt_algn(lbl_amt(txn.dr_amt, this.currency_code))}</td>
                    //       <td > {rt_algn(lbl_amt(txn.balance, this.currency_code))}</td>
                    //       <td > {txn.stmt_txn_id}</td>
                    //       <td > {txn.descr}</td>
                    //       <td > {format_date_tbl(txn.stmt_txn_date,true)}</td>
                    //       <td>{this.chk_fa_id(txn)}</td>
                    //       <td><ReconStatus show_btn = {false}  data={txn} onSync = {this.refreshPaymentsList}/></td>
                    //       <td><ReconStatus show_btn = {true}  data={txn} onSync = {this.refreshPaymentsList}/></td>
                    //       </tr>
                    //  );
                    this.timerID = setInterval(() => this.tick(),1000);
                    var dt = new Date()
                    dt.setMinutes( dt.getMinutes() - 2 );
                    this.setState({last_updated: (dt.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric',second: 'numeric', hour24: true })).toString(), 
                                    timer : this.REFRESH_IN,
                                    // lenderTxnStmts,
                                    account_result:"show_div",
                                    account_num:  account_num ,
                                    row_data: response.data.account_txns,
                                  column_data:this.generate_column_datas()});
                    
                        // $('#statement_search').removeAttr('width').dataTable({
                        //     "pageLength": 20,
                        //     "order" : [],
                        //         "columns": [
                        //           {"width" : "10%"},
                        //           {"width": "10%"},
                        //           {"width": "10%"},
                        //           {"width": "15%"},
                        //           {"width": "15%"},
                        //           {"width": "10%"},
                        //           {"width": "10%"},
                        //           {"width": "10%"},
                        //           {"width": "10%"},
                        //           {"width": "10%"},
                        //           {"width": "10%"},
                        //         ],
                        //         dom:'Bfrtip',
                        //         buttons: [
                        //         'csv','excel','print'
                        //         ]
                        // });        
                }else{
                  alert(response.message + "\n" + JSON.stringify(response.data));  
                }

  });
  }

// get_message = () => {
//     if(!this.timerID){
//         return <h4 style={{color: "red"}}>Paused Refreshing</h4>
//     }
//     if(this.state.timer != 0){ 
//         // return <h4 style={{color: "blue"}}>Refreshing in {this.state.timer} seconds</h4>
//     }
//     else{
//         return <h4 style={{color: "green"}}>Refreshing please wait.</h4>
//     }
               
// }




render(){
    return(
    
      <div id=""><br/>
        <div className="container">
            <div className="form-row">
                <div className = "col-md-6">
                    <h3 className="lender_accounts">Txns in Last Six hours {this.state.account_num}</h3>
                </div>
                <div className = "col-md-3">
                    {/* {this.get_message()} */}
                </div>
               
                <div className = "col-md-3">
                    {/*<Button variant="primary" onClick={this.refreshPaymentsList}>Refresh Now</Button> &nbsp; */}
                    {/* <Button variant={this.state.btn_variant} onClick={this.pauseRefresh}>{this.state.buttonName}</Button>  */}
                </div>
            </div>

            <div className="form-row mt-3">
                {this.state.end_time ? 
                <div className='d-flex align-items-center'>
               
                <h5>Last updated : {this.state.end_time } </h5>
                    {this.state.time_diff >1 ?  <h5 style={{paddingLeft:'20px'}}>( <span style={{color:this.state.time_diff>=3?"red":"white"}}> {this.state.time_diff}  mins ago </span> )</h5>:
                      <h5>( {this.state.time_diff} minute ago )</h5>
                    }
             
                </div>
                :
                <h5>Last updated :  </h5>
                }
            </div>

            
            <br/>
           </div>
            <div className={`${this.state.account_result}  statement_table_responsive`}>
                <div id='datatable ' className={"table-responsive "}>
                    {/* <table id ="statement_search" className='acc-stmt-table list'>
                    <thead className={"text-info"}>
                        <tr>
                            <th>S.No</th>
                            <th>Transaction Type</th>
                            <th>Credit</th>
                            <th>Debit</th> 
                            <th>Balance</th>
                            <th>Transaction ID</th>
                            <th>Description</th> 
                            <th>Transaction Date</th> 
                            <th>Matching FA ID</th>
                            <th>Recon Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody> 
                        {this.state.lenderTxnStmts}
                    </tbody>
                    </table> */}
                    {this.state.loader ? <div className='product_takeup_loader'></div> 
                    :
                    <BasicReactTable row_data={this.state.row_data != undefined ? this.state.row_data : []} column_data={this.state.column_data != undefined ? this.state.column_data : []} default_page_size={10} pagination={this.state.row_data != undefined && this.state.row_data.length > 9 ? true : false} csv_file={true} FName='account_stmts' export_csv_datas={this.state.row_data != undefined ? this.state.row_data : []}  global_search = {true}/>}
                              
                </div>
            </div>
        </div>
        

      )


}
}


export default withRouter(ListTodayAccountStmtTxnContainer);