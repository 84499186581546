import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import { Box, Typography, useTheme } from "@mui/material";
import React, { ReactNode } from "react";
import { GoVerified } from "react-icons/go";
import { dd_value } from "../../../actions/common_actions";
import { REKYC_INFO } from "../../../constants";
import TextField from "../../CommonComponent/field/TextField";
import { LightTooltip } from "../../CommonComponent/tooltip";
import {
  BodyVarientOneTypo,
  HeaderVarientH3Typo,
  HeaderVarientH4Typo,
} from "../../CommonComponent/typography";
import { Config, get } from "../../../helpers/storage_helper";
import { rekycVerify } from "../../../actions/lead_actions";
import { req } from "../../../helpers/common_helper";
import { verify } from "crypto";

type FieldProps = {
  value: string;
  type: string;
  verify: boolean;
  modified: boolean;
  collected_n_modified ?: boolean;
};

type AddressResProps = {
  district: FieldProps;
  country: FieldProps;
  sub_country: FieldProps;
  parish: FieldProps;
  village: FieldProps;
  location: FieldProps;
  landMark: FieldProps;
};

const AddressKycCommon = ({
  data,
  title,
  usedFor,
  sub_title,
  HeaderoverRideStyles,
  verify_handler,
  addr_update_path
}: {
  data: AddressResProps;
  title: string;
  usedFor: "kyc" | "re-kyc" | "customer_profile";
  sub_title?: string | React.ReactNode;
  HeaderoverRideStyles?: any;
  verify_handler?: (req_key: string) => void;
  addr_update_path?:string;
}) => {
  const theme = useTheme();
  const keys = Config("addr_hiearchy")[get("market").country_code] as
    | Array<keyof AddressResProps>
    | undefined;

  const defaultHeaderStyle = {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    height: sub_title ? "none" : "54px",addr_update_path,
    display: "grid",
    alignItems: "center",
  };
  const isOldJson = title.includes("As per KYC");

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {usedFor === "re-kyc" && (
        <AddressKycHeader>
          <HeaderVarientH3Typo
            text={title}
            overRideStyles={{ color: "#fff", fontFamily: "Montserrat" }}
          />
        </AddressKycHeader>
      )}
      <AddressKycBorderWrapper usedFor={usedFor}>
        {usedFor !== "re-kyc" && (
          <Box sx={HeaderoverRideStyles ?? defaultHeaderStyle}>
            <HeaderVarientH4Typo
              text={title}
              overRideStyles={defaultHeaderStyle}
            />
            {sub_title && (
              <BodyVarientOneTypo
                text={sub_title as unknown as string}
                overRideStyles={{ color: theme.palette.text.primary, pt: 1 }}
              />
            )}
          </Box>
        )}
        <Box
          className={usedFor === "customer_profile" ? "row mx-0" : ""}
          sx={{ height: usedFor === "customer_profile" ? "" : "100%" }}
        >
          {keys?.map((it, idx) => (
            <Box
              key={idx}
              className={
                usedFor === "customer_profile" ? "col-sm-12 col-md-6" : ""
              }
              py={usedFor === "re-kyc" ? 1 : 2}
              px={2}
            >
              {usedFor === "re-kyc" ? (
                <Box
                  sx={{
                    border:
                    !isOldJson && (data[it]?.modified || data[it]?.collected_n_modified)                        
                    ? "solid 2px #FF4C29"
                        : "none",
                    borderRadius: "10px",
                    // minHeight: "65px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                  component={
                    // isOldJson && data[it]?.modified ? "div" : "fieldset"
                    "fieldset"
                  }
                >
                  { !isOldJson && (data[it]?.modified || data[it]?.collected_n_modified)&& (
                    <Box
                      component={"legend"}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        ml: "15px",
                        mb: 0,
                        pl: "10px",
                        color: "#FF4C29",
                      }}
                    >
                      <b>Modified </b>
                      <PopUp />
                    </Box>
                  )}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      px: 2,
                      minHeight: (data[it]?.modified || data[it]?.collected_n_modified) ? "38px" : "65px",
                    }}
                  >
                    <TextField
                      label={dd_value(it)}
                      val={data[it]?.value || "-"}
                      direction="row"
                    />
                    {!isOldJson && (
                      <VerifyUI
                        verify_data = {data[it]}
                        req_key={`${addr_update_path}.${it}`}
                        verify_handler={verify_handler}
                     
                      />
                    )}
                  </Box>
                </Box>
              ) : (
                <TextField
                  label={dd_value(it)}
                  val={
                    data[it]?.value
                      ? dd_value(data[it].value)
                      : data[it] && usedFor === "customer_profile"
                      ? dd_value(data[it])
                      : "-"
                  }
                  direction={usedFor === "customer_profile" ? "column" : "row"}
                />
              )}
            </Box>
          ))}
        </Box>
      </AddressKycBorderWrapper>
    </Box>
  );
};

export default AddressKycCommon;

const AddressKycHeader = ({ children }: { children: ReactNode }) => (
  <Box sx={{ backgroundColor: "#2FB8D8", p: 1, textAlign: "center" }}>
    {children}
  </Box>
);

const verifyHandler = () => {};

const AddressKycBorderWrapper = ({
  children,
  usedFor,
}: {
  children: ReactNode;
  usedFor: string;
}) => (
  <Box
    sx={{
      border: usedFor !== "re-kyc" ? "solid 2px #FFFFFF" : "solid 2px #2FB8D8",
      mt: 1,
      p: usedFor !== "re-kyc" ? 0 : 2,
      height: "100%",
    }}
  >
    {children}
  </Box>
);

const PopUp = () => (
  <LightTooltip
    sx={{ "& .MuiTooltip-arrow": { color: "#fff" } }}
    title={REKYC_INFO}
    arrow
    placement="top"
  >
    <InfoTwoToneIcon sx={{ ml: "10px", color: "#4bafe3" }} />
  </LightTooltip>
);

const VerifyUI = ({
  verify_data,
  verify_handler,
  req_key,

}: {
  verify_data: FieldProps;
  verify_handler?: (req_key: string) => void;
  req_key: string;
}) => (
  <Box paddingRight={2}>
    {verify_data.verify   ? (
      <GoVerified size={"25"} color={"#4caf50"} />
    ) : ((verify_data.modified || verify_data.collected_n_modified) &&
      <Typography
        sx={{
          color: "#2FB8D8",
          textDecoration: "underline",
          fontSize: "14px",
          cursor: "pointer",
        }}
        onClick={() => verify_handler && verify_handler(req_key)} // Ensure it's checked before invocation
      >
        Verify
      </Typography>
    )}
  </Box>
);