import React, { useEffect, useState } from 'react';
import { bg_box, login_box } from '../../../styles/common_styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { Box, Grid, Typography } from '@mui/material';
import CustomInput from '../../../MUI/Components/CustomInput';
import CustomButton from '../../../MUI/Components/CustomButton';

import { get, set } from '../../../helpers/storage_helper';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { userService } from '../../../actions/auth_actions';
import { changeHeaderSlice } from '../../../Redux/Slices/HeaderSlice';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import CustomSnackbar from '../../../MUI/Components/CustomSnackbar';
import { powered_by_text } from '../../../helpers/common_helper';

const ValidateOTP = () => {
    const { watch,control,handleSubmit,formState: { isValid, errors }} = useForm({mode: "onChange"});
    const [emailVerification, setVerification] = useState(false)
    const theme = useTheme()
    const [user_otp, setOtp] = React.useState('')
    const [Resetpassword, setResetpassword] = React.useState(false)
    const [login_credentials, setlogin_credentials] = useState({ email: "", password: "" ,confirm_password:""})
    const [otp_response,setotp_response] = useState(null)
    const [loader,setloader] = useState(false)
    const [show_snackbar,setshow_snackbar] = useState(false)
    const {brand_info} = useSelector(state => state.common_slice);

    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(()=>{
    if(get('otp_id')){
        setVerification(true)
    }
   else if(get('otp_verified_token')){
        setResetpassword(true)
    }
    else{
        navigate('/login')
    }

    },[])

 
    const user_input = (val,type) => {
        setlogin_credentials({
            ...login_credentials,
            [type]: val
          })
    }

    const send_otp = (type) => {
        setshow_snackbar(true)
        const email = login_credentials.email ? login_credentials.email:get('otp_receiver_email')
        if(type=="resend_otp"){
            setOtp('')
        }
        userService.send_login_otp(email)
        .then((response)=>{
            setshow_snackbar(false)
            if(!response){  
                 return}
            if(response&& response.status =="success"){
                set('otp_id',response.data.otp_id)
                set('otp_receiver_email',email)

                console.log('response', response)
                setotp_response(response.data)
                setVerification(true)
            }
        })
    }

    const verify_otp = () =>{
        setloader(true)
        const otp = user_otp
        const otp_id = (otp_response && otp_response.otp_id) ?  otp_response.otp_id : get('otp_id')
        const email = login_credentials.email ? login_credentials.email:get('otp_receiver_email')
        userService.verify_login_otp(otp,otp_id,email)
        .then((response)=>{
            if(!response){            
                setloader(false)
                return}
            if(response&& response.status =="success"){
                set('otp_verified_token',response.data.token)
                console.log('response', response)
                setotp_response(response.data)
                setResetpassword(true)

                localStorage.removeItem('otp_id')
                localStorage.removeItem('otp_receiver_email')
                setloader(false)
            }
        })

    }

    const resetpassword = () =>{
        setloader(true)
        const token = (otp_response&&otp_response.token )? otp_response.token : get('otp_verified_token')
        const password =  watch('confirm_password')
        userService.reset_password(token,password)
        .then((response)=>{
            if(!response){       
                setloader(false)
                return}
            if(response&& response.status =="success"){
                console.log('response', response)
                localStorage.removeItem('otp_verified_token')
                dispatch(changeHeaderSlice({ "show_login_after_reset_pass": true,login_credentials: response.data}))
                navigate('/login')
                setloader(false)
            }
        })
        setResetpassword(true)
    }

    const handleChange = (newValue) => {
        setOtp(newValue)
    }

    const is_disabled = () =>{
        
        // let disables_cond = (watch('password') && watch('password').length>0  && login_credentials.confirm_password.length>0 )? (login_credentials.password == login_credentials.confirm_password) ? false:true:true
        
          let disable_cond =   (watch('password') &&
            watch('confirm_password') &&
            watch('password') === watch('confirm_password') )

        return disable_cond
          

    }
 

    const wrapper_box = {
        padding: '40px'

    }
    const reset_password = {
        mt: 7,
        backgroundColor: theme.palette.custom_colors.secondary_500,
        color: theme.palette.custom_colors.secondary_a_50,
        borderRadius: '10px',
    }
    const input_otp = {
        display: 'flex',
        justifyContent: 'center',
        '& .MuiOutlinedInput-root': {
            width: { xs: '40px', sm: '50px', md: '77px' },
            height: { xs: '40px', sm: '50px', md: '48px' },
            '& fieldset': {
                borderColor: theme.palette.custom_colors.secondary_a_400,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.custom_colors.secondary_a_400,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.custom_colors.secondary_a_400,
                borderWidth: '2px',
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.custom_colors.secondary_a_400,
        }
    }

    const sign_in_button = {
        backgroundColor: theme.palette.custom_colors.secondary_500,
        color: theme.palette.custom_colors.secondary_a_50,
        borderRadius: '10px',
    }

    const input_style = {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.custom_colors.secondary_a_400,
                borderRadius: '10px',
            },
        },
    }


  
    const form_submit = ()=>{
        Resetpassword ?resetpassword(): emailVerification ?  verify_otp()  : send_otp()
    }
    const logo_style = {
        background:'white',
		borderRadius:'50%'
    }

    return (
        <Box sx={[bg_box,{backgroundColor: theme.palette.custom_colors.old_primary_900,    }]}>
        <Box sx={[login_box,{ backgroundColor: theme.palette.primary.main,}]}>
        <form onSubmit={handleSubmit(form_submit)}>
          <Box sx={wrapper_box}>{console.log('is_disabled', is_disabled())}
              <Grid container alignItems="start" justifyContent="space-between" >
                  <Grid item xs={8} lg={8}>
                      <Typography variant="m_poppins_semibold_white" component={'div'} >
                          {Resetpassword ? "Reset password" : "Verification code" }
                      </Typography>
                      <Typography variant="m_inter_regular_gray" component={"p"} >
                          {Resetpassword ? "Please choose your new password" :  "Check your mail"}
                      </Typography>
                  </Grid>
                  <Box sx={brand_info?.footer ?logo_style:{} }>

                    <img
                    style={brand_info?.style??{}}
                    src={brand_info?.logo}
                    alt="Flow Logo white"
                    loading="lazy"
                    className='login_logo mt-2'
                    />
                    </Box>
              </Grid>
  
              {Resetpassword ?
                  <Box>
                      <Box>
                          <Box sx={{ mt: 7 }}>
                          <CustomInput
                          autoFocus={true}
                            name="password"
                            control={control}
                            sx={input_style}
                            variant="outlined"
                            label="Password"
                            type='password'
                            rules={{ required: 'password is required' , pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/},}}
                            error={!!errors.password}
                            className="default_outlined_variant"
                        />
                          </Box>
                          <Box sx={{ mt: 3 }}>

                          <CustomInput
                            name="confirm_password"
                            control={control}
                            sx={input_style}
                            variant="outlined"
                            label="Confirm Password"
                            type='password'
                            rules={{
                                required: "Confirm password is required",
                                validate: (value) =>
                                  value === watch("password") || "Passwords do not match",
                              }}                    
                            error={!!errors.confirm_password}
                            className="default_outlined_variant"
                        />
                          </Box>
                          <Typography variant="xxs_inter_regular_blue" className=' mt-2' component={'p'} >
                          Must have atleast 8 characters, with uppercase, lowercase, a number, and a special character
                          </Typography>
                      </Box>
                  </Box> 
                  :
                  <>
                      {emailVerification &&
                          <Box>
                              <Typography variant="s_inter_medium_gray" component={'p'} sx={{textAlign: "center", marginTop: "69px" }}>
                              We have send you code on {login_credentials.email ? login_credentials.email : get('otp_receiver_email')}
                              </Typography>
                              <Box sx={{
                                  margin: { xs: "20px 0px 40px 0px", sm: "25px 0px 50px 0px", md: "28px 0px 55px 0px" } // Adjusted for responsiveness
                               }}>
                                  <MuiOtpInput
                                      onError={!user_otp}
                                      sx={input_otp}
                                      value={user_otp}
                                      onChange={handleChange}
                                      className='default_outlined_variant'
                                  />
                              </Box>
                          </Box>
                      }
                  </>
              }

              <CustomButton isLoading={loader} variant="full_width_inter_sm" component='button' text={Resetpassword ? "Reset password" : emailVerification ? "Continue" : "Send"} onClick={() => {Resetpassword ?resetpassword(): emailVerification ?  verify_otp()  : send_otp() }} style={Resetpassword ? reset_password : sign_in_button}  type={"submit"} 
              isDisabled=  {
                Resetpassword ? !is_disabled() :
                emailVerification?user_otp.length<4 : !isValid} />
               { brand_info?.footer &&
                        <Box sx={{mt:2}}>{
                        powered_by_text(brand_info?.footer.logo,theme.palette.custom_colors.secondary_a_400 )
                        }</Box>
                }
               
              {emailVerification && !Resetpassword &&
                  <Box className='d-flex justify-content-end'  >
                      <Typography component={'p'} className='pointer' variant="s_inter_medium_white" sx={{textAlign: 'end', mt: 3 }} onClick={()=>send_otp("resend_otp")}>
                          Resend code
                      </Typography>
                  </Box>
              }
          </Box>
        </form>
          </Box>
        {show_snackbar &&
          <CustomSnackbar message={"Otp has been sent successfully "}/>
        }
      </Box>
    );
};

export default ValidateOTP;