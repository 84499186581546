import { Box } from '@mui/material';
import React from 'react';
import FoundersHome from '../reports/container/founders_home';
import ManagementDashboard from '../reports/container/management_dashboard';
import MonthlyReport from '../reports/container/monthly_report';
import { Config, get } from '../../helpers/storage_helper';
import ReportChartLive  from '../reports/container/Report_chart_live';
import DelinquencyReport from '../reports/component/DelinquencyReport';

type HomeScreenComponents = {
  screen_name: string;
  component: JSX.Element;
};

const HomeScreen = () => {
  const home_screen: HomeScreenComponents[] = [
    { screen_name: 'founder_home_screen', component: <FoundersHome /> },
    { screen_name: 'live_dashboard', component: <ManagementDashboard /> },
    { screen_name: 'monthly_dashboard', component: <MonthlyReport /> },
    { screen_name: 'charts', component: <ReportChartLive /> },
    { screen_name: 'collection_followups', component: <DelinquencyReport /> },

  ];

  const current_screen = Config('home');
  const screen = home_screen.find((item) => item.screen_name == current_screen);
  return (
    <Box>
      <div>
        {screen ? screen.component : <div>Component not found</div>}
      </div>
    </Box>
  );
};

export default HomeScreen;
