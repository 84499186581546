import FlowComponent from './FlowComponent';
import React from 'react';
import { check_priv, get } from '../../../../helpers/storage_helper';
import {Link} from 'react-router-dom';
import {searchLoanAppl} from '../../../../actions/loan_appl_actions';
import  {format_month_change, getValueFromLangJson, set_id_for_rows} from '../../../../helpers/common_helper';
import {dd_value} from '../../../../actions/common_actions';
import {CustID} from '../../partial';
import '../../../../styles/flow.css';
import $ from 'jquery';
import { sortBizcommon,sortAmtcommon,sortDatecommon } from '../../../../helpers/sortingHelpers';
import { lbl_amt } from '../../../../helpers/common_helper';
import Accordion from 'react-bootstrap/Accordion';
import {Card} from 'react-bootstrap'
import {BsChevronDown} from 'react-icons/bs';
import { BasicReactTable } from '../../../../helpers/react_table';
import MUIAccordion from '../../../../MUI/Components/MUIAccordion';
import HomeScreenTable from '../../../home/HomeScreenTable';
import { connect } from 'react-redux';

class LoanPendingApproval extends FlowComponent {
    
    state = {
        rowdatas:[],
        columndatas:[],  
        show_table: false ,
        role_code : get('role_codes')  ,
        page: { page: 0, pageSize: 10 },
   
    }

	handleToggle = (e) => {

        switch(e){
            case("Account"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
        }
    }

    componentWillMount(){
        const loan_appl_search_criteria = {status : "pending_approval"};

        if(this.state.role_code == 'loan_approver'){
            loan_appl_search_criteria.approver_role = 'loan_approver';
        }else{
            loan_appl_search_criteria.loan_approver_id = get('user_id');
        }
        
        
        searchLoanAppl(this.req({loan_appl_search_criteria}, true))
        .then((response) => {
            // console.log("response  "+response);
            if(!response){return };
            if(response){
                const data = set_id_for_rows(response.data)
                this.setState(state => state.rowdatas = data )                
                this.setState(state => state.columndatas = this.columndatas())                
                // // var loanApplicationResults =[];
                    
                // // loanApplicationResults = response.data.map((loan_appl,i)=> <tr>
                    
                // //     <td>{loan_appl.loan_appl_doc_id}</td>
                // //     <td><Link to={"/borrower/indiv/view/"+loan_appl.acc_number} target="_blank">{loan_appl.acc_number}</Link></td>
                // //     <td>{loan_appl.cust_name }</td>
                // //     <td>{loan_appl.cust_id }</td>
                // //     <td>{loan_appl.biz_name }</td>
                // //     <td>{loan_appl.loan_principal} UGX</td>
                // //     <td>{format_month_change(loan_appl.loan_appl_date)}</td>
                // //     {/* <td>{loan_appl.cs_result_code ? dd_value(loan_appl.cs_result_code) : "NULL"}</td> */}
                // //     <td>{loan_appl.cs_result_code ? 
                // //           (loan_appl.cs_result_code == "ineligible" ? "Ineligible" : 
                // //           ((loan_appl.cs_result_code == "requires_flow_rm_approval"  || loan_appl.cs_result_code == "first_n_fas_wo_score" )? "Need Approval" : "NULL"))
                // //           : "NULL"}</td>
                // //     <td>
                // //         {loan_appl.cs_result_code != "ineligible" ?
                // //             <input id={loan_appl.loan_appl_doc_id} 
                // //             type='submit' value='Take Action' onClick={(event) => {this.takeAction(event, loan_appl.cs_result_code, loan_appl.cust_id)}} className='btn btn-primary' />
                // //         :
                // //         "NULL"
                // //         }
                // //     </td>
                    
                // //     </tr>
                // //     ); 
           
                //     this.setState({loanApplicationResults: loanApplicationResults});
                //         $('#loan_list').removeAttr('width').dataTable({
                //             "order" : [],
                //             "columns": [
                //                 {"width": "5%"}, 
                //                 {"width": "5%"}, 
                //                 {"width": "5%"},
                //                 {"width": "5%"},
                //                 {"width": "5%"},
                //                 {"width": "5%"},
                //                 {"width": "5%"},
                //                 {"width": "5%"},
                //                 {"width": "5%"},
                                
                                      
                //              ],
                //             "searching": false,
                //             "paging": false,
                //             "info": false,
                //     });
                }
        });
        
    }

    sortBiz(a,b){                
        return sortBizcommon (a,b)                 
    }
    sortAmt(a,b){         
        const curr_Amt = a.props.children
        const prev_Amt = b.props.children     
        return sortAmtcommon(curr_Amt,prev_Amt)      
    }
    sortDate (a,b) {	 
		const current_Date = a//.props.children[1]
		const prev_Date = b//.props.children[1]		
		return sortDatecommon(current_Date,prev_Date)        
	}

    columndatas () {
        return [
            {
                headerName : getValueFromLangJson('ac_number'),
                field: "acc_number",
                headerAlign: 'left', flex: 1, align: 'left',
                renderCell : params => <Link to={"/borrower/indiv/view/"+params.row.acc_number} target="_blank">{params.row.acc_number}</Link>
            },
            {
                headerName : getValueFromLangJson('fa_applcation_id'),
                field: "loan_appl_doc_id",
                headerAlign: 'left', flex: 1, align: 'left',
                renderCell : params => <Link to={"/fa_appl/view/"+params.row.loan_appl_doc_id} target="_blank">{params.row.loan_appl_doc_id}</Link>
            },
            {
                headerName : getValueFromLangJson("cust_name"),
                field: "cust_name",
                headerAlign: 'left', flex: 1, align: 'left',
                renderCell : params => {return params.row.cust_name }
            },
            {
                headerName : getValueFromLangJson("cust_id"),
                field: "cust_id",
                headerAlign: 'left', flex: 1, align: 'left',
                
                 renderCell : params => <Link to={"/borrower/indiv/view/"+params.row.cust_id} target="_blank">{params.row.cust_id}</Link>
            },
            {
                headerName : getValueFromLangJson("bz_name"),
                field: "biz_name",
                headerAlign: 'left', flex: 1, align: 'left',
                sortMethod: (a,b) => this.sortBiz(a,b),
                renderCell : params => {return params.row.biz_name }
            },
            {
                headerName : getValueFromLangJson('fa_amount'),
                field: "loan_principal",
                sortable : false,
                headerAlign: 'left', flex: 1, align: 'left',
                renderCell : params => <div>{lbl_amt(params.row.loan_principal, this.currency_code)}</div>
            },
            {
                headerName : getValueFromLangJson("date"),
                field: "loan_appl_date",
                sortable : false,
                headerAlign: 'left', flex: 1, align: 'left',
                renderCell : params => {return format_month_change(params.row.loan_appl_date)}
            },
            {
                headerName : getValueFromLangJson("action"),
                field: "cs_result_code",
                headerAlign: 'left', flex: 1, align: 'left',
                renderCell : params => {return params.row.cs_result_code ? 
                    (params.row.cs_result_code == "ineligible" ? "Ineligible" : 
                    ((params.row.cs_result_code == "requires_flow_rm_approval"  || params.row.cs_result_code == "first_n_fas_wo_score" ) ?  getValueFromLangJson('need_approval') : "NULL"))
                    : "NULL"}
            },
        ]

         
    }

    takeAction = (event, result_code, cust_id) => {
       

        if(result_code == "requires_flow_rm_approval" || result_code == "eligible" || result_code == "first_n_fas_wo_score"){
            const loan_appl_doc_id = event.target.id;
            alert("Please approve/reject the FA from the new tab");
            window.open("/fa_appl/view/"+loan_appl_doc_id,"_blank");
        } 
       	
        else {
            window.open("/borrower/indiv/view/"+cust_id,"_blank");
        }
	}
    render(){        
        return (
            <MUIAccordion
            title={
                <h4 className="card-title text-white tableHead_font ">{ getValueFromLangJson('fas_pend_ur_approval')}</h4>
               }
            body={
                // <BasicReactTable row_data={this.state.rowdatas !== undefined ? this.state.rowdatas : []} column_data={this.state.columndatas} pagination={ this.state.rowdatas !== undefined && this.state.rowdatas.length > 9 ? true : false}  export_csv_datas={this.state.logs ? this.state.logs : []}  defaultSorted={[{id:"loan_appl_date",desc:true}]} default_page_size = {50}  />  

                <HomeScreenTable
                rows={this.state.rowdatas ??[] } 
                paginationMode= {"client"}
                page={this.state.page}
                setpage={(newPage) => this.setState({ page: newPage })}
                // isLoading={ this.state.loader }
                rowCount={this.state.rowdatas?.length}
                columns={this.state.columndatas}
                
                />

            }
            total_count = { this.props.acc_prvdr_list?.length > 1 ? 0 : (this.state.rowdatas?.length)}

            >

            </MUIAccordion>

          
        );
    
}
}

const mapStateToProps = (state) => {
	return {
	  acc_prvdr_list : state.common_slice?.acc_prvdr_list
	};
  };

export default connect(mapStateToProps)(LoanPendingApproval);
