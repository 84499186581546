import React, { useEffect, useState } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import { BodyVarientOneTypo, HeaderVarientH3Typo } from "../../../../CommonComponent/typography";

interface FilterProps {
    tab_handler: (main_tab: "due" | "overdue", sub_tab: string) => void;
}

const FilterTabs: React.FC<FilterProps> = ({ tab_handler }) => {
    const theme = useTheme();
    const [activeTab, setActiveTab] = useState<"due" | "overdue">("due");
    const [tab, setTab] = useState({ due: "today", overdue: "all" });
    
    const tabs = [
        { id: "due", label: "Due Customers" },
        { id: "overdue", label: "Overdue Customers" }
    ];
    
    const subTabs = {
        due: [
            { id: "today", label: "Today" },
            { id: "tomorrow", label: "Tomorrow" },
        ],
        overdue: [
            { id: "all", label: "All" },
            { id: "1-1", label: "Day 1" },
            { id: "2-3", label: "Day 2-3" },
            { id: "4-7", label: "Day 4-7" },
            { id: "8-10", label: "Day 8-10" },
            { id: "11-15", label: "Day 11-15" },
            { id: "more_than_15", label: "Day >15" },
        ],
    };

    const commonStyle = (isActive: boolean, type: string) => ({
        border: type === "main" ? `1px solid ${theme.palette.text.primary}` : 'none',
        px: 2,
        py: type === "main" ? 2 : 0.5,
        mx: 1,
        borderRadius: 1,
        textAlign: "center",
        cursor: "pointer",
        fontWeight: isActive ? 800 : 400,
        backgroundColor: isActive
            ? type === "main" 
                ? theme.palette.text.primary
                : theme.palette.secondary.main
            : "transparent",
        color: isActive
            ? theme.palette.text.primary
            : type === "sub"
                ? theme.palette.text.secondary
                : theme.palette.text.primary,
        transition: "background-color 0.3s, color 0.3s",
        "&:hover": {
            backgroundColor: isActive
                ? type === "main"
                    ? theme.palette.text.primary
                    : theme.palette.secondary.main
                : theme.palette.primary.light,
                color: type === "sub"?
                    
                     theme.palette.text.primary :  theme.palette.text.secondary,
                
        },
    });

    const list_customers = (main_tab: "due" | "overdue", sub_tab: string) => {
        setTab(prev => ({ ...prev, [main_tab]: sub_tab }));
        tab_handler(main_tab, sub_tab);
    };

    const main_tab = (main_tab: "due" | "overdue", sub_tab: string) => {
        setActiveTab(main_tab);
        tab_handler(main_tab, sub_tab);
    };

    return (
        <Box>
            <Grid container spacing={2} mt={2} justifyContent="center">
                {tabs.map(({ id, label }) => (
                    <Grid key={id} item xs={12} lg={5}>
                        <Box
                            sx={commonStyle(activeTab === id, "main")}
                            onClick={() => main_tab(id as "due" | "overdue", tab[id as "due" | "overdue"])}
                            className={activeTab === id ? "h3_text" :""}
                           >

                            <HeaderVarientH3Typo
                                text={label}
                                overRideStyles={{
                                    color: activeTab === id ? theme.palette.secondary.main : theme.palette.text.primary,
                                    fontWeight: activeTab === id ? 600 : 400,
                                }}
                            />
                        </Box>
                    </Grid>
                ))}
            </Grid>

            <Grid container mt={4} justifyContent="center">
                <Box
                    sx={{
                        display: "flex",
                        background: theme.palette.text.primary,
                        py: 1.5, px: 1, borderRadius: 2
                    }}
                >
                    {subTabs[activeTab]?.map(({ id, label }) => (
                        <Box
                            key={id}
                            sx={commonStyle(tab[activeTab] === id, "sub")}
                            onClick={() => list_customers(activeTab, id)}
                        >
                            <BodyVarientOneTypo text={label} />
                        </Box>
                    ))}
                </Box>
            </Grid>
        </Box>
    );
};

export default FilterTabs;
