
import './styles/flow.css';
import React, { Component, useEffect, useState } from 'react';
import { Navigate, RouterProvider, useLocation} from 'react-router-dom';
import {Config, check_priv, get,get_item, remove_all, set} from './helpers/storage_helper';
import Login from './UI/auth/component/Login';
import AdminHome from './UI/home/AdminHome';
import SelectMarket from './UI/home/SelectMarket';
import $ from 'jquery';
import 'react-datetime/css/react-datetime.css';
import { get_notification_permission, } from "./firebase/config";
import Router from '../src/Router/Router';
import FreshChat from './UI/utility/Component/FreshChat';
import { touch } from './actions/common_actions';
import { useDispatch, useSelector } from 'react-redux';
import { updateValuesIntoRedux } from './Redux/Slices/CommonSlice';
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material';
import createCustomTheme, { theme } from './MUI/Theme';
import custom_colors from './MUI/colors';
import CSSoftPhoneContainer from './UI/cs_management/container/CSSoftPhoneContainer';

import { Modal } from 'react-bootstrap';
import '@fontsource/poppins';
import {FaPhoneAlt, FaPhoneSlash} from "react-icons/fa";
import { changeHeaderClientTabId ,changeHeaderSlice} from './Redux/Slices/HeaderSlice';
import { store } from './Redux/Store';
$.dataTable = require('datatables.net');
require("datatables.net-buttons/js/dataTables.buttons.js");
require("datatables.net-buttons/js/buttons.colVis.js");
require("datatables.net-buttons/js/buttons.flash.js");
require("datatables.net-buttons/js/buttons.html5.js");
require("datatables.net-buttons/js/buttons.print.js");
import BrandingConfig from '../src/BrandingConfig.json'; 
import AppFooter from './UI/common/component/AppFooter';
import { list_acc_prvdr, markets_with_lender } from './helpers/common_apicalls';





const  App = ()=>{

  const datas = useSelector(state=>state.common_slice)
  const dispatch = useDispatch();

  const acc_prvdr_code = get('acc_prvdr_code');
  const role_codes = get('role_codes')

  const [cust_data, setCustdata] = useState({
    cust_name: "Lydia Martin",
    mobile_num: "98654323234"
  })   
  const [isIncomingCall, setIsincomingCall] = useState(false)  

  const {header_client_tab_id} = useSelector((state)=>state.HeaderSlice)

  useEffect(()=>{
   
    set_app_logo()
    get_notification_permission()

    if(!get('role_codes') || !get('access_token')){
      Router.navigate('/login')
    }
    else if(get('role_codes') && get('market')){
      fetch_acc_prvdr()
      server_time()
    }else if(get('role_codes') && get('market_list') && !get('market')){
      Router.navigate('/select_market')
    }
  }, [])



  useEffect(() => {
    const channel = new BroadcastChannel('cs_soft_phone')

    const handleBeforeUnload = (event) => {
        console.log('Page is about to be refreshed or closed');

        if (localStorage.getItem('client_tab_id')) {
            if (store.getState().HeaderSlice.header_client_tab_id == localStorage.getItem('client_tab_id')) {
                localStorage.removeItem('client_tab_id')
                changeHeaderClientTabId(null)
                channel.postMessage({message:'client_destroyed'})
            }
        }

    };


    window.addEventListener('beforeunload', handleBeforeUnload);


    return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        channel.close()
    };
}, []);


  const set_app_logo = () => {
    const host_name = window.location.hostname;
    const current_branding = BrandingConfig[host_name] || BrandingConfig.default;
    dispatch(updateValuesIntoRedux({brand_info : current_branding}))
    markets_with_lender(dispatch)
  }

  const fetch_acc_prvdr = async () => {
    if(datas?.acc_prvdr_list.length<1 ){
      const acc_prvdr_list = await list_acc_prvdr();
      const acc_prvdr_codes = acc_prvdr_list.map((item) => item.acc_prvdr_code); 
      dispatch(updateValuesIntoRedux({"acc_prvdr_list" : acc_prvdr_codes}))  
    }
  };


  const server_time = async() =>{
    return await touch({country_code:get('market').country_code, time_zone: get('market').time_zone})
             .then((response) => {
                 if(!response){return false};
                 
                 if(response.status === "success"){
                   dispatch(updateValuesIntoRedux({serevr_time:response}))
                 }
                 else{
                      remove_all()
                      Router.navigate('/login')
                 }
                }
              ); 
}

const paths = ["login", "forgot_password", "validate_otp"];
const hide_footer = paths.some(path => window.location.pathname.includes(path));


const app_footer = datas.brand_info?.footer  && !hide_footer
    return (
      <>{datas &&
      <ThemeProvider theme={createCustomTheme(datas.mode)}>
        <CssBaseline />
        <GlobalStyles styles={{ p:{fontFamily:'"Inter", sans-serif !important'} }}/>
        <div className="App">
            <RouterProvider router={Router} />
        </div>
        { app_footer&& <AppFooter/>}
    </ThemeProvider>}
       </> 
    );
  
}

export default App;
