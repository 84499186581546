import { Button, Card } from 'react-bootstrap';
import { fsdu_report } from '../../../actions/report_actions';
import FlowComponent from '../../common/component/core/FlowComponent';
import React from 'react'
import moment from 'moment';
import DatePicker from 'react-datetime';
import { getLoanSearchCriteria } from '../../../actions/common_actions';

class FSDUReport extends FlowComponent{

    state ={
      disb_check : false,
      overdue_check : false,
      start_date :moment().format("YYYY-MM-DD HH:mm:ss"),
      end_date : moment().format("YYYY-MM-DD HH:mm:ss"),
      email : null,
      calling_api : false,
      lender :null
    }

    componentWillMount(){
        this.getSelect()
        this.setState({calling_api : false})
    }

    handleApiCall = ()=>{
        
        this.setState({calling_api : true})

        if(!this.state.disb_check && !this.state.overdue_check){
            this.setState({calling_api : false})
            alert("Please fill the required criterias.")
            return
        }

        if(this.state.email == null){
            this.setState({calling_api : false})
            alert("Please fill the required criterias.")
            return
        }

        let email_arr = this.state.email.split(',');
        for(let i = 0; i < email_arr.length; i++){
            if(!email_arr[i].includes('@')){
              this.setState({calling_api : false})
              alert("Please enter a vaild email address")
              return
            }
        }
        let report_type = this.state.disb_check ? 'disbursal' : 'overdue'
        let req = {type : report_type, recipients : this.state.email, start_date : this.state.start_date, end_date : this.state.end_date, lender:this.state.lender}


        fsdu_report(this.req(req)).then((response) => {
            this.resp(response)
            if(!response){return alert("Unable to process the mail")}
            if(response){
                alert("The report is being generated and will be sent to the requested emails shortly.");
            }
            window.location.reload()
        });
    }

    getSelect = () => {
        var acc_prvdr_code = this.acc_prvdr_code;
        var screen = "loan_search";
        var country_code = this.country_code
        getLoanSearchCriteria(this.req({country_code, screen, acc_prvdr_code, "status": "enabled"}))
                    .then((response) => {
                      if(!response){return };
                    var result = response.data;
                    for (var dd_name in result) {
                        var name_list = result[dd_name];
                      
                        if(dd_name == 'sub_lender_code'){
                            const data_holders = ["data_code", "data_value"];
                            this.getSelectOptionsFromResult(name_list, dd_name, data_holders,false);
                        }else{	
                            this.getSelectOptionsFromResult(name_list, dd_name);
                        }		
                    }
               });
    }

    handleChangeRadioButton = (action, type)=>{
        if(type == 'disbursal'){
            this.setState({disb_check : true, overdue_check : false, start_date : moment().format("YYYY-MM-DD HH:mm:ss"), end_date : moment().format("YYYY-MM-DD HH:mm:ss")})
        }
        else if(type == 'overdue'){
            this.setState({disb_check : false, overdue_check : true, end_date : moment().format("YYYY-MM-DD HH:mm:ss")})
        }
    }

    handleChanges=(event)=> {
      this.setState({email: event.target.value});
    }

    handleLenderChanges=(event)=>{
        this.setState({lender: event.target.value});
    }

    render(){
        return(
            <div>
                <form className="mb-3" autocomplete="off">
                    <Card>
                        <Card.Header style = {{backgroundColor: "#01062c" , color: "white"}}><h4>Lender Report</h4></Card.Header>
                            <Card.Body style={{padding:15}}>
                                <div> 
                                      <div class="form-check d-flex align-items-center  mb-5" >
                                          <input type="radio" className='mr-2' style={{height:"17px", width:"14px"}}  id="disbursal" name='report_type' onChange={(e) => this.handleChangeRadioButton(e.target.checked, 'disbursal')} />
                                          <span className='m-0' for="report_type" ><p className='m-0' style={{fontSize:"15px",color:"white"}}>Disbursal Report</p></span>
                                      </div>
                                      {this.state.disb_check && 
                                            <div className='row col-md-6 p-0 mb-5'>  
                                              <div className='col-md-4 p-0 ml-4'>
                                                  <label className='col p-0' >Start Date <span className='text-danger'>*</span></label>
                                                  <DatePicker className='col-12 p-0' inputProps={{ id: 'start_date', name: 'start_date', autoComplete:'off' ,readOnly:true}} closeOnSelect={true}  onChange={(e) => this.setState({start_date: moment(e).format("YYYY-MM-DD HH:mm:ss")})}  dateFormat="YYYY MM DD" value={moment(this.state.start_date).format("YYYY-MM-DD") } timeFormat={false}  />
                                              </div>
                                                <div className='col-md-4 p-0 ml-4'>
                                                  <label className='col p-0' >End Date <span className='text-danger'>*</span></label>
                                                  <DatePicker className='col-12 p-0' inputProps={{ id: 'end_date', name: 'end_date', autoComplete:'off', readOnly:true}} closeOnSelect={true}  onChange={(e)=>this.setState({end_date:moment(e).format("YYYY-MM-DD HH:mm:ss")})}   dateFormat="YYYY MM DD" value={moment(this.state.end_date).format("YYYY-MM-DD")} timeFormat={false}/>
                                                </div>
                                            </div>
                                      }
                                      <div class="form-check d-flex align-items-center mb-4" >
                                          <input type="radio" className='mr-2' style={{height:"17px", width:"14px"}} id="overdue" name='report_type' onChange={(e) => this.handleChangeRadioButton(e.target.checked, 'overdue')} />
                                          <span className='m-0' for="report_type" ><p className='m-0' style={{fontSize:"15px",color:"white"}}>Overdue Report</p></span>
                                      </div>
                                      {this.state.overdue_check &&
                                            <div className='col-md-2 p-0 ml-1'>
                                            <label className='col p-0' >As on date<span className='text-danger'>*</span></label>
                                            <DatePicker className='col-12 p-0' inputProps={{ id: 'end_date', name: 'end_date', autoComplete:'off', readOnly:true}} closeOnSelect={true}  onChange={(e)=>this.setState({end_date:moment(e).format("YYYY-MM-DD HH:mm:ss")})}   dateFormat="YYYY MM DD" value={moment(this.state.end_date).format("YYYY-MM-DD")} timeFormat={false}/>
                                          </div>
                                      }
                                </div>
                                <div className="row align-items-end">
                                        <label className='col-md-1.5 mb-0 ml-4' style={{fontSize:"16px",width:'150px'}}>Lender : <span className='text-danger'>*</span></label>
                                        <div  className="col-md-3">
                                    <select onChange={this.handleLenderChanges} className="form-control">
                                        {this.state.sub_lender_code_dd && <option value={null} selected>All</option>}
                                    {this.state.sub_lender_code_dd}
                                    </select>
                                    </div>
                                </div>
                                <div>
                                <form>
                                    <div className='row mt-4'>
                                      <label className='col-md-1.5 mt-4 mb-0 ml-4' style={{fontSize:"16px",width:'150px'}}>{"Email Recipients : "}<span className='text-danger'>*</span></label>
                                      <div className='col-md-3'>
                                        <input type="email" class="form-control" id="email" aria-describedby="email" onChange={this.handleChanges}/>
                                       
                                        </div>

                                    </div>
                                </form>
                                </div>
                                <div className="form-group d-flex reset-margin justify-content-center py-4">
                                    <Button onClick={this.handleApiCall} disabled={this.state.calling_api}>Send</Button>
                                </div>
                            </Card.Body>
                        </Card>
                </form>
            </div>
        )
    }
}
export default FSDUReport