import React, { useState, useRef, useEffect, useCallback } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Box, IconButton, Typography, CircularProgress, useTheme } from "@mui/material";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import DownloadIcon from "@mui/icons-material/Download";
import mammoth from "mammoth";
import jsPDF from "jspdf";
import { file_format } from "../../../helpers/common_helper";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

interface PDFViewerProps {
  file: string;
  height?: string;
  file_name?: string;
  call_from?:string | null
}

const PDFViewer: React.FC<PDFViewerProps> = ({ file, height = "560px", file_name, call_from }) => {
  const [numPages, setNumPages] = useState<number | undefined>();
  const [pdfWidth, setPdfWidth] = useState<number>(800);
  const [docContent, setDocContent] = useState<string | null>(null);
  const [pdfFile, setPdfFile] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const isDocxFile = useCallback(() => {
    return file_name ? ["doc", "docx"].includes(file_format(file_name)) : false;
  }, [file_name]);

  useEffect(() => {
    if (isDocxFile()) {
      handleDocxConversion();
    } else {
      setPdfFile(file);
    }
  }, [isDocxFile]);

  const handleDocxConversion = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(file);
      if (!response.ok) throw new Error("Failed to fetch the file.");
      const blob = await response.blob();
      const arrayBuffer = await blob.arrayBuffer();

      const result = await mammoth.extractRawText({ arrayBuffer });

      setDocContent(result.value);

      const pdf = new jsPDF();
      pdf.text(result.value, 10, 10);
      const pdfOutput = pdf.output("datauristring");

      setPdfFile(pdfOutput);
    } catch (error) {
      console.error("Error loading DOCX file:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePdfDownload = () => {
    if (!docContent) return;
    const pdf = new jsPDF();
    pdf.text(docContent, 10, 10);
    pdf.save(`${file_name || "document"}.pdf`);
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
    setIsLoading(false);
  };

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setPdfWidth(containerRef.current.offsetWidth - 20);
      }
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  console.log('call_from', call_from)
  return (
    <Box
      ref={containerRef}
      sx={{
        overflow: "hidden",
        height,
        display: "flex",
        flexDirection: "column",
        border: (call_from&& call_from=="cust_profile")? 'none': "1px solid gray",
      }}
    > 
    
      <Box
        sx={{
          position: "absolute",
          top: "-40px",
          right: 0,
          zIndex: 3,
          display:  (call_from&& call_from=="cust_profile") ? "none" :"flex",
          justifyContent: "flex-end",
        }}
      >
        <IconButton onClick={() => window.open(file, "_blank")} title="Expand">
          <AspectRatioIcon sx={{ fontSize: "1.5rem", color: theme.palette.text.primary }} />
        </IconButton>

        {/* <IconButton onClick={handlePdfDownload} title="Download">
          <DownloadIcon sx={{ fontSize: "1.5rem", color: theme.palette.text.primary }} />
        </IconButton> */}

        <a href={file} download>
            <IconButton title="Download">
              <DownloadIcon sx={{ fontSize: "1.5rem", color: theme.palette.text.primary }} />
            </IconButton>
          </a>


      </Box>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        pdfFile && (
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              position: "relative",
              height: "100%",
            }}
            tabIndex={0}
          >
            <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}
             onClick={()=>{ (call_from&& call_from=="cust_profile") ? window.open(file, "_blank") : null}} 
             className={"pointer"} loading="Loading PDF...">
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={pdfWidth}
                  renderMode="canvas"
                />
              ))}
            </Document>
          </Box>
        )
      )}
    </Box>
  );
};

export default PDFViewer;
