import React from "react";
import FlowComponent from "../../common/component/core/FlowComponent";
import { get, Config, check_priv } from "../../../helpers/storage_helper";
import {
  capitalize_first_letter,
  getLabel,
  getValueFromLangJson,
  get_dir_path,
} from "../../../helpers/common_helper";
import DisplayImage from "../../common/component/DisplayImage";
import { dd_value } from "../../../actions/common_actions";
import GoogleMapSetup from "../../../helpers/googleMap";
import moment from "moment";
import { connect } from "react-redux";
import { Box, Grid, useTheme } from "@mui/material";
import TextField from "../../CommonComponent/field/TextField";
import { BodyVarientOneTypo, SubTitleVarientOneTypo } from "../../CommonComponent/typography";
import { FaPhoneAlt } from "react-icons/fa";
import withRouter from '../../../Router/Withrouter';
import Softphone from "../../cs_management/container/cs_soft_phone";
import { getCustDetails } from "../../../actions/borrower_actions";
import { Link } from "react-router-dom";
import { setValuesIntoRedux } from "../../../Redux/Slices/CsSoftphoneSlice";

class AuditKYCMapandHandlerComponent extends FlowComponent {
  constructor() {
    super();
  }

 
  checkbox_spinner() {
    return (
      <div class="d-flex col-lg-10 py-2 px-5 mt-4 justify-content-center ml-5">
        <div class="spinner-border text-light" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  componentWillMount(){
    if(check_priv('lead', 'verify_lead_num_call') && get("market").country_code == 'UGA'){
      this.setState({lead_num_verify_call:true})
    }
    this.setState({ rm_label_short : getValueFromLangJson("rm_label_short")})

  }

  audit_section_spinner() {
    return (
      <div class="audit_spinner" style={{ margin: "20vh 0px  30vh 45%" }}>
        <span class="level level-1"></span>
        <span class="level level-2"></span>
        <span class="level level-3"></span>
        <span class="level level-4"></span>
        <span class="level level-5"></span>
        <span class="level level-6"></span>
      </div>
    );
  }

  rekyc_modify_state_handler(json_type, json_key, key) {
    if (
      this.props.data[json_type][json_key][key].hasOwnProperty("modified") &&
      this.props.data[json_type][json_key][key].modified
    ) {
      return "new_kyc_json";
    } else {
      return "";
    }
  }

  get_cust_details = async(cust_id) => {
    let cust_name = null
    await getCustDetails(this.req({cust_id : cust_id}))
    .then((response) => {
      this.resp(response);

      if(response?.data?.cust_name){
        cust_name =  response.data.cust_name
      }
      }
    )
    return cust_name
  }

  handleCall = async(mobile_num, type, entity_id) => {
    if(type == 'reference'){
      let cust_name = await this.get_cust_details(entity_id);
      this.props.setValuesIntoRedux({screen_from:"verification_call", cust_id :entity_id, outgoing_call_data : {cust_id : entity_id, cust_mobile_num : mobile_num, cust_name}, lead_details : {lead_id : entity_id}, verification_call : true})
    }else{
      this.props.setValuesIntoRedux({screen_from:"verification_call", lead_id :entity_id, outgoing_call_data : {lead_id : entity_id, lead_mob_num : mobile_num}, lead_details : {lead_id : entity_id, lead_mob_num : mobile_num}, verification_call : true})
    }
   
  }


   objhas_any_value = (address = {}) => {

    for (let key in address) {
      if (address[key]?.value ) {
        return true; 
      }
    }

    return false; 
  };

  getOwnerAddressText = () => {
    const handler_and_maplocation_data = this?.props?.handler_and_maplocation_data
    const visit_type = handler_and_maplocation_data?.cust_reg_json?.gps_address?.owner_address?.verification_method?.value; // reference, others, lcletter, homevisit
    const ref_mob_num = handler_and_maplocation_data?.cust_reg_json?.gps_address?.owner_address?.ref_mobile_num?.value;
    const ref_cust_id = handler_and_maplocation_data?.cust_reg_json?.gps_address?.owner_address?.ref_cust_id?.value;
    const baseText = `${Config('cont_for_kyc_addr')[visit_type]}`
    // console.log(visit_type, ref_mob_num, ref_cust_id,'visitType is here')
    if(visit_type === 'reference') return <>{baseText} <Link className="audit_cust_url" to={`/borrower/indiv/view/${ref_cust_id}`} >{ref_cust_id}</Link></> // for reference type the base text and cust id inside link has concatinated
    else if(visit_type === 'others') return `${baseText} ${ref_mob_num}` // others type will have the mbl number need to concatinate that with the base text
    return  baseText // for other cases lcletter and homevisit no need to do anything just basetext is enough
  }

  render() {
    var audit_kyc =
      this.props.audit_kyc &&
      this.props.audit_kyc[this.props.handler_and_maplocation_data.active_key];

      const owner_addr = this.props.handler_and_maplocation_data.cust_reg_json.gps_address?.owner_address
      const addr_fields =  Config('addr_hiearchy')?.[get('market').country_code] ?? []
      const permanent_addr = this.props.handler_and_maplocation_data.cust_reg_json.gps_address?.permanent_res_addr
      const biz_addr = this.props.handler_and_maplocation_data
      .cust_reg_json?.gps_address?.biz_address
      const photo_pps_full_path = this.props.handler_and_maplocation_data
      .cust_reg_json?.contact_persons[0]
      ?.photo_pps_full_path?.value

      const photo_selfie_full_path = this.props.handler_and_maplocation_data.cust_reg_json?.contact_persons[0]?.photo_selfie_full_path?.value
      const verified_by = this.props.handler_and_maplocation_data
      .cust_reg_json.gps_address.owner_address
      .verification_method?.value
      const disable_call_condn = this.props.handler_and_maplocation_data.active_section.verify || this.props.handler_and_maplocation_data.active_section.to_reassign || this.props.handler_and_maplocation_data.responseData.audited_by != get("user_id")
      const matchedAddressJson = [
        {show: permanent_addr?.match_biz_cur_per_addr, name: 'permanent address'}, 
        {show: biz_addr.match_biz_cur_per_addr, name: 'business address'}, 
        {show: owner_addr?.match_biz_cur_per_addr, name: 'current residential address'}
      ].filter(mDj => mDj.show)

     
    return (
      <div>
        {this.props.handler_and_maplocation_data.audit_kyc_wizard_menus
          .contact_persons && (
            <>
              {" "}
              {this.props.handler_and_maplocation_data
                .View_lead_section_loader ? (
                this.audit_section_spinner()
              ) : (
                <div className="row no-margin justify-content-center">
                  <div
                    className="col-lg-12 py-3 mt-5 "
                    style={{ background: " #01062c" }}
                  >
                    <h2 className="text-white text-center">
                      <b>2 - Handler Verification </b>
                    </h2>
                    <h2 className=" d-flex mt-1 text-center justify-content-center align-items-center">
                      <img src="/img/pending_with_rm.png" height={"50"} />{" "}
                      <b
                        className="ml-2 align-self-cente"
                        style={{ color: "#F3EA0B" }}
                      >
                        {" "}
                        Pending with{" "}
                        {this.props.handler_and_maplocation_data.active_section &&
                          this.props.handler_and_maplocation_data.active_section[0]
                            .pending_with === "rm"
                          ? this.state.rm_label_short??''
                          : "YOU"}
                      </b>
                    </h2>
                  </div>
                  <>
                    <div
                      className="col-lg-10 py-5 mt-5 px-5"
                      style={{ background: "#223b56" }}
                    >
                      <h2 className="text-white">
                        <b>
                          {" "}
                          Handler {getLabel("national_id")} Front Side
                          Verification
                        </b>
                      </h2>
                      {this.props.handler_and_maplocation_data.cust_reg_json && (
                        <>
                          <div className=" mt-4 row ">
                            <div className="col-md-6 no-padding col-lg-6">
                              <div className="m-0 text-light  h-100 w-100">
                                <DisplayImage
                                  image_path={get_dir_path(
                                    this.props.handler_and_maplocation_data
                                      .cust_reg_json.contact_persons[0]
                                      .photo_id_proof_full_path.value,
                                    this.props.handler_and_maplocation_data
                                      .cust_reg_json.contact_persons[0]
                                      .photo_id_proof.value
                                  )}
                                  style={{ height: "550px", width: "100%" }}
                                  file_name={
                                    this.props.handler_and_maplocation_data
                                      .cust_reg_json.contact_persons[0]
                                      .photo_id_proof.value
                                  }
                                  rotate={false}
                                  saved_file_name={"photo_national_id"}
                                  lead_id={this.props.lead_id}
                                  addl_file_name={null}
                                  view_lead={() => this.props.view_lead()}
                                  actual_file={"handler_national_id"}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-6 ">
                              <div className="m-0 text-light border h-100 w-100">
                                <div className=" py-4">
                                  <div className="align-items-center my-4 py-4 mx-2">
                                    <p className="no-margin text-white pl-4 business_operations_text">
                                      {" "}
                                      FIRST NAME{" "}
                                    </p>
                                    <h2 className="text-white pl-4 audit_kyc_text">
                                      {" "}
                                      <b>
                                        {capitalize_first_letter(
                                          this.props.handler_and_maplocation_data
                                            .cust_reg_json.contact_persons[0]
                                            .first_name.value
                                        )}
                                      </b>
                                    </h2>
                                  </div>

                                  <div className="align-items-center my-4 py-4 mx-2">
                                    <p className="no-margin text-white col-4 business_operations_text ">
                                      {" "}
                                      LAST NAME{" "}
                                    </p>
                                    <h2 className="text-white  pl-4 audit_kyc_text">
                                      {" "}
                                      <b>
                                        {" "}
                                        {
                                          this.props.handler_and_maplocation_data
                                            .cust_reg_json.contact_persons[0]
                                            .last_name.value
                                        }
                                      </b>
                                    </h2>
                                  </div>

                                  <div className="d-flex row align-items-center py-4 my-4 mx-2">
                                    <div className="col-4">
                                      <p className="no-margin text-white  business_operations_text ">
                                        {" "}
                                        NATIONALITY{" "}
                                      </p>
                                      <h2 className="text-white  audit_kyc_text">
                                        <b>{get("market").country_code}</b>
                                      </h2>
                                    </div>

                                    <div className="col-4">
                                      <p className="no-margin text-white  business_operations_text">
                                        {" "}
                                        GENDER
                                      </p>
                                      <h2 className="text-white  audit_kyc_text">
                                        <b>
                                          {" "}
                                          {capitalize_first_letter(
                                            this.props
                                              .handler_and_maplocation_data
                                              .cust_reg_json.contact_persons[0]
                                              .gender.value
                                          )}
                                        </b>
                                      </h2>
                                    </div>

                                    <div className="col-4">
                                      <p className="no-margin text-white  business_operations_text ">
                                        {" "}
                                        DOB{" "}
                                      </p>
                                      <h2 className="text-white  audit_kyc_text">
                                        <b>
                                          {moment(
                                            this.props
                                              .handler_and_maplocation_data
                                              .cust_reg_json.contact_persons[0]
                                              .dob.value
                                          ).format("DD/MM/YYYY")}
                                        </b>
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="align-items-center py-4 my-4 mx-2">
                                    <p className="no-margin text-white col-4 business_operations_text">
                                      {" "}
                                      {getLabel("national_id")}{" "}
                                    </p>
                                    <h2 className="text-white pl-4 col-7 audit_kyc_text">
                                      <b>
                                        {
                                          this.props.handler_and_maplocation_data
                                            .cust_reg_json.contact_persons[0]
                                            .id_proof_num.value
                                        }
                                      </b>
                                    </h2>
                                  </div>
                                  <br />
                                </div>

                                {this.props.handler_and_maplocation_data
                                  .active_section && (
                                    <div>
                                      {this.props.handler_and_maplocation_data
                                        .active_section[0].verify && (
                                          <div className="closedStamp ">
                                            <div className="stamp is-nope audit_kyc_verified_stamp">
                                              Verified
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            {/* {this.props.handler_and_maplocation_data.form_spinner ? 
                  <div class="d-flex col-lg-10 py-2 px-5 mt-4 justify-content-center ml-5">
                  <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                  : */}
                            <div className="col-lg-10 py-2 px-5 mt-4">
                              {this.props.handler_and_maplocation_data
                                .active_section && (
                                  <form
                                    className={`${this.props.handler_and_maplocation_data
                                      .active_section[0].verify ||
                                      this.props.handler_and_maplocation_data
                                        .active_section[0].to_reassign == true
                                      ? "disable_div"
                                      : ""
                                      }  ${this.props.handler_and_maplocation_data
                                        .responseData.audited_by ==
                                        get("user_id") ||
                                        this.props.handler_and_maplocation_data
                                          .responseData.audited_by == null
                                        ? ""
                                        : "disable_div"
                                      }`}
                                  >
                                    <div className="d-flex align-items-center">
                                      <input
                                        type="checkbox"
                                        id="vehicle1"
                                        className="pointer"
                                        style={{ height: "19px", width: "20px" }}
                                        checked={
                                          audit_kyc &&
                                          this.props.audit_kyc["contact_persons"]
                                            .original_national_id &&
                                          true
                                        }
                                        name="vehicle1"
                                        value="Bike"
                                        onChange={(e) =>
                                          this.props.checkbox_verification(
                                            e.target.checked,
                                            "original_national_id"
                                          )
                                        }
                                      />

                                      <label
                                        className="no-margin pl-3 audit_kyc_text pointer"
                                        for="vehicle1"
                                      >
                                        The {getLabel("national_id")} details
                                        matches with the details entered by the {this.state.rm_label_short??''}
                                      </label>
                                      <br />
                                      <br />
                                      <br />
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <input
                                        type="checkbox"
                                        id="vehicle2"
                                        name="vehicle2"
                                        className="pointer"
                                        value="Car"
                                        checked={
                                          audit_kyc &&
                                          this.props.audit_kyc["contact_persons"]
                                            .clear_and_readable &&
                                          true
                                        }
                                        style={{ height: "18px", width: "20px" }}
                                        onChange={(e) =>
                                          this.props.checkbox_verification(
                                            e.target.checked,
                                            "clear_and_readable"
                                          )
                                        }
                                      />
                                      <label
                                        className="no-margin pl-3 audit_kyc_text pointer"
                                        for="vehicle2"
                                      >
                                        {" "}
                                        The text in the {getLabel("national_id")} is
                                        in a clear & readable state
                                      </label>
                                      <br />
                                      <br />
                                    </div>

                                    <div className="d-flex align-items-center mt-3">
                                      <input
                                        type="checkbox"
                                        id="vehicle3"
                                        name="vehicle3"
                                        className="pointer"
                                        checked={
                                          audit_kyc &&
                                          this.props.audit_kyc["contact_persons"]
                                            .face_recogonizable &&
                                          true
                                        }
                                        style={{ height: "18px", width: "20px" }}
                                        onChange={(e) =>
                                          this.props.checkbox_verification(
                                            e.target.checked,
                                            "face_recogonizable"
                                          )
                                        }
                                      />
                                      <label
                                        className="no-margin pl-3 audit_kyc_text pointer"
                                        for="vehicle3"
                                      >
                                        {" "}
                                        The face of the customer in the{" "}
                                        {getLabel("national_id")} is recognizable
                                      </label>
                                      <br />
                                    </div>
                                  </form>
                                )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    {this.props.handler_and_maplocation_data
                      ?.cust_reg_json?.contact_persons[0]
                      ?.contact_address && (
                        <div
                          className="col-lg-10 py-5 mt-5 px-5"
                          style={{ background: "#223b56" }}
                        >
                          <h2 className="text-white">
                            <b>
                              Handler {getLabel("national_id")} Back Side
                              Verification
                            </b>{" "}
                          </h2>


                          <div className="d-flex mt-4 row justify-content-evenly ">
                            <div className="col-md-12 col-lg-6 ">
                              <div
                                className="h-100 w-100"
                                style={{ border: "1px solid #D9D9D9" }}
                              >
                                <h2 className="text-white audit_kyc_text no-margin px-4 py-3">
                                  {" "}
                                  <b>Home Address </b>{" "}
                                  <span style={{ fontSize: "13px" }}>
                                    (as entered by {this.state.rm_label_short??''})
                                  </span>{" "}
                                </h2>
                                <hr className="no-margin" />
                                <div className=" p-4">
                                  {Object.entries(
                                    this.props.handler_and_maplocation_data
                                      .cust_reg_json.contact_persons[0]
                                      .contact_address
                                  ).map(([key, value]) => {
                                    return (
                                      <div className="d-flex row align-items-center my-3 mx-2 align-items-center">
                                        <p className="no-margin text-white col-4 business_operations_text">
                                          {" "}
                                          {dd_value(key.toUpperCase())}{" "}
                                        </p>
                                        <h2 className="text-white pl-4 col-8 audit_kyc_text">
                                          {" "}
                                          <b>
                                            {value.value ? (
                                              dd_value(
                                                capitalize_first_letter(
                                                  value.value
                                                )
                                              )
                                            ) : (
                                              <p className="text-white text-left no-margin">
                                                -
                                              </p>
                                            )}{" "}
                                          </b>
                                        </h2>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 col-lg-6">
                              <DisplayImage
                                image_path={get_dir_path(
                                  this.props.handler_and_maplocation_data
                                    .cust_reg_json.contact_persons[0]
                                    .photo_id_proof_back_full_path.value,
                                  this.props.handler_and_maplocation_data
                                    .cust_reg_json.contact_persons[0]
                                    .photo_id_proof_back.value
                                )}
                                rotate={false}
                                saved_file_name={"photo_national_id_back"}
                                lead_id={this.props.lead_id}
                                style={{ height: "500px", width: "100%" }}
                                file_name={
                                  this.props.handler_and_maplocation_data
                                    .cust_reg_json.contact_persons[0]
                                    .photo_id_proof_back.value
                                }
                                view_lead={() => this.props.view_lead()}
                              />
                            </div>
                          </div>

                        </div>
                      )}

                    {(photo_pps_full_path || photo_selfie_full_path) &&
                      (
                        <div
                          className="col-lg-10 py-5 mt-5 px-5"
                          style={{ background: "#223b56" }}
                        >
                          <div className="d-flex justify-content-around mt-5">
                            <div className="col-md-12 my-3 col-lg-5 no-padding">
                              <p className="no-margin text-white text-center py-3 audit_kyc_text">
                                HANDLER PHOTO
                              </p>
                              {photo_pps_full_path &&
                                <div className="col p-0">
                                  <DisplayImage
                                    rotate={false}
                                    saved_file_name={"photo_pps"}
                                    lead_id={this.props.lead_id}
                                    image_path={get_dir_path(
                                      this.props.handler_and_maplocation_data
                                        .cust_reg_json.contact_persons[0]
                                        .photo_pps_full_path.value,
                                      this.props.handler_and_maplocation_data
                                        .cust_reg_json.contact_persons[0]
                                        .photo_pps.value
                                    )}
                                    style={{ height: "100%", width: "100%" }}
                                    file_name={
                                      this.props.handler_and_maplocation_data
                                        .cust_reg_json.contact_persons[0]
                                        .photo_pps.value
                                    }
                                    view_lead={() => this.props.view_lead()}
                                  />
                                </div>
                              }

                            </div>
                            {photo_selfie_full_path &&
                              <div className="col-md-12 my-3 col-lg-5 no-padding">
                                <p className="no-margin text-white text-center py-3 audit_kyc_text">
                                  SELFIE PHOTO
                                </p>
                                <div className="col p-0">
                                  <DisplayImage
                                    rotate={false}
                                    saved_file_name={"photo_selfie"}
                                    view_lead={() => this.props.view_lead()}
                                    lead_id={this.props.lead_id}
                                    image_path={get_dir_path(
                                      this.props.handler_and_maplocation_data
                                        .cust_reg_json.contact_persons[0]
                                        .photo_selfie_full_path.value,
                                      this.props.handler_and_maplocation_data
                                        .cust_reg_json.contact_persons[0]
                                        .photo_selfie.value
                                    )}
                                    style={{ height: "100%", width: "100%" }}
                                    file_name={
                                      this.props.handler_and_maplocation_data
                                        .cust_reg_json.contact_persons[0]
                                        .photo_selfie.value
                                    }
                                  />
                                </div>
                              </div>
                            }
                          </div>

                        </div>
                      )}
                  </>
                </div>
              )}{" "}
            </>
          )}

        {this.props.handler_and_maplocation_data.audit_kyc_wizard_menus
          .gps_address && (
            <>
              {this.props.handler_and_maplocation_data
                .View_lead_section_loader ? (
                this.audit_section_spinner()
              ) : (
                <div className="row no-margin justify-content-center">
                  <div
                    className="col-lg-12 py-3 mt-5 "
                    style={{ background: " #01062c" }}
                  >
                    <h2 className="text-white text-center">
                      <b>
                        {" "}
                        {this.props.handler_and_maplocation_data.cust_reg_json
                          .same_as_owner_person
                          ? "2"
                          : "3"}{" "}
                        - GPS & Address
                      </b>
                    </h2>
                    <h2 className=" d-flex mt-1 text-center justify-content-center align-items-center">
                      <img src="/img/pending_with_rm.png" height={"50"} />{" "}
                      <b
                        className="ml-2 align-self-cente"
                        style={{ color: "#F3EA0B" }}
                      >
                        {" "}
                        Pending with{" "}
                        {this.props.handler_and_maplocation_data.active_section &&
                          (this.props.handler_and_maplocation_data.active_section
                            .pending_with == "rm"
                            ? this.state.rm_label_short??''
                            : "YOU")}
                      </b>
                    </h2>
                  </div>
                  {this.props.handler_and_maplocation_data
                    .View_lead_section_loader ? (
                    this.audit_section_spinner()
                  ) : (
                    <>
                      <div className="col-lg-10  px-5">
                        <div className=" mt-4 row ">
                          {this.props.handler_and_maplocation_data
                            .cust_reg_json && (
                              <div
                                className="col-md-6 col-lg-6 no-padding "
                                style={{ border: biz_addr.match_biz_cur_per_addr ? "2px solid #2fb8d8" : "2px solid #D9D9D9" }}
                              >
                                <div>
                                  <h2 className="audit_kyc_text no-margin px-4 py-3" style={{color: biz_addr.match_biz_cur_per_addr ? "#2fb8d8" : "#fff"}}>
                                    {" "}
                                    <b>Business Details</b>{" "}
                                    <span>(captured by {this.state.rm_label_short??''})</span>{" "}
                                  </h2>
                                  <hr className="no-margin" />
                                  <div className=" p-4">


                                    {this.props.handler_and_maplocation_data
                                      .cust_reg_json.id_proof.first_name && (
                                        <div className="d-flex row align-items-center my-4 mx-2 align-items-center">
                                          <p className="no-margin text-white col-4 business_operations_text">
                                            Customer Name
                                          </p>
                                          <p className="text-white m-0 pl-4 col-8 font_1rem">
                                            <b>
                                              {capitalize_first_letter(
                                                this.props
                                                  .handler_and_maplocation_data
                                                  .cust_reg_json.id_proof.first_name
                                                  .value
                                              )}
                                            </b>{" "}
                                            <b className="pl-3">
                                              {capitalize_first_letter(
                                                this.props
                                                  .handler_and_maplocation_data
                                                  .cust_reg_json.id_proof.last_name
                                                  .value
                                              )}
                                            </b>
                                          </p>
                                        </div>
                                      )}
                                    {addr_fields?.length > 0 &&
                                      addr_fields.map((item, idx) => {
                                        return (
                                          <div
                                            className="d-flex row align-items-center  my-4 mx-2"
                                            key={idx}
                                          >
                                            <p className="no-margin text-white col-4 business_operations_text">
                                              {dd_value(item)}
                                            </p>
                                            <p
                                              className="text-white pl-4 m-0 col-8 font_1rem"
                                              style={{ fontSize: "1rem" }}
                                            >
                                              {this.props
                                                .handler_and_maplocation_data
                                                .cust_reg_json.gps_address
                                                ?.biz_address[item]?.value ? (
                                                <b>
                                                  {capitalize_first_letter(
                                                    this.props
                                                      .handler_and_maplocation_data
                                                      .cust_reg_json.gps_address
                                                      .biz_address[item]?.value
                                                  )}
                                                </b>
                                              ) : (
                                                <p className="text-white no-margin">
                                                  -
                                                </p>
                                              )}
                                            </p>
                                          </div>
                                        );
                                      })}

                                    <div className="d-flex  row align-items-center my-4 mx-2">
                                      <p className="no-margin text-white col-4 business_operations_text ">
                                        {" "}
                                        Country
                                      </p>
                                      <p className="text-white m-0 col-8 pl-4 font_1rem">
                                        {" "}
                                        <b>{get("market").country}</b>
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                {this.props.handler_and_maplocation_data
                                  .active_section && (
                                    <div>
                                      {this.props.handler_and_maplocation_data
                                        .active_section.verify && (
                                          <div class="closedStamp ">
                                            <div className="stamp is-nope audit_kyc_verified_stamp">
                                              Verified
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  )}
                              </div>
                            )}
                          <div className="col-md-6 col-lg-6">
                            <GoogleMapSetup
                              coordinates={
                                this.props.handler_and_maplocation_data
                                  .cust_reg_json.gps_address.gps.value
                              }
                              height={"556px"}
                            />
                          </div>
                          <p
                            className="no-margin text-white pt-3"
                            style={{ fontSize: "13px" }}
                          >
                            <b style={{ color: "red" }}>Note :</b> Zoom In/Zoom
                            Out to verify if the gps location pointed by the {this.state.rm_label_short??''}
                            matches with the business address captured
                          </p>
                        </div>
                      </div>

                      {this.props.handler_and_maplocation_data.active_section && (
                        <div className="col-lg-10 py-2 px-5 mt-4">
                          {/* {this.props.handler_and_maplocation_data.form_spinner ? 
                this.checkbox_spinner()
                : */}
                          <form
                            className={`${this.props.handler_and_maplocation_data
                              .active_section.verify ||
                              this.props.handler_and_maplocation_data
                                .active_section.to_reassign == true
                              ? "disable_div"
                              : ""
                              }   ${this.props.handler_and_maplocation_data.responseData
                                .audited_by == get("user_id") ||
                                this.props.handler_and_maplocation_data.responseData
                                  .audited_by == null
                                ? ""
                                : "disable_div"
                              }`}
                          >
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                id="vehicle1"
                                className="pointer"
                                style={{ height: "19px", width: "20px" }}
                                checked={
                                  audit_kyc &&
                                  this.props.audit_kyc["gps_address"]
                                    .gps_address_match &&
                                  true
                                }
                                onChange={(e) =>
                                  this.props.checkbox_verification(
                                    e.target.checked,
                                    "gps_address_match"
                                  )
                                }
                              />
                              <label
                                className="no-margin pl-3 audit_kyc_text pointer"
                                for="vehicle1"
                              >
                                The location in the Business Address matches with
                                the gps location{" "}
                              </label>
                              <br />
                              <br />
                            </div>
                            <br />
                          </form>
                          {/* }   */}
                        </div>
                      )}

                      <div className="col-lg-10  px-5">
                        <Grid container spacing={1}>
                          { owner_addr && this.objhas_any_value(owner_addr)  &&  (
                              <Grid item xs={12} lg={6}>
                                <Box sx={{ border: owner_addr?.match_biz_cur_per_addr ? "2px solid #2fb8d8" : "2px solid white", py: 2, px: 5 }}>
                                  <Box >
                                    <b>
                                      <SubTitleVarientOneTypo
                                        text={"Current Residential Address"}
                                        overRideStyles={{
                                          color: owner_addr?.match_biz_cur_per_addr ? "#2fb8d8" : "#fff",
                                          textTransform: 'none',
                                          fontWeight: 700 
                                        }}
                                      />
                                    </b>
                                    <div className="d-flex align-items-center">
                                     {owner_addr.verification_method?.value && <>
                                      {owner_addr.verification_method?.value == 'reference' ?
                                        <span>
                                          <span className="text-white">{'(Verified by '}</span> <Link className="audit_cust_url" target="blank" to={`/borrower/indiv/view/${owner_addr.ref_cust_id.value}`} >{owner_addr.ref_cust_id.value}</Link><span className="text-white">{')'}</span>
                                        </span> :
                                        <BodyVarientOneTypo
                                          text={`(Verified by ${owner_addr.verification_method?.value
                                            ? owner_addr.verification_method.value == 'others' ? 
                                            owner_addr.ref_mobile_num.value : 
                                              dd_value(
                                                owner_addr.verification_method.value
                                            )
                                            : "-"
                                            })`}
                                          overRideStyles={{ color: 'white', pt: 1 }}
                                        />
                                      }
                                      </>
                                      }
                                      
                                      {this.state?.lead_num_verify_call && (owner_addr?.verification_method?.value == 'others' ||  
                                      owner_addr?.verification_method?.value == 'reference') && 
                                      <div id="dial-icon" className={`call-icon lead_call_icon ml-3 ${this.props.softPhoneState.verification_call || disable_call_condn ? 'disable_div' : ''}`} 
                                        onClick={()=> this.handleCall(owner_addr.ref_mobile_num.value, owner_addr.verification_method.value, owner_addr.verification_method.value == 'reference' ? owner_addr.ref_cust_id.value : this.props.value)} type="button">

                                        <FaPhoneAlt style={{color:"white"}}/>
                                      </div>
                                      }
                                    </div>

                                  </Box>
                                  
                                  <Box>
                                    {addr_fields?.length > 0 &&
                                      addr_fields.map((_item, idx) => {
                                        return (
                                          <Box sx={{ py: 2 }}>
                                            <TextField
                                              key={idx}
                                              label={dd_value(_item)}
                                              val={(owner_addr[_item]?.value) ? dd_value(owner_addr[_item]?.value) : '-'}
                                              direction="row"
                                            />
                                          </Box>
                                        );
                                      })
                                    }
                                  </Box>
                                </Box>

                              </Grid>
                            )}

                          {permanent_addr&&  this.objhas_any_value(permanent_addr)  &&(
                              <Grid item xs={12} lg={6}>
                                <Box sx={{ border: permanent_addr?.match_biz_cur_per_addr ? "2px solid #2fb8d8" : "2px solid white", py: 2, px: 5, height: "100%" }}>
                                  <Box >
                                    <b>
                                      <SubTitleVarientOneTypo
                                        text={"Permanent Residential Address"}
                                        overRideStyles={{
                                          color: permanent_addr?.match_biz_cur_per_addr ? "#2fb8d8" : "#fff",
                                          textTransform: 'none',
                                          fontWeight: 700
                                        }}
                                      />
                                    </b>

                                  </Box>
                                  <Box sx={{mt:3}}>
                                    {addr_fields?.length > 0 &&
                                      addr_fields.map((_item, idx) => {
                                        return (
                                          <Box sx={{ py: 2 }}>
                                            <TextField
                                              key={idx}
                                              label={dd_value(_item)}
                                              val={permanent_addr[_item]?.value ? dd_value(permanent_addr[_item]?.value) : '-'}
                                              direction="row"
                                            />
                                          </Box>
                                        );
                                      })
                                    }
                                  </Box>
                                </Box>
                              </Grid>
                            )}
                        </Grid> 
                        {owner_addr &&  this.objhas_any_value(owner_addr) &&
                         owner_addr?.verification_method?.value &&
                          
                          (
                            <form
                              className={`${this.props.handler_and_maplocation_data
                                .active_section.verify ||
                                this.props.handler_and_maplocation_data
                                  .active_section.to_reassign == true
                                ? "disable_div"
                                : ""
                                }   ${this.props.handler_and_maplocation_data.responseData
                                  .audited_by == get("user_id") ||
                                  this.props.handler_and_maplocation_data.responseData
                                    .audited_by == null
                                  ? ""
                                  : "disable_div"
                                }`}
                            >
                            {matchedAddressJson.length >= 2 && // length = 2 means any 2 address matched, length > 2 means all the 3 address are matched
                              <p
                                className="no-margin text-white pt-3"
                                style={{ fontSize: "13px" }}
                              >
                                <b style={{ color: "red" }}>
                                  Note :
                                  </b> 
                                    {`The customer’s ${matchedAddressJson.map((mAj, idx) => { // returns the same address names here
                                      if(idx === matchedAddressJson.length - 1) {
                                        return `and ${mAj.name}`
                                      } else return mAj.name
                                    }).flat()} are same verify it from your end.`}
                              </p>
                            }
                              <div className="d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  id="confirm_cur_res_address"
                                  className="pointer"
                                  style={{ height: "19px", width: "20px" }}
                                  checked={
                                    audit_kyc &&
                                    this.props.audit_kyc["gps_address"]
                                      .confirm_cur_res_address &&
                                    true
                                  }
                                  onChange={(e) =>
                                    this.props.checkbox_verification(
                                      e.target.checked,
                                      "confirm_cur_res_address"
                                    )
                                  }
                                />
                                <label
                                  className="no-margin pl-3 audit_kyc_text pointer"
                                  for="confirm_cur_res_address"
                                >
                                  {/* I have confirmed the current residential address
                                  from the relationship manager {" "} */}
                                  {this.getOwnerAddressText()}
                                </label>
                                <br />
                                <br />
                              </div>
                              <br />
                            </form>
                          )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          )}
          {
            this.props.softPhoneState.verification_call && 
            <Softphone screen_from ={"verification_call"} is_direct_outgoing = {true} outgoing_call_data = {this.props.softPhoneState.outgoing_call_data}/>
          }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    audit_kyc: state.common_slice,
    softPhoneState: state.cs_soft_phone
  };
};

const mapDispatchToProps ={
  setValuesIntoRedux

};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuditKYCMapandHandlerComponent));