import { Box, useTheme } from '@mui/material';
import React from 'react';
import { HeaderVarientH2Typo } from '../../../CommonComponent/typography';
import FilterTabs from './Components/FilterTabs';
import CollectionFollowUps from './Components/CollectionFollowUps';

const DelinquencyReport = () => {

    const theme = useTheme()
    return (
        <Box>
            <HeaderVarientH2Typo
                text="Collections Due Report"
                overRideStyles={{
                    color: theme.palette.text.primary,
                    textAlign: "center",
                }}
            />
            <CollectionFollowUps/>
        </Box>
    );
};

export default DelinquencyReport;