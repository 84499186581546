const custom_colors = {
  dark: {
    primary_50: "#e7e8eb",
    primary_100: "#b5b8c1",
    primary_200: "#9295a3",
    primary_300: "#606578",
    primary_400: "#41475e",
    primary_500: "#111936", //side pannel card background
    primary_600: "#0f1731",
    primary_700: "#0c1226",
    primary_800: "#090e1e", //table background
    old_primary_900: "#070b17", //background
    primary_900: "#1a2035",
    old_primary_400: "#202940",

    secondary_50: "#e6f0f9",
    secondary_100: "#b0d1ed",
    secondary_200: "#8abae4",
    secondary_300: "#549bd8",
    secondary_400: "#3387d1",
    secondary_500: "#0069c5", //buttons
    secondary_600: "#0060b3",
    secondary_700: "#004b8c",
    secondary_800: "#003a6c",
    secondary_900: "#002c53",

    secondary_a_50: "#f1f1f1", //text
    secondary_a_100: "#d4d4d4",
    secondary_a_200: "#c0c0c0",
    secondary_a_300: "#a3a3a3",
    secondary_a_400: "#919191",
    secondary_a_500: "#757575",
    secondary_a_600: "#6a6a6a",
    secondary_a_700: "#535353",
    secondary_a_800: "#404040",
    secondary_a_900: "#313131",

    secondary_b_50: "#b4d4f0", //checkbox
    grey: "#3D3E53",
    url : '#008fc1',
    text:'black'
  },

  light: {
    primary: "#7944e3",
    primary_50: "#f2ecfc",
    primary_100: "#d5c5f6",
    primary_200: "#c1a9f2",
    primary_300: "#a582ec",
    primary_400: "#9469e9",
    primary_500: "#7944e3",
    primary_600: "#6e3ecf",
    primary_700: "#5630a1",
    primary_800: "#43257d",
    primary_900: "#331d5f",

    secondary: "#4444e3",
    secondary_50: "#ececfc",
    secondary_100: "#c5c5f6",
    secondary_200: "#a9a9f2",
    secondary_300: "#8282ec",
    secondary_400: "#6969e9",
    secondary_500: "#4444e3",
    secondary_600: "#3e3ecf",
    secondary_700: "#3030a1",
    secondary_800: "#25257d",
    secondary_900: "#1d1d5f",

    grey: "#757575",
    grey_50: "#f1f1f1",
    grey_100: "#d4d4d4",
    grey_200: "#c0c0c0",
    grey_300: "#a3a3a3",
    grey_400: "#919191",
    grey_500: "#757575",
    grey_600: "#6a6a6a",
    grey_700: "#535353",
    grey_800: "#404040",
    grey_900: "#313131",

    negative: "#e33434",
    negative_50: "#fcebeb",
    negative_100: "#f6c0c0",
    negative_200: "#f2a2a2",
    negative_300: "#ec7777",
    negative_400: "#e95d5d",
    negative_500: "#e33434",
    negative_600: "#cf2f2f",
    negative_700: "#a12525",
    negative_800: "#7d1d1d",
    negative_900: "#5f1616",

    positive: "#25a135",
    positive_50: "#e9f6eb",
    positive_100: "#bbe2c0",
    positive_200: "#9bd4a2",
    positive_300: "#6dc078",
    positive_400: "#51b45d",
    positive_500: "#25a135",
    positive_600: "#229330",
    positive_700: "#1a7226",
    positive_800: "#14591d",
    positive_900: "#104416",

    warning: "#e3A834",
    warning_50: "#fcf6eb",
    warning_100: "#f6e4c0",
    warning_200: "#f2d7a2",
    warning_300: "#ecc577",
    warning_400: "#e9b95d",
    warning_500: "#e3a834",
    warning_600: "#cf992f",
    warning_700: "#a17725",
    warning_800: "#7d5c1d",
    warning_900: "#5f4716",

    secondary_b_50: "#b4d4f0", //checkbox

    primary: "#4444e3",

    secondary: "#ffff",

    background: "#ffff",
    text: "black",
  },
};

export default custom_colors;
