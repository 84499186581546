import React from 'react';
import {Form, Col ,Modal, Row, Button,checkbox } from 'react-bootstrap';
import DatePicker from 'react-datetime';
import {BiCalendar} from "react-icons/bi";
import FlowComponent from '../../common/component/core/FlowComponent';
import moment from 'moment';
import {selfRegistrationReport,get_data,allocate_lead} from '../../../actions/lead_actions';
import { BasicReactTable } from '../../../helpers/react_table'
import {dd_value,dd} from '../../../actions/common_actions';
import {format_date_tbl,title_case,Homeacc_prvdrLogoselector} from '../../../helpers/common_helper';
import Select from "react-select";
import SelfRegContainer from "../../lead/container/SelfRegContainer";
import { Config, get } from '../../../helpers/storage_helper';
import { FcInfo } from "react-icons/fc";
import { Tooltip , OverlayTrigger} from 'react-bootstrap';

class SelfRegistrationReport extends FlowComponent {

    state = {
        criteria:{},
        loader:false,
        self_registered_customers:[],
        columndatas:[], 
     
        lead_details:[
            {name:"Lead Name",key:"biz_name"},
            {name:"Account Number",key:"account_num"},
            {name:"Contact Number",key:"mobile_num"},
            {name:"Account Ownership",key:"acc_owner_type"},
            {name:"WhatsApp Number",key:"whatsapp"},
            {name:"Agent line Number",key:"alt_acc_num"},
            {name:"District",key:"district"},
            {name:"Sector",key:"sector"},
            {name:"Cell",key:"cell"},
            {name:"Village",key:"village"},
            {name:"Landmark",key:"landmark"}
        ],

        referral_details:[{name:"Referred By",key:"referred_by"},{name:"Referral Name",key:"referral_name"},{name:"Referral Customer’s ID",key:"cust_id"},{name:"Referral Mob No.",key:"mobile_num"},{name:"Referred On",key:"reffered_on"}],
    }
    
    componentWillMount(){
        this.setOptionsListToStateFromAppApi('common', 'list', {...this.def_req ,status: "enabled", biz_account: true}, ['acc_prvdr_code', 'name'], "acc_prvdr");
        this.load_flow_rel_mgr(null,"multiselect_rm_name");
        this.load_sales_rep(null,"sales_rep");
        this.setState(state=>state.columndatas=this.columndatas())

      }

      get_data_handler(data){
        var request={"id":data.id,"entity_id": data.id,"entity":"leads","country_code": get('market').country_code,"read_only":true}

        get_data(this.req(request))
        .then((response)=>{
            this.resp(response)
            if(!response){return}
            if(response.status=="success"){
                this.setState({ReportModal:true,ReportData:response.data,row_value:data})
                // window.location.replace(`/lead/edit/${id}`)

            }
        })

  
      }
    

    get_input_handler(value,key){

        let criteria={ ...this.state.criteria}
        criteria[key]=value

        this.setState({
            ...this.state,
            criteria,
        })

      
      
    }

    redirect_handler(data){
        this.get_data_handler(data)

    }

    submit_handler(){
        this.setState({loader:true})
        var req= { "criteria" : this.state.criteria}
        selfRegistrationReport(this.req(req))
        .then((response)=>{
            this.resp(response)

            if(!response){
                this.setState({loader:false})
                return ""}
            if(response.status=="success"){
                this.setState({self_registered_customers:response.data,loader:false})
            }
        })
    }

    handleApprovalModalClose(){
        this.setState({ReportModal:!this.state.ReportModal})
    }

    columndatas () {
        const columns = [
            {
              Header : <div className='text-center'>SI No</div>,
              accessor: "account_id",      
              maxWidth:100,   
             
              show:true,       
              Cell : cell =>{return <div className='text-center'>{cell.row.index+1}</div>}
          },
          {
            Header : <div className='text-center'>Customer Name</div> ,
            id: "cust_name",
            maxWidth:150,
            
            accessor: "cust_name",  
            Cell : cell =>{return <div className='text-center'>{cell.row.original.cust_name}</div>}
  
    
        },
          {
            Header :<div className='text-center'>Acc Number</div>  ,
            id: "acc_prvdr",
            maxWidth:150,
            
            accessor: "acc_prvdr_code",  

            Cell : cell =>{return <div className='d-flex align-items-center'>  <Homeacc_prvdrLogoselector logotype={ cell.row.original.acc_prvdr_code}/> <p className='text-white m-0 pl-2'>{cell.row.original.account_num}</p></div> }
  
          },
          {
            Header :  <div className='text-center'>Contact Number</div>  ,
            id: "mobile_num",
            maxWidth:150,
            accessor: "mobile_num",  

            Cell : cell =>{return    <p className='text-white text-center  m-0 pl-2'>{cell.row.original.mobile_num ? cell.row.original.mobile_num:"-"}</p> }
  
          },
            
          {
          
            Header : <div className='text-center'>Referred by</div> ,
            id: "referred by",
            maxWidth:150,
            
            accessor: "referred_by",  
            Cell : cell =>{return <div className='text-center'>{ dd_value(cell.row.original.referred_by,"role_code")}  {cell.row.original.referred_by == "customer" ? 
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{<div>
                <a href={`/borrower/indiv/view/${cell.row.original.cust_id}`} className='a_link'>{cell.row.original.cust_id}</a>
                <p className=' m-0' style={{fontSize:'12px'}} > <b>{cell.row.original.cust_mobile_num}</b></p>
                </div>}
            </Tooltip>}>
            <FcInfo  size={23}/>
          </OverlayTrigger>   
            
           
            :""}</div>}     
        },
        {
          
            Header : <div className='text-center'>Referral Name</div>  ,
            id: "referral_name",
            maxWidth:150,
            
            accessor: "referral_name",  
            Cell : cell =>{return <div className='text-center'>{cell.row.original.referral_name}</div>}     
        },
        {
          
            Header : <div className='text-center'>Referral Date</div>  ,
            id: "reffered_on",
            maxWidth:150,
            
            accessor: "reffered_on",  
            Cell : cell =>{return <div className='text-center'>{cell.row.original.reffered_on ? moment(cell.row.original.reffered_on ).format("DD MMM YYYY"):'N/A'}</div>}     
           },
           {
            Header : <div className='text-center'>RM name</div>  ,
            id: "rm_name",
            maxWidth:150,
            
            accessor: "rm_name",  
            Cell : cell =>{return <div className='text-center'>{cell.row.original.rm_name ? cell.row.original.rm_name : "-"}</div>}     

        },
        {
          
            Header :  <div className='text-center'>Status</div>  ,
            id: "status",
            maxWidth:160,
            
            accessor: "status",  
            Cell : cell =>{return <div className='text-center'>{ cell.row.original.status? dd_value (cell.row.original.status,"lead_status"):"-"}</div>}     
        },
        {
          
            Header : <p className='text-center'>Close reason</p>  ,
            id: "close_reason",
            width:180,
            
            accessor: "close_reason",  
            Cell : cell =>{return <div className='text-center'>{cell.row.original.close_reason ? dd_value(cell.row.original.close_reason) : "-"}</div>}     
        },
        {
           
            Header : <div className='text-center'>Onboarded Date</div> ,
            id: "onboarded_date",
            maxWidth:150,
            
            accessor: "onboarded_date",  
            Cell : cell =>{return <div className='text-center'>{ cell.row.original.onboarded_date? moment (cell.row.original.onboarded_date).format("DD MMM Y"):"-"}</div>}     
        },
       
        {
          
            Header : <div className='text-center'>Action</div>  ,
            id: "Action",
            maxWidth:150,
            accessor: "action",  
            Cell : cell =>{return <button type="button" class="btn btn-primary" onClick={()=>{this.redirect_handler(cell.row.original,"read_only_mode",)}}>View</button>
        }     
        },
         
         
      ]
      return columns.filter((column) => column.show !== false);
    }

    clear_handler(){
        this.setState({criteria:{}})
    }

    isValidDate(current) {
        const today = moment().startOf('day');
        return current.isBefore(today);
      }
    handleSubmit(){
       
        
        var req={rm_id: this.state.assign_rm,lead_id:this.state.row_value.id}
        allocate_lead(this.req(req))
        .then((response)=>{
            this.resp(response)

            if(!response){ 
                this.setState({edit:false})
                return}
            if(response&& response.status == "success"){
                alert("RM assigned successfully.")
                this.submit_handler()
                this.setState({ReportModal:false,assign_rm:"",edit:false})
            }
        })

    }

     addl_addr_fields (key){
        if(['sector', 'cell', 'village'].includes(key) ){
            let addr_fields = Config('addr_hiearchy')?.[get('market').country_code]
            const is_show_addl_fields =(addr_fields).includes(key);
           return is_show_addl_fields;
        } else return true
        
       
     }

    render(){
      
        const show_submit = (this.state.edit || !this.state?.row_value?.rm_name)

        const is_disable =  (show_submit && this.state.assign_rm ) ? false: ( !this.state.edit && (this.state.assign_rm || this.state?.row_value?.rm_name ))? false : true
        
          return (
            <div className={this.state.ReportModal ? "disable_div":""}>
             <div className="container containerTopmargin max-width no-padding">
	      <div className="floatAdvancetitleBox">
	      	<h5 className='no-margin preHeaderpad' style={{paddingLeft:15,fontSize:18}}>Self Registration Report </h5>
	      </div>

          <div style={{background:'#202940'}}>
          <div className='row m-0 py-3' >
          <div className={'col-md-3 marT-5'}>
						
                        <div className="form-group searchInput_height">
                                    <label className='no-margin d-flex justify-content-between ' htmlFor='Referral_from'>
                                          <p className='text-white m-0'> Referral From</p>
                                          <p className='m-0 calender_position pointer' > <BiCalendar size={20}/></p> 
                                    </label>
                                    
                                    <DatePicker closeOnSelect={true} 
                                     isValidDate={(current) => {
                                        const today = moment().startOf('day');
                                        return current.isSameOrBefore(today, 'day');  }} 
                                        value={ this.state.criteria.referral_from ? moment(this.state.criteria.referral_from).format('DD MMM y'):''}  onChange={(value)=>{this.get_input_handler(  moment(value).format("YYYY-MM-DD"),"referral_from")}}  inputProps={{ id: 'Referral_from', name: 'Referral_from',autoComplete:'off' }} dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                        </div>
                                    
                                
                    </div>

            <div className={'col-md-3 marT-5'}>
						
                <div className="form-group searchInput_height">
                            <label className='no-margin d-flex justify-content-between ' htmlFor='Referral_to'>
                                  <p className='text-white m-0'> Referral To</p>
                                  <p className='m-0 calender_position pointer' > <BiCalendar size={20}/></p> 
                            </label>
                             
                            <DatePicker 
                             
                             isValidDate={(current) => {
                                const today = moment().startOf('day');
                                return current.isSameOrBefore(today, 'day');  }} 
                               closeOnSelect={true} value={ this.state.criteria.referral_to ? moment(this.state.criteria.referral_to).format('DD MMM y'):''} onChange={(value)=>{this.get_input_handler( moment(value).format("YYYY-MM-DD"),"referral_to")}}   inputProps={{ id: 'Referral_to', name: 'Referral_to',autoComplete:'off' }} dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                </div>
							
						
			</div>

            <div className={'col-md-3 marT-5'}>
						
                        <div className="form-group searchInput_height">
                            <label className='no-margin d-flex justify-content-between ' htmlFor='Self_Registered_From'>
                                  <p className='text-white m-0'> Self Registered From</p>
                                  <p className='m-0 calender_position pointer'  > <BiCalendar size={20}/></p> 
                            </label>
                            
                            <DatePicker
                            isValidDate={(current) => {
                                const today = moment().startOf('day');
                                return current.isSameOrBefore(today, 'day');  }} 
                             closeOnSelect={true} value={ this.state.criteria.self_reg_from ? moment(this.state.criteria.self_reg_from).format('DD MMM y'):''} onChange={(value)=>{this.get_input_handler( moment(value).format("YYYY-MM-DD"),"self_reg_from")}}   inputProps={{ id: 'Self_Registered_From', name: 'Self_Registered_From',autoComplete:'off' }} dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                        </div>
							
						
			</div>

            <div className={'col-md-3 marT-5'}>
						
                        <div className="form-group searchInput_height">
                            <label className='no-margin d-flex justify-content-between ' htmlFor='Self_Registered_To'>
                                  <p className='text-white m-0'> Self Registered To</p>
                                  <p className='m-0 calender_position pointer' > <BiCalendar size={20}/></p> 
                            </label>
                            
                            <DatePicker closeOnSelect={true} 
                            isValidDate={(current) => {
                                const today = moment().startOf('day');
                                return current.isSameOrBefore(today, 'day');  }} 
                            value={ this.state.criteria.Self_Registered_To ? moment(this.state.criteria.Self_Registered_To).format('DD MMM y'):''}  onChange={(value)=>{this.get_input_handler( moment(value).format("YYYY-MM-DD"),"self_reg_to")}}  inputProps={{ id: 'Self_Registered_To', name: 'Self_Registered_To',autoComplete:'off' }} dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                        </div>
							
						
			</div>
			
            <div className={'col-md-3 marT-5'}>

                    <div className="form-group searchInput_height">
					{/* <label className='no-margin'>Status</label> */}
					<select className="form-control" id="territory" value={ this.state.criteria.status ?this.state.criteria.status :""}  onChange={(e)=>{this.get_input_handler(e.target.value,"status")}}>
					    <option selected value="">Status</option>
					   { this.getSelectOptions("self_reg_web_status") }
				   </select>
				</div>
								
			</div>

            <div className={'col-md-3 marT-5'}>

                <div className="form-group searchInput_height">
                <select className="form-control" id="referred_by"  value={ this.state.criteria.referred_by?this.state.criteria.referred_by:"" } onChange={(e)=>{this.get_input_handler(e.target.value,"referred_by")}}>
                
                <option selected value="sales_rep">Sales Rep</option>
                <option selected value="customer">Customer</option>
                <option selected value="rm">RM</option>
                <option selected value="">Reffered by</option>
                    

                </select>
                </div>
                            
            </div>
            
            {/* {( this.state.criteria.referred_by && (this.state.criteria.referred_by=="sales_rep")|| (this.state.criteria.referred_by=="rm" )) &&
                 <div className={'col-md-3 marT-5'}>

                 <div className="form-group searchInput_height">
                    {(this.state.criteria.referred_by=="rm" ) ?

                    
                        
                 <select className="form-control" id="referred_by"  onChange={(e)=>{this.get_input_handler(e.target.value,"flow_rel_mgr_id")}}>
                     <option selected value="   ">RM Name</option>
                     <input type="text" id="username" onChange={(e)=>this.selectfilterOptionHandler(e.target.value)} name="username"/>

                     {this.state.flow_rel_mgr_dd} 
                 </select>
                  :
                     <div className="dropdown-container">
                        <Select
                           placeholder="Sales Rep Name"
                            onChange={(e)=>{this.setState({sales_rep_name :e},()=>{this.get_input_handler(e.value,"sales_rep_id")})}}
                            isSearchable={true}
                            value={this.state.sales_rep_name}
                            options={this.state.flow_sales_rep}
                            style={{color:'white'}}
                        />
                        </div>
                  
               
                  }
                 </div>
                             
             </div>
            } */}


            <div className={'col-md-3 marT-5'}>

                <div className="form-group searchInput_height">
                {/* <label className='no-margin'>Account Provider</label> */}
                <select className="form-control" id="acc_prvdr" value={ this.state.criteria.acc_prvdr_code ?this.state.criteria.acc_prvdr_code:""}  onChange={(e)=>{this.get_input_handler(e.target.value,"acc_prvdr_code")}} >
                    <option selected value="">Account Provider</option>
                    {this.state.acc_prvdr_dd}

                </select>
                </div>
                            
            </div>
			
    	  </div>
          
          <h4 className='text-center py-5'> <b>OR</b> </h4>

          <div className='row m-0 pb-5 '>
            <div className='col-6'>
            <Form.Group as={Col} className={`${this.state.section3} no-padding no-margin`} >
							<Form.Label className='no-margin'>Cust Name / Cust ID / Account Num / Mobile No / National ID </Form.Label>
							<Form.Control id="req_parameter"  value={ this.state.criteria.no_criteria? this.state.criteria.no_criteria:" "}   onChange={(e)=>{this.get_input_handler(e.target.value,"no_criteria")}}>
							</Form.Control>
			</Form.Group>
            </div>


          </div>

          <div className='row justify-content-center pt-3 pb-5'>
            <div className='col-3 d-flex justify-content-center '>
            <button type="button" class="btn btn-secondary" onClick={()=>this.clear_handler()}>clear</button>

            <button type="button" class="btn btn-primary ml-2" disabled={this.state.loader} onClick={()=>this.submit_handler()}>Submit</button>

            </div>
          </div>
     
          </div>
            {this.state.self_registered_customers.length>0 &&
          <div className='my-3'>
           <h2 className='text-white sizexm'>Self Registered Customer</h2>
           <p className='m-0 text-white'> Total :{this.state.self_registered_customers.length}</p>
           {this.state.loader ? 
                <div class="d-flex justify-content-center">
                    <div class="spinner-border text-info" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
           :
           <div>
           <BasicReactTable row_data={this.state.self_registered_customers} column_data={this.state.columndatas} csv_file={true} FName="Self Registered Customers" searchData={[{title:'Self Registered Customers'}]}  pagination={true} global_search={true}  excess_time_action={false} default_page_size={15} /> 

           </div>
           }
          </div>
            }
           
			 
             </div>
          


        <Modal show={this.state.ReportModal} onHide={()=>this.handleApprovalModalClose()} size={"xl"}>

        <Modal.Header closeButton className=" py-0 d-flex align-items-center profile_summary_modal" show={this.state.ReportModal} onHide={()=>this.handleApprovalModalClose()}  style={{background:"#01062c"}}>
          <h2 className='text-white text-center w-100 mt-1 d-flex justify-content-center'>Agent Details</h2>

        </Modal.Header>
            <Modal.Body>
                {this.state.ReportData &&
                <div>
                    
                    <fieldset className=' self_report_border p-2 my-2 ' style={{borderRadius:'5px'}}>
                        <legend className='w-auto text-light sizem ml-4 px-2  mb-0'> <b>Lead Details</b> </legend>
                        <div className='row mx-0 my-3'>
                        {this.state.lead_details.map((item,idx)=>{return(
                           this.addl_addr_fields(item.key) ?
                            <div className='col-lg-6 py-3 col-md-12 col-sm-12 d-flex align-items-center row m-0'>               
                                <p className='m-0  text-white sizem col-6'> {item.name}</p>
                                

                                {(item.key=="biz_name") ? 
                                <p className={`m-0   text-white  sizem col-6 ${(item.key=="account_num") ? "d-flex align-items-center " :""}`} style={{fontWeight:'600'}}>
                                
                                <span>{(this.state.ReportData["first_name"]) ?  <b>{this.state.ReportData["first_name"] + " "+ this.state.ReportData["last_name"] } </b> :(this.state.ReportData["biz_name"])}</span>
                                 </p>
                                
                                :
                                <p className={`m-0   text-white  sizem col-6 ${(item.key=="account_num") ? "d-flex align-items-center " :""}`} style={{fontWeight:'600'}}>
                                    {(item.key=="account_num") &&  this.state.ReportData[item.key] &&
                                    <Homeacc_prvdrLogoselector logotype={this.state.ReportData["acc_prvdr_code"]}/>
                                    }
                                   <span className={`${(item.key=="account_num")  ? "pl-3":""}`}>  {this.state.ReportData[item.key] ?this.state.ReportData[item.key]:"-" } </span>
                                   
                                   </p>
                                   }
                            </div>:""
                        )})}
                        </div>
                    </fieldset>

                    <fieldset className=' self_report_border p-2 my-3 ' style={{borderRadius:'5px'}}>
                        <legend className='w-auto text-light sizem ml-4 px-2  mb-0'> <b>Referral Details</b></legend>
                        <div className='row mx-0 my-3'>
                        {this.state.referral_details.map((item,idx)=>{return(
                            <div className='col-lg-6 py-3 col-md-12 col-sm-12 d-flex align-items-center row m-0'>
                                <p className='m-0  text-white sizem col-6'> {item.name}</p>

                                <p className='m-0   text-white  sizem col-6' style={{fontWeight:'600'}}>
                                    {item.key=="cust_id" &&  this.state.row_value[item.key] ?
                                    <>
                                    <a href={`/borrower/indiv/view/${this.state.row_value[item.key]}`} className='sizem'>{this.state.row_value[item.key] ? dd_value(this.state.row_value[item.key]):"-" }</a>
                                    </>

                                    :

                                    <>
                                    {item.key=="reffered_on" ?  moment(this.state.row_value[item.key]).format("DD MMM YYYY"):                  
                                    <>
                                     {this.state.row_value[item.key] ? dd_value(this.state.row_value[item.key]):"-" }
                                     </>
                                     }
                                     </>
                        }
                                 </p>
                            </div>
                        )})}
                        </div>
                    </fieldset>{console.log('this.state.row_value', this.state.row_value)}

                    <fieldset className=' self_report_border p-2 my-3 ' style={{borderRadius:'5px'}}>
                        <legend className='w-auto text-light sizem ml-4 px-2  mb-0'> <b>RM Assignment</b></legend>
                        <div className='row mx-0 my-3'>
                       
                            <div className='col-lg-6 py-3 col-md-12 col-sm-12 d-flex align-items-center row m-0'>
                                <p className={`m-0  text-white sizem ${(this.state.row_value.rm_details) ? "col-6" :"col-4 "}`}> Assign RM</p>
                                {(this.state.row_value.rm_name && !this.state.edit) ? 
                                <p className='col-6 text-white sizem m-0'> <b>{this.state.row_value.rm_name}</b></p>
                                :

                                <div className='col-8 text-white resassign_text'>
                                
                                {/* <select className="form-control w-100" id="activity_status" onChange={(e) => {this.setState({assign_rm:e.target.value})}}>
                                    {this.state.flow_rel_mgr_dd}
                                </select> */}

                                <Select onChange={(e) => {this.setState({assign_rm:e.value})}}
                              value = {this.state.select_value}
							  options={this.state.flow_rel_mgr}
							  placeholder={"Choose"}
							  styles={this.select_custom_styles()}
							  classNamePrefix="my-select" // Add a custom class prefix to target the components
							  />

                                </div>
                                }
                            </div>
                       
                        </div>
                        {((!this.state.row_value.rm_details)|| this.state.edit) &&
                        <div className='d-flex align-items-center justify-content-center'>
                        <button type="button" class="btn btn-primary" disabled={is_disable }onClick={ ()=> show_submit ? this.handleSubmit() : this.setState({edit:true})}>{ show_submit? "Submit" :"Edit"} </button>

                        </div>}
                    </fieldset>
                       

                       
                     
                </div>
         }
            </Modal.Body>
          
        </Modal> 
        </div>
          );
      }
    }
    
    export default SelfRegistrationReport;



