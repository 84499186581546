import FlowComponent from '../common/component/core/FlowComponent';
import React from 'react';
import { pending_cashback_list, update_cashback_status,retry,pending_capture } from '../../actions/loan_actions';
import { lbl_amt, Homeacc_prvdrLogoselector, dynAccPrvdrFilter } from '../../helpers/common_helper';

import '../../styles/flow.css';

import { dd_value, touch } from "../../actions/common_actions";
import { check_priv, Config } from '../../helpers/storage_helper';

import * as moment from "moment";

import { get, set } from '../../helpers/storage_helper';
import { listAccountProvider } from '../../actions/account_provider_actions';

import Accordion from 'react-bootstrap/Accordion';
import { Card, Modal } from 'react-bootstrap'
import { BsChevronDown } from 'react-icons/bs';
import { BasicReactTable } from '../../helpers/react_table';
import { Overlay, Tooltip, OverlayTrigger } from 'react-bootstrap'
import AccProviderFilter from '../common/component/AccProviderFilter';
import { handleProviderSelection } from '../../helpers/helper';
import { connect } from 'react-redux';
import MUIAccordion from '../../MUI/Components/MUIAccordion';
import DataGridTable from '../CommonComponent/table';


class PendingCashbacks extends FlowComponent {
    state = {
        dynamic_filter: {
            dyn_Btn_selected: [],
            all_Btn_status: false,
            filter_actions: {},
            copy: [],
        },
        refresh: false,
        rowdatas: [],
        columndatas: [],
        cashback_col_fields: [
            { key: "acc_number", label: "Acc Number" },
            { key: "cashback_doc_id", label: "Cashback ID" },
            { key: "cust_id", label: "Customer ID" },
            { key: "biz_name", label: "Biz Name" },
            { key: "amount", label: "Amount" },
            { key: "type", label: "Type" },
            { key: "created_at", label: "Initiated" },
            { key: "disbursal_status", label: "Disbursal Status" },
            { key: "action", label: "Action" }],
        selectedProviders: [],
        filteredRows: [],
        page: { page: 0, pageSize: 10 },

    }

    handleToggle = (e) => {

        switch (e) {
            case ("Account"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
            case ("retry_modal") :
                this.setState({retry_modal:false});
                break
        }
    }

    componentWillMount() {

        const ls_market = get('market')
        const acc_prvdr_support_ussd = Config("acc_prvdr_support_ussd")
        this.setState({ acc_prvdr_support_ussd })
        if (this.props.type == "pending_cashback") {
            this.setState({ loader: true})

            this.getCashBackList("set_columns")

        }

    }

    getCashBackList = (type=false) => {

        pending_cashback_list(this.req())
            .then((response) => {
                
                if (!response) { 
                    this.setState({loader:false })
                     return }
                if (response.status == "success") {
                    if(type){
                        this.setState({ columndatas: this.columndatas()})
                    }
                    this.setState({rowdatas:response.data.results ,loader:false })

                    if(this.state.selectedProviders.includes("All") && !type ){
                        let selectedProviders= this.state.selectedProviders.filter(item => item !== "All");
                        this.setState({selectedProviders },()=>{
                            this.handle_acc_prvdr("All",response.data.results)
                        })
                    }else{
                        this.handle_acc_prvdr("All",response.data.results)
                    }
                }
            })
    }



    setDatas(response, acc_prvdrs) {
        const market = get('market')
        const market_name = market.country_code
        const data = response.data.results ;
        if (response.data.results.length > 0 || response.data.results.length === 0) {
            this.setState(state => state.all_count = response.data.results.length)
            this.setState({ rowdatas: data})
            const newArr = get([market_name] + '_' + `${this.props.type}`) === undefined ? this.state.all_Acc_pvdrs : get([market_name] + '_' + `${this.props.type}`)
            acc_prvdrs.map(each => {
                const count = data.filter(indiv => indiv.acc_prvdr_code === each)
                const length = count.length
                this.setState(state => state['dynamic_filter']['filter_actions'][each] = [{ count: 0 }, { status: newArr.includes(each) }],()=>{
                    this.dynClickhandler(this.state.dynamic_filter.filter_actions[each][1].status, each)

                })
                this.setState(state => state['dynamic_filter']['filter_actions'][each][0].count = length)

            })
        }
    }

    
    dynClickhandler(key, val) {

        var datas = dynAccPrvdrFilter(key, val, this.state.rowdatas, this.state.all_Acc_pvdrs, this.state.dynamic_filter.dyn_Btn_selected, this.state.dynamic_filter.filter_actions, this.state.dynamic_filter)
        this.setState({ dynamic_filter: datas })
    }

    failed_retry(data, value) {

        var req = {
            disbursal_status: value,
            cashback_doc_id: data.cashback_doc_id
        }
        update_cashback_status(this.req(req))
            .then((response) => {
                if (!response) { 
                    this.setState({ loader: false})
                    return }
                if (response.status == "success") {
                    this.getCashBackList()
                }
            })

    }

    update_disb_status(value, data) {
        if (value == "failed") {
            this.failed_retry(data, value)
        }
        if(value=="pending_capture"){
            this.setState({retry_modal:true,activeRow:data})
        }
        
    }

   retryHandler(data){   
    this.setState({ loader:true})
        var req = {
            cashback_doc_id: data.cashback_doc_id,
        }
        retry(this.req(req))
            .then((response) => {
                if (!response) { return }
                if (response.status == "success") {
                    alert(response.message)
                    this.getCashBackList()
                }
            })
   }

   update_pending_capture(){
    var req = {
        txn_id :this.state.txn_id,
        cashback_doc_id: this.state.activeRow.cashback_doc_id,
    }
    pending_capture(this.req(req))
        .then((response) => {
            if (!response) { this.setState({retry_modal:false}); return  }
            if (response.status == "success") {
                alert(response.message)
                this.setState({retry_modal:false})
                this.getCashBackList()
            }
        })
   }

    columndatas() {
        if (this.props.type == "pending_cashback") {
            const columns = [
                this.state.cashback_col_fields.map((item, idx) => {
                    return {
                        headerName: item.label,
                        field: item.key,
                        id: item.key,
                        headeralign: 'left', width:250 ,resizable: true, align: 'left',

                        renderCell: (cell) => {
                            {
                                return <div className='d-flex justify-content-start'>
                                    {item.key == "acc_number" ?
                                        <div className='d-flex align-items-center'> <Homeacc_prvdrLogoselector logotype={cell.row.acc_prvdr_code} /> <p className='no-margin' style={{ paddingLeft: '20px' }}> {cell.row.acc_number}</p>
                                        </div>
                                        :
                                        <>
                                            {(item.key == "action") ?
                                                <>{cell.row.disbursal_status == "failed" ?

                                                  <div className='d-flex align-items-center'>
                                                    <div>
                                                    <button type="button" class="btn btn-primary" onClick={() => { this.retryHandler(cell.row)}}>Retry</button>
                                                    </div>
                                                    
                                                    </div>
                                                   
                                                    
                                                    :
                                                    
                                                    <div>
                                                        {(cell.row.disbursal_status == "sent_to_batch_dsbrsl_queue" || cell.row.disbursal_status == "sent_to_disbursal_queue")  ? "-":
                                                        <select id="status" className="form-control" required="required" onChange={(e) => this.update_disb_status(e.target.value, cell.row)} >
                                                            <option value="">Choose</option>
                                                            <option value={"pending_capture"}>Pending Capture</option>
                                                            <option value="failed">Failed</option>

                                                        </select>}
                                                    </div>
                                                }
                                                </>
                                                :
                                                <p className='text-light m-0  '>

                                                    {
                                                        (item.key == "biz_name") ?
                                                        <OverlayTrigger key='local_balance' placement="top" overlay={
                                                            <Tooltip id="local_balance">{dd_value(cell.row[item.key])} {(item.key =="type" )? cell.row.recent_ontime_fas ? "- "+ cell.row.recent_ontime_fas  :"" :""}
                                                            </Tooltip>}>
                                                                <span>
                                                                    {( cell.row[item.key] && cell.row[item.key].length > 15  && item.key !== "type")?
                                                                     (dd_value(cell.row[item.key])) : 
                                                                    
                                                                    <>{dd_value(cell.row[item.key])} {(item.key =="type" )? cell.row.recent_ontime_fas ? "- "+ cell.row.recent_ontime_fas  :"" :""}</>}
                                                                </span>
                                                            </OverlayTrigger>
                                                            :

                                                            cell.row[item.key] ? ((item.key == "created_at") ? moment(cell.row[item.key]).format('DD MMM YYYY') :

                                                                (item.key == "amount" ? lbl_amt(cell.row[item.key], get('market').currency_code) : dd_value(cell.row[item.key],item.key=="type" ? "cashback_type":cell.row[item.key])))

                                                                : "NA"
                                                    }

                                                </p>
                                            }
                                        </>
                                    }
                                </div>
                            }
                        }


                    };
                }),
            ];

            return columns[0];
        }
    }

    handle_acc_prvdr(provider, rows = null  ) {
        let row_datas = rows ??this.state.rowdatas
        handleProviderSelection(
          provider,
          this.state.selectedProviders,
          this.props.acc_prvdr_list,
          row_datas,
          (updated_prvdr) => this.setState({ selectedProviders: updated_prvdr }),
          (filtered_rows) => this.setState({ filteredRows: filtered_rows })
        );
      }
    render() {
        return (

            <>
            <MUIAccordion
            title ={
                <div className="card-header card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between d-flex align-items-center">
                            <h4 className="card-title text-white tableHead_font title-bottom d-flex align-items-center" > {this.props.type ? dd_value(this.props.type) : "FAs Pending Disbursal "}</h4>
                             
                            <AccProviderFilter
                            rows={this.state.rowdatas??[]} 
                            handle_acc_prvdr={(provider)=>this.handle_acc_prvdr(provider)}
                            selectedProviders ={this.state.selectedProviders}
                            />
                           
                        </div>
            }

            body ={ 
          
            <DataGridTable
            rows={this.state.filteredRows??[]  } 
            paginationMode= {"client"}
            page={this.state.page}
            setpage={(newPage) => this.setState({ page: newPage })}
            isLoading={ this.state.loader }
            rowCount={this.state.filteredRows?.length}
            columns={this.state.columndatas??[]}
            noOfColumnsToLoad={10}

            />
            }
            total_count = { this.props.acc_prvdr_list?.length > 1 ? 0 : (this.state.rowdatas?.length)}
            >
                 

            </MUIAccordion>
            {this.state.retry_modal &&
                    <Modal className="confirm_modal pending_capture_modal" show={this.state.retry_modal} onHide={() => this.handleToggle("retry_modal")}
                     
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                       
                        <Modal.Body>
                            <p className='m-0 sizel text-white' style={{lineHeight:'25px'}}>Entry the matching transaction ID found in unknown for this referral </p>
                          
                          <div className='d-flex align-items-center my-3 row mx-0'>
                            <p className='m-0 col-3 text-white'>TXN ID <b className='text-danger'>*</b></p>
                            <div className='col-9 px-0'>
                            <input type="text"  id="model_code" autoComplete='off'  onChange={(e)=>{this.setState({txn_id:e.target.value})}} className="form-control col-12 w-100 "  />

                            </div>

                          </div>

                            <div className="d-flex align-items-center justify-content-end mt-5">
                                <button type="button" class="btn btn-confirm mx-3 " onClick={(e) => this.update_pending_capture()} >Confirm</button>
                                <button type="button" class="btn btn-cancel mx-2 text-white" onClick={() => this.handleToggle("retry_modal")} >Cancel</button>
                            </div>
                        </Modal.Body>

                    </Modal>}
            </>
          
        );

    }
}



const mapStateToProps = (state) => {
	return {
	  acc_prvdr_list : state.common_slice?.acc_prvdr_list

	};
  };

export default connect(mapStateToProps)(PendingCashbacks);

