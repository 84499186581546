import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

const PDFtoImage = React.memo(({ pdfUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function goToPrevPage() {
    setPageNumber(prevPageNumber => prevPageNumber - 1);
  }

  function goToNextPage() {
    setPageNumber(prevPageNumber => prevPageNumber + 1);
  }

  return (
    <div className="w-100 h-100">
      {pdfUrl && (
        <>
          <Document
            className="w-100 pdf_to_image"
            file={{ url: `${pdfUrl}` }}
            onLoadSuccess={onDocumentLoadSuccess}
            options={{
              renderMode:"none"
            }}
          >
            <Page className="w-100" pageNumber={pageNumber} />
          </Document>
         
          <div className="d-flex justify-content-center mt-3">
            <a href={pdfUrl} className="btn btn-success" target="_blank" rel="noopener noreferrer">
              Download PDF
            </a>
          </div>
        </>
      )}
      {pageNumber > 1 && (
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-success btn-sm"
            disabled={pageNumber <= 1}
            onClick={goToPrevPage}
          >
            Previous Page
          </button>
          <button
            className="btn btn-success btn-sm"
            disabled={pageNumber >= numPages}
            onClick={goToNextPage}
          >
            Next Page
          </button>
        </div>
      )}
    </div>
  );
});

export default PDFtoImage;
