import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {upload_file, remove_file, get_file_rel_path, upload_national_id} from '../../../actions/file_actions';
import { Modal } from 'react-bootstrap';
import { get_path } from '../../../helpers/common_helper';


class FileUpload extends FlowComponent {
  state = {action : "Upload", reset_btn: "show_div", view_btn: "hide_div", base64_format : null};

  resetFile = (event) => {
      this.fileInput.value = null;
      this.setState({action: "Upload",file:null});
  }

  constructor(){
    super();
    this.loaded = false;
    
    if(process.env.REACT_APP_USE_ROOT_ONLY_DEV == undefined){
     this.root = "";
    }else{
     this.root = "http://localhost:8000"; 
    }
  }
 
 componentWillMount(){
   this.setState({action : "Upload", reset_btn: "show_div", view_btn: "hide_div"});
  }
  componentWillReceiveProps(props){
    if(!this.loaded){
        if(props.mode == "view"){
          this.load_props_for_view(props,()=>this.loaded = true)
        }  
        else if(props.mode != "view"){
          this.loaded = true;
        }   
    }
  }


 
  load_props_for_view = (props) => {
       if(props.borrower_id){
            this.entity_type = "borrowers";
            this.entity_id = props.borrower_id;
            this.entity_code = props.cust_id;
       }else if(props.person_id){
          this.entity_type = "persons";
          this.entity_id = props.person_id;
          this.entity_code = props.person_id;
       }else if(props.lender_id){
          this.entity_type = "lenders";
          this.entity_id = props.lender_id;
          this.entity_code = props.lender_code;
       }else if(props.data_prvdr_id){
          this.entity_type = "data_prvdrs";
          this.entity_id = props.data_prvdr_id;
          this.entity_code = props.acc_prvdr_code;
       }else if(props.acc_prvdr_id){
          this.entity_type = "acc_providers";
          this.entity_id = props.acc_prvdr_id;
          this.entity_code = props.acc_prvdr_code;
       }else if(props.lead_id){
          this.entity_type = "leads";
          this.entity_id = props.lead_id;
          this.entity_code = props.lead_id;
       }
       else if(props.stmt_txn_proof){
        this.entity_type = "stmt_txn_proof";
        this.entity_id = props.txn_id;
        this.entity_code = 'stmt_txn_proof';
     };
      if(props.db_file_name){
        
        const request = {entity_code: this.entity_code, file_of: this.props.id};
        get_file_rel_path(this.req(request)).then((resp) => {
          this.resp(resp)
          if(!resp){return }; 
          const file_rel_path = resp.data.file_rel_path;
          this.setState({action : "Remove", reset_btn: "hide_div", view_btn: "show_div",
                      file_name:  props.db_file_name,file_rel_path});      
          });
        
      }
  }
  
  handleFileChange(event){
     
      let files = event.target.files; 
      this.createImage(files[0]);
   
  }

  createImage(file){
    
    let reader = new FileReader();
    reader.onload = (e) => {
     
      this.setState({file: e.target.result,
                    file_type: file['type'],
                    file_name: file['name'],
                    file_data_type: "data-url"
                  })
    };
    reader.readAsDataURL(file);
  }

  processUploadResponse(response, e){
      //e.target.orig_file_name = e.target.value; 
      //e.target.name = e.target.value; 

      const id = "orig_"+this.props.id;
      //const value = e.target.value;
      const value = response.data.original_file_name;
      var orig_file_name_event = {target: {id, value}} ;
      //alert(JSON.stringify(orig_file_name_event));
      //e.target.value =  response.data.file_type + '|' + response.data.file_name;
      e.target.id = this.props.id;
      e.target.value = response.data.file_name;

      if(this.props.stmt_txn_proof){
        this.props.handleStmt_txn_proof('uploaded',response)
      }
     if(this.props.mode === "view"){
        this.props.onEditUpload(e);  
        //this.props.onEditUpload(orig_file_name_event);  
      }else if(this.props.mode === "create"){       
        this.props.onCreateUpload(e); 
        this.props.onCreateUpload(orig_file_name_event); 
             
      }

      this.setState({action: "Remove", reset_btn: "hide_div", view_btn: "show_div", 
                   file_rel_path: response.data.file_rel_path,
                   file_name: response.data.file_name,});
      if(this.props.for == 'third_party_owner'){
        this.props.onSaveFile(this.props.id, response.data.file_name)
      }
     
      if(this.props.mode === "create"){
        alert("File attached successfully. Please submit the form to complete file upload.");
      }
  }


   encodeImageFileAsURL=(element) => {

    var file = element.target.files[0];
    var reader = new FileReader();
    reader.onloadend = () => {
      this.setState({base64_format: reader.result})
    }   
     reader.readAsDataURL(file);

  }

  handleUploadFile = (e) => {
    e.preventDefault();
    e.persist();
      if(this.state.action == "Upload"){
        this.props.editField && this.props.editField(this.props.id)
        if(this.props.entity == 'accounts' && this.props.id == 'photo_national_id'){
          const request = {file_of: this.props.id,
            cust_id: this.props.cust_id,
            type : this.props.person_type,
            file_data_type: this.state.file_data_type,
            file_data: this.state.base64_format,
            file_name: this.state.file_name,
            file_type: this.state.file_type, 
            file_of : this.props.id,
            country_code: this.country_code
          };

          if(request['file_data']){
            upload_national_id(this.req(request))
            .then((resp) => {
              this.resp(resp)
                if(!resp){return };
                  this.processUploadResponse(resp, e);
                  this.props.onTextract(resp.data.textract)
                });
            
          
          }else{
            alert("Please choose the file to upload");
          }

        }else{
          let request
          if(this.props.entity == "leads"){
            request = {file_of: this.props.id,
              txn_id :this.props.txn_id,
              cust_id: this.props.cust_id,
              aggr_doc_id: this.props.aggr_doc_id,
              file_data_type: this.state.file_data_type,
              file_data: this.state.file,
              file_name: this.state.file_name,
              file_type: this.state.file_type, 
              country_code: this.country_code,
              entity_id: this.props.entity_id? this.props.entity_id:this.props.entity,
              entity_code: this.props.lead_id,
              entity:this.props.entity_id? this.props.entity:"leads",
            };
          }
          else{
            request = {file_of: this.props.id,
              txn_id :this.props.txn_id,
              cust_id: this.props.cust_id,
              aggr_doc_id: this.props.aggr_doc_id,
              file_data_type: this.state.file_data_type,
              file_data: this.state.file,
              file_name: this.state.file_name,
              file_type: this.state.file_type, 
              country_code: this.country_code
            };
          }

          if(request['file_data']){

            upload_file(this.req(request)).then((resp) => {
                if(!resp){return }; 
                this.resp(resp)

              this.processUploadResponse(resp, e);
            

              });
            
          
          }else{
            alert("Please choose the file to upload");
          }
        }
        
        
      }
      else if(this.state.action == "Remove"){
       
        var resp = window.confirm("Are you sure to remove the file? The file will be permanently deleted.");

     
        if(resp){
          this.props.editField && this.props.editField(this.props.id)
            let id = this.props.id == 'photo_national_id' ? 'photo_id_proof' : (this.props.id == 'photo_national_id_back' ?  'photo_id_proof_back': this.props.id);
            const request = {file_of: id,
                          file_name: this.state.file_name,
                          country_code: this.country_code,
                          file_rel_path: this.state.file_rel_path,
                          entity_type: this.entity_type,
                          entity_id: this.entity_id
                          };

            remove_file(this.req(request)).then((resp) => 
              {
                  this.resp(resp)
                  this.setState({file_name : null, file:null, action: "Upload", reset_btn: "show_div", view_btn: "hide_div"});  
            
                  if(resp){

                    if(this.props.for == 'third_party_owner'){
                      this.props.onSaveFile(this.props.id, resp.data, "remove")
                    }
                    if(this.props.stmt_txn_proof){
                      this.props.handleStmt_txn_proof('removed')
                    }

                    if(this.props.remove_file){
                      
                     
                      
                      this.props.removeFile()
                    }
                    
                    if(this.props.mode === "create"){ 
                   
                    const id = "orig_"+this.props.id;
                    const value = null;
                    var orig_file_name_event = {target: {id, value}} ;
                    this.props.onCreateUpload(e); 
                    this.props.onCreateUpload(orig_file_name_event); 
                    }
                    
                     alert(resp.message);
                     this.props.onRemove && this.props.onRemove();
                   }
              });
        }
    
      }
      
  }


  handleViewButton = (event) => {
    this.setState({modalBox : true});
  }

  handleClose = (event) => {
    this.setState({modalBox: false});
  }


  render(){
    return(
      

      <div className="d-flex align-items-center">


          <div className="col-md-6 px-0">

            <span className='viewCus_label'>{this.props.label ? this.props.label : ""}</span>
            {this.props.is_mandatory &&
              <font className="text-danger">   *  </font>

            }
            {this.props.id == "cust_txn_file" &&
             
              <a href={get_path("view_file")+"/files/UGA/data_providers/sample_file.xls"}>( Download Sample File )</a>

            }

            {this.state.action === "Remove" ? 
              <b><label>{this.state.file_name}</label></b>  
              
            :  
              <input type="file" id="file" onChange={(e) => {this.handleFileChange(e);this.encodeImageFileAsURL(e)}} ref={ref => this.fileInput = ref} className="form-control" />    
            }
            
          </div>
          {(this.props.id == "cust_txn_file") ?
          <div></div>
          :
          <div className={this.state.view_btn}>     
            <div className="col-md-3">
              <input type="button" className="btn-sm btn-light" value="View" onClick={this.handleViewButton} />
            </div>
          </div>
        }

          <div id={this.props.id} value={this.state.file_name} className={` ${this.props.stmt_txn_proof?' mr-2 ':''} `} onClick={this.handleUploadFile}>
            <input type="button" className={`btn-sm ${this.state.action == "Upload" ? "btn-light" : "btn-secondary"}`} value={this.state.action} disabled={this.state.calling_api}  />
          </div>

          <div className={this.state.reset_btn}>
          <div className="col-md-3">
            <input type="button" className="btn-sm btn-secondary" value="Reset File" onClick={this.resetFile} />
          </div>
          </div>

          <div>

          {/*<Modal show={this.state.modalBox} onHide={this.handleClose} 
          className={(this.props.label == "Business Licence" || this.props.label == "Establishment Photo") ? "establishment" : "photo"}>
            <Modal.Header closeButton show={this.state.modalBox} onHide={this.handleClose} style={{padding: '0px'}}>
              <Modal.Title className={(this.props.label == "Business Licence" || this.props.label == "Establishment Photo") ? "establishment_head" : "photo_head"}>{this.props.label}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img  className={(this.props.label == "Logo") ? "logo_style" : "img_size"} 
                src={this.root + this.state.file_rel_path + "/" + this.state.file_name} 
                />
            </Modal.Body>
          </Modal>*/}


{/*dialogClassName={(this.props.label == "Logo") ? "modal-dialog-logo" : "modal-dialog-img_size"}
className="img_size" style={{display:'inline-block'}}*/}
           <Modal show={this.state.modalBox} onHide={this.handleClose} size={this.props.label == 'Logo'? 'sm' : 'lg'} >
              <Modal.Header closeButton className="modal-close-btn" show={this.state.modalBox} onHide={this.handleClose} >
                  <Modal.Title>
                    {this.props.label}
                  </Modal.Title>
              </Modal.Header>

              <Modal.Body>
             
              {(this.props.label == "Logo") ? 
                <img src={get_path("view_file") + this.state.file_rel_path + "/m_" + this.state.file_name} 
                alt={this.state.file_rel_path + "/m_" + this.state.file_name} />
              :
                
                <img 
                src={get_path("view_file") + this.state.file_rel_path + "/l_" + this.state.file_name} 
                alt={this.state.file_rel_path + "/l_" + this.state.file_name}
                />
              }
           
              </Modal.Body>

             
          </Modal>
         </div>

      </div>
     
      );
  }
      
}

export default FileUpload;