

import React, { useEffect, useState } from "react"
import { Config, get } from "../../../helpers/storage_helper"
import { IoIosGlobe } from "react-icons/io"
// import Flag from "react-flags";
import CustomFlag from "../../common/component/CustomFlag";
import { listAccountProvider } from "../../../actions/account_provider_actions";
import { IoIosPie, IoIosTrendingDown, IoIosTrendingUp } from "react-icons/io";
import { Accordion, Card, Form, ProgressBar } from "react-bootstrap";
import moment, { min } from "moment";
import { getReport, getAccountBalance } from "../../../actions/report_actions";
import ReportChart from "../component/ReportChart";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import DatePicker from 'react-datetime';
import { IoCalendarOutline } from "react-icons/io5";
import { components } from "react-select";

import { BiCalendar, BiSolidDollarCircle } from "react-icons/bi";
import { Homeacc_prvdrLogoselector, RoundOffSplitter, find_notification_time, format_amount, get_time_diff, get_time_zone_str, lbl_amt, short } from "../../../helpers/common_helper";
import { BsChevronDown, BsClockFill } from "react-icons/bs";
import CommonReactTable from "../../../helpers/commonReact_table";
import { BasicReactTable } from "../../../helpers/react_table";
import { dd_value } from "../../../actions/common_actions";
import MultiSelecter from "../../../helpers/multiSelecter";
import ReactSelect from "react-select";
import {Overlay, Tooltip, OverlayTrigger} from 'react-bootstrap'
import { FaHandHoldingUsd } from "react-icons/fa";
import CustomDatePicker from "../../../MUI/Components/CustomDatePicker";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

const FoundersHome = () => {

  var conversion_currencies = ['USD', 'EUR']
  const switchCountries = Config("switch_supported_countries") ?? [];
  const {market} =  useSelector(state=>state.HeaderSlice)
  const [Market, setMarket] = useState( 
    !get('market_list') || get('market_list')?.length<1 ? get('market'):
    { country_code: '*', country: 'Global' , currency_code:"USD"}
  )
  const [MarketList, setMarketList] = useState(null)
  const [AccprvdrList, setAccprvdrList] = useState([])
  const [Accprvdr, setAccprvdr] = useState(null);
  const [ResponseDataPast, setResponseDataPast] = useState(null)
  const [ResponseDataLive, setResponseDataLive] = useState(null)
  const [FilteredDataPast, setFilteredDataPast] = useState(null)
  const [FilterdDataLive, setFilteredDataLive] = useState(null)
  const [lastSixMonths, setlastSixMonths] = useState([])
  const [activeMonth, setactiveMonth] = useState(moment().format('YYYYMM'))
  const [active_toogle, setactive_toogle] = useState("")
  const [ActiveTab, setActiveTab] = useState("get_advances_disbursed_and_completed")
  const [charts_value, setcharts_value] = useState({})
  const [ActiveChartData, setActiveChartData] = useState("get_advances_disbursed_and_completed")
  const [PastCurrencyDetails, setPastCurrencyDetails] = useState(null)
  const [LiveCurrencyDetails, setLiveCurrencyDetails] = useState(null)
  const [AccountBalance, setAccountBalance] = useState(null)
  const [currentCountryactiveCurrency, setcurrentCountryactiveCurrency] = useState({
    active_currency: '',
  })
  const [LenderCode, setLenderCode] = useState(null)
  
  const [ActiveChartKey,   setActiveChartKey] = useState("loan_disbursed")

  const [ActiveCurrency, setActiveCurrency] = useState({
    currency_change: false,
    active_currency: '',
    active_currency_val: ''
  })

  const [ChartLoader,setChartLoader] = useState(false)
   const [Accordian, setAccordian] = useState(false)
  const [businessVolume, setbusinessVolume] = useState(false)

  const [TotalRevenue, setTotalRevenue] = useState(0)
  const [RevenueMonth, setRevenueMonth] = useState(moment().format('MMM YYYY'))
  const [originalRevenueData, setoriginalRevenueData] = useState({
    chart_data: [],
    total_revenue: ''
  })
  const [RevenueChart, setRevenueChart] = useState([])
  const [ColumnDatas, setColumnDatas] = useState([])
  const [Loader, setLoader] = useState(false)
  const [CohartChartData, setCohortChartData] = useState(null)
  const [ParDays, setParDays] = useState(null)
  const [SelectedParDays, setSelectedParDays] = useState([])
  const [ParChartDataOriginal, setParChartDataOriginal] = useState(null)
  const [LastWeekComparable, setLastWeekComparable] = useState(false)
  const [LastMonthComparable, setLastMonthComparable] = useState(false)

  const lender_list = useSelector((state=>state.common_slice?.markets_with_lender ))
  const market_list = get('market_list')
  const marketCodes = market_list?.map(country => country.country_code);
  const [switch_data, setSwitchDataState] = useState(false);

  useEffect(() => {
    if (MarketList && switchCountries ){
      if (switchCountries.includes(Market.country_code) || 
          (switchCountries.some(countryCode => marketCodes.includes(countryCode)) && Market.country_code === '*')) {
        setSwitchDataState(true);
      } else {
        setSwitchDataState(false);
      }
    }
  }, [Market, switchCountries, marketCodes]); 

  const [Month, setMonth] = useState("")
  const [ToggleState, setToggleState] = useState({})

  const customer_metrics = {
    cust_reg_count: 'Registered Customers',
    cust_active_count: 'FA Active Customers',
    cust_enabled_count: 'Enabled Customers'
  }
  const switch_customer_metrics = {
    cust_switch_count:'Switch Customers'
  }

  const revenue_months_to_show = 6;
  const os_portfolio = [
    { label: "OS Principal", key: "os_val_eom" , icon:<BiSolidDollarCircle size={25} color="white"/>, unit:"amount", increase_color : "green", decrease_color : "red" },
    { label: " OS Fee", key: "os_fee_eom" , unit:'amount', icon:<FaHandHoldingUsd size={25} color="white"/>, increase_color : "green", decrease_color : "red"},

    { label: "Outstanding Loans", key: "os_count_eom" , unit:"count" , icon:<FaHandHoldingUsd size={25} color="white"/>, increase_color : "green", decrease_color : "red"},
    { label: "Ontime Repayments", key: "ontime_repayment_rate", unit:"percentage" ,icon:<BsClockFill color="white" size={25}/>, increase_color : "green", decrease_color : "red"},

    { label: "Overdue Amount", key: "od_amount", unit:"amount" , icon:<FaHandHoldingUsd size={25} color="white"/>, increase_color : "red", decrease_color : "green"},
    { label: "Overdue Count", key: "od_count", unit:"count", icon:<FaHandHoldingUsd size={25} color="white"/> ,increase_color : "red", decrease_color : "green"},


  ]
  const portfolio_at_risk = [
    { label: "PAR 15 - 30", key: "par15_to_par30" ,increase_color : "red", decrease_color : "green"},
    { label: "PAR 30 - 60", key: "par30_to_par60" ,increase_color : "red", decrease_color : "green"},
    { label: "PAR 60 - 90", key: "par60_to_par90",increase_color : "red", decrease_color : "green" },
    { label: "PAR 90+", key: "par90" ,increase_color : "red", decrease_color : "green"},


  ]

  const performance = [
    { label: "Maximum Outstanding", key: "max_os_val" },
    { label: "Maximum Ontime Repayment", key: "max_ontime_repay_rate" }
  ]
  const businessVolumeArr = 
      [
      { label: "Loans Disbursed", key: 'loan_disbursed',report_type: "get_advances_disbursed_and_completed",chart_type:'bar', is_currency:false, },
      { label: "Amount Disbursed", key:`amount_disbursed` ,report_type:"get_tot_disb_report",chart_type:'area', is_currency:true, },
      { label: "Average Loan Size", key: 'average_loan_size',report_type: "average_Fa_amount",chart_type:'area', is_currency:true, },
      { label: "Average Loan Duration", key: 'average_loan_duration',report_type: "get_avg_loan_duration",chart_type:'bar', is_currency:false,  },
      { label: "Gross Transaction value", key: 'gross_txn_value', report_type: "get_gross_txn_value" ,chart_type:'bar', is_currency:true, },
      { label: "Ontime Payment", key: 'ontime_payment',report_type: 'get_ontime_payments',chart_type:'bar', is_currency:false, },
      { label: "Recovery Cohort", key: 'recovery_cohort',report_type: "cohort_report" ,chart_type:'bar', is_currency:false,  },
      { label: "Total & New overdue FAs", key: 'get_total_and_overdue_fa', key_array:['Total Disbursed', 'New Overdues'],report_type: 'get_total_and_overdue_fa' ,chart_type:'bar', is_currency:false},
      { label: "Historical PAR", key: 'par_chart',report_type: "get_portfolio_chart",chart_type:'line', is_currency:false },
]
 


  const [PortfolioDetails, setPortfolioDetails] = useState(null);
  const [FilteredPortfolioDetails, setFilteredPortFolioDetails] = useState(null)

  useEffect(()=>{
    if(!get('market_list') || get('market_list')?.length<1){
      
      setcurrentCountryactiveCurrency({active_currency:get('market').currency_code})
      setActiveCurrency({active_currency:get('market').currency_code,active_currency_val:1 })
    }
     
  },[])

  useEffect(() => {
    setMarketList(get('market_list'))
    get_report('management_dashboard_monthly')
    get_report('management_dashboard_live')
    getLastSixMonths()
    get_account_balance()
    setColumnDatas(getBalance())
    get_portfolio_details()
  }, [])

  useEffect(()=>{
    get_report('get_revenue_for_country')
  },[Accprvdr])

 

  const handle_market_change = (market)=>{
    setMarket(market)
    setcurrentCountryactiveCurrency({active_currency:market.currency_code})
  }

  const getBalance = () => {
    return [

      {
        Header: <div style={{textAlign:'',fontSize:'18px', fontWeight:500}}>Country</div>,
        id: "country_code",
        accessor: "country_code",
        width:100,
        Cell: cell => { return  <div style={{ textAlign: '', fontSize:'15px', fontWeight:500 }} >{cell.row.original.country_code}</div>   }

      },
      {
        Header: <div style={{textAlign:'center', fontSize:'18px', fontWeight:500}}>Acc Provider</div>,
        id: "network_prvdr_code",
        accessor: "network_prvdr_code",
        width:150,
        Cell: cell => { return <div style={{ fontSize:'15px', fontWeight:500  }}>{<Homeacc_prvdrLogoselector product_takeup_print={true} logotype={cell.row.original.acc_prvdr_code} />}</div> },
      },
      {
        Header: <div style={{textAlign:'center', fontSize:'18px', fontWeight:500}}>Acc Number</div>,
        id: "acc_number",
        accessor: "acc_number",
        width:150,
        Cell: cell => { return <div style={{ fontSize:'15px', fontWeight:500  }}>{cell.row.original.acc_number}</div> },
      },

      {
        Header: <div style={{textAlign:'center',fontSize:'18px', fontWeight:500}}>Balance In USD</div>,
        id: "usd_balance",
        accessor: "usd_balance",
        width:200,
        show: true,
        // Cell: cell => { return  <p className=' m-0 text-center' >{lbl_amt(cell.row.original.usd_balance, get('market').currency_code)}</p>  </> }
        Cell: cell => { return  <div className="roundoffvalue d-flex justify-content-center"><RoundOffSplitter val={cell.row.original.usd_balance} unit ='USD' copy_val={cell.row.original.usd_balance.toLocaleString('en-GB',{maximumFractionDigits: 1}) + " " + "USD"}/></div>
        // <OverlayTrigger key="usd_balance" placement="top"  overlay={<Tooltip id='usd_balance'>{cell.row.original.usd_balance.toLocaleString()}</Tooltip>}><div  style={{ textAlign: 'center', fontSize:'15px', fontWeight:500  }}>{format_amount(cell.row.original.usd_balance)}</div></OverlayTrigger> 
       }
      },
      {
        Header: <div style={{textAlign:'left',fontSize:'18px', fontWeight:500}}>Balance in Local Currency</div>,
        id: "balance",
        width:250,
        accessor: "balance",
        // Cell: cell => { return  <div style={{ textAlign: 'center', fontSize:'15px', fontWeight:500  }} >{cell.row.original.balance}</div>   }
        Cell: cell => {return  <div className="roundoffvalue d-flex justify-content-center"><RoundOffSplitter val={cell.row.original?.balance} unit ={cell.row.original?.currency_code} copy_val={cell.row.original?.balance?.toLocaleString('en-GB',{maximumFractionDigits: 1}) + " " + cell.row.original?.currency_code}/></div>
        // <OverlayTrigger key='local_balance' placement="top" overlay={<Tooltip id="local_balance">{cell.row.original.balance.toLocaleString()}</Tooltip>}>
        //    <div style={{ textAlign: 'center', fontSize:'15px', fontWeight:500  }} >{format_amount(cell.row.original.balance)}</div>
        // </OverlayTrigger>
        }

      },
      {
        Header: <div className="d-flex justify-content-center" style={{fontSize:'18px', fontWeight:500}} >Last Synced</div>,
        id: "float_acc_stmt_end_time",
        accessor: "float_acc_stmt_end_time",
        Cell: cell => { return  <div  className="d-flex justify-content-center" style={{ fontSize:'15px', fontWeight:500  }}>{get_time_diff(moment.tz(moment(), get_time_zone_str()).format('YYYY-MM-DD HH:mm:ss') ,cell.row.original.float_acc_stmt_end_time)}</div>}

      },





    ]
  }
  useEffect(()=>{
    if(ParDays){
      setSelectedParDays(get_par_days())
    }
  },[ParDays])

  useEffect(() => {
    if (Market.country_code != '*') {
      let request = { country_code: Market.country_code, time_zone: get('market').time_zone, }
      listAccountProvider({ ...request, status: 'enabled', biz_account: true }).then((response) => {
        if (!response) return;
        if (response.status == 'success') {
          let acc_prvds = response.data.list.map((val) => val.acc_prvdr_code)
          setAccprvdrList(acc_prvds)
        }
      })


    }
    get_report('get_revenue_for_country')
    // getChartsData(ActiveChartData)

  }, [Market.country_code,market])


  const get_account_balance = () => {
    var req = {
      "country_code": Market.country_code,
      "time_zone": get('market').time_zone,
    }
    getAccountBalance(req)
      .then((response) => {
        if (!response) { return }
        if (response) {
          setAccountBalance(response.data)
        }
      })

  }

  useEffect(() => {
    if (ResponseDataPast) {
      filterData(ResponseDataPast, Market.country_code, Accprvdr, 'past')
    }
    if (ResponseDataLive) {
      filterData(ResponseDataLive, Market.country_code, Accprvdr, 'live')
    }

    // getChartsData(ActiveChartData)

  }, [Market.country_code, Accprvdr ])


  useEffect(()=>{
    if(PortfolioDetails && PortfolioDetails.records){
      filter_portfolio_data(PortfolioDetails.records, Market.country_code, Accprvdr, LenderCode)
    }
    
  },[Market.country_code, Accprvdr, LenderCode, ActiveCurrency.active_currency])


  useEffect(() => {

    if (PastCurrencyDetails) {
      setActiveCurrency({ active_currency: currentCountryactiveCurrency.active_currency, currency_change: false, active_currency_val:'' })

      if(Market.country_code=='*'){
        setActiveCurrency({ active_currency: 'USD' , currency_change: false, active_currency_val: PastCurrencyDetails ? PastCurrencyDetails[get('market').country_code].forex_rates['USD'] : "" })
      }
    }
    // if(Market.country_code == '*'){
    //   setcurrentCountryactiveCurrency({active_currency:''})
    //   setActiveCurrency({active_currency:'', currency_change:false, active_currency_val:1})
    // }
    get_account_balance()
    
  }, [Market.country_code])

  useEffect(()=>{
    if (PastCurrencyDetails) {
      setActiveCurrency({ active_currency: currentCountryactiveCurrency.active_currency, currency_change: false, active_currency_val:'' })

      if(Market.country_code=='*'){
        setActiveCurrency({ active_currency: 'USD' , currency_change: false, active_currency_val: PastCurrencyDetails ? PastCurrencyDetails[get('market').country_code].forex_rates['USD'] : "" })
      }
    }

  }, [activeMonth, Month])
  

  useEffect(() => {
    if (ActiveCurrency.active_currency != currentCountryactiveCurrency.active_currency  && Market.country_code != "*") {

      getChartsData(ActiveChartData,"currency")

      if (originalRevenueData && originalRevenueData.chart_data.length > 0) {
        let currency_val = ActiveCurrency.active_currency_val ? ActiveCurrency.active_currency_val : PastCurrencyDetails ? PastCurrencyDetails[get('market').country_code].forex_rates[ActiveCurrency.active_currency] : ""
        let chart_data = change_currency_value(originalRevenueData.chart_data, 'currency', currency_val)

        let total_revenue = (originalRevenueData.total_revenue * currency_val)
        setTotalRevenue(total_revenue)
        setRevenueChart(chart_data)
      }
    }
    else {
      getChartsData(ActiveChartData)
      setRevenueChart(originalRevenueData.chart_data)
      setTotalRevenue(originalRevenueData.total_revenue)

    }
    
      
    

  }, [ActiveCurrency.active_currency, Market.country_code, Accprvdr])


  const filterData = (data, country, dataprvdr, type) => {

    const filter_country = data.filter(e => e.country_code == country)

    if (filter_country.length > 0) {
      const filter_prvdr = filter_country.filter(e => e.acc_prvdr_code == dataprvdr)
      if (type == 'past') {
        setFilteredDataPast(filter_prvdr)
      }
      if (type == 'live') {
        setFilteredDataLive(filter_prvdr)
      }

    } else {
      setFilteredDataPast([])
      setFilteredDataLive([])
    }


  }

  const get_report = (type) => {
    var req = {
      "country_code": Market.country_code,
      "report_type": type,
      // "report_month": month.from.replace(/-/g, ""),
      // "vs_month" : month.to.replace(/-/g, ""),
      "time_zone": get('market').time_zone,
      // "lender":Lender
      

    }
    if(type=='get_revenue_for_country'){
      req['founder_screen'] =true;
      req['acc_prvdr_code'] = Accprvdr ? Accprvdr :null
    }
    getReport(req)
      .then((response) => {
        if (response) {
          if (response.status == "success") {
            if (type == 'get_revenue_for_country') {

              // let total_revenue = response.data[Market.country_code] ? response.data[Market.country_code] .slice(-revenue_months_to_show).reduce((sum, val) => sum + val.y, 0): 0
              let total_revenue = response.data[Market.country_code] ? response.data[Market.country_code].slice(-revenue_months_to_show)[response.data[Market.country_code].slice(-revenue_months_to_show).length - 1].y: 0;
              let revenue_last_month = response.data[Market.country_code] ? moment(response.data[Market.country_code].slice(-revenue_months_to_show)[response.data[Market.country_code].slice(-revenue_months_to_show).length - 1].x).format('MMM YYYY'): RevenueMonth;
              let revenue_data_to_show = response.data[Market.country_code] ? response.data[Market.country_code] .slice(-revenue_months_to_show) : []
              setRevenueMonth(revenue_last_month)
              setRevenueChart(revenue_data_to_show)
              setTotalRevenue(total_revenue)
              setoriginalRevenueData({ chart_data: revenue_data_to_show, total_revenue: total_revenue })
              return
            }
            if (type == 'management_dashboard_monthly') {
              setResponseDataPast(response.data.records)

              filterData(response.data.records, Market.country_code, Accprvdr, 'past')

              const currency_details = response.data.currency_data;
              // setPastCurrencyDetails(currency_details)
              // setcurrentCountryactiveCurrency({
              //   active_currency: currency_details[get('market').country_code].currency_code
              // })
            }

            if (type == 'management_dashboard_live') {
              setResponseDataLive(response.data.records)
              filterData(response.data.records, Market.country_code, Accprvdr, 'live')
              const currency_details = response.data.currency_data;
              // setLiveCurrencyDetails(currency_details)
              // setcurrentCountryactiveCurrency({
              //   active_currency: currency_details[get('market').country_code].currency_code
              // })
            }



          }

        }
      })
  }


  const change_currency_value = (array, type, curr_val) => {

    if (type == 'currency') {
      if (array) {
        var currency_data = array.map((val) => {
          return { ...val, y: val.y * curr_val }
        })

        return currency_data
      }

    }


  }

 

  const handle_toggle_month_change = (month_change = false)=>{
    if(month_change  && ToggleState.last_week){
      setToggleState({...ToggleState,last_week:false})
      setactive_toogle("")
      return
    }
    if(month_change &&  ToggleState.last_month && !LastMonthComparable){
      setToggleState({...ToggleState,last_month:false})
      setactive_toogle("")
      return
    }
  }

  const handle_toogle_state = (event) => {
    let id = event.hasOwnProperty('target') ? event.target.id :  ''
    let state = { ...ToggleState }
    setFilteredPortFolioDetails(null)
    if (event.target.checked) {
      if ((id == "last_week")) {
        
        setToggleState({ ...ToggleState, [id]: event.target.checked, ["last_month"]: !event.target.checked })

      } else {
        setToggleState({ ...ToggleState, [id]: event.target.checked, ["last_week"]: !event.target.checked })

      }

      setactive_toogle(event.target.id)

    }
    else {
      setToggleState({ ...ToggleState, [id]: event.target.checked })
      setactive_toogle("")

    }



  }


  const setCurrencyHandler = (curren) => {
    if (Market.country_code != "*") {
      if (curren === "USD" || curren === "EUR") {
        setActiveCurrency({
          ["currency_change"]: true,
          ["active_currency"]: curren,
          ["active_currency_val"]:PastCurrencyDetails ? PastCurrencyDetails[Market.country_code].forex_rates[curren] : ""
        })
      }
      else if (curren === Market.currency_code) {
        setActiveCurrency({
          ["currency_change"]: false,
          ["active_currency"]: curren,
          ["active_currency_val"]: ""
        })
      }

    } else {

      setActiveCurrency({
        ["currency_change"]: true,
        ["active_currency"]: curren,
        ["active_currency_val"]: 1
      })


    }

  }
  const getLastSixMonths = () => {
    // const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const currentDate = new Date();
    const monthNames = [];
    const monthYears = [];

    const today = moment();

    for (let i = 5; i >= 0; i--) {
        const currentMonth = today.clone().subtract(i, 'months');
        
        const formattedMonthYear = currentMonth.format('YYYYMM');

        monthYears.push(formattedMonthYear)
        monthNames.push(currentMonth.format('MMM'));
    }
    setlastSixMonths({monthNames:monthNames,monthYears:monthYears})

  }

  function formated_year_month(monthInState) {
    
    const parsedMonth = moment(monthInState, 'MMM YYYY');

   
    const formated_month = parsedMonth.format('YYYYMM');

    return formated_month;
}

  const filter_chart_data =  (data, filter) => {
    const parDayArrays = {};
    const parDays=[]
    const accessor_key = Market.country_code == '*' ? 'Global' : Market.country_code
    data[accessor_key] &&  data[accessor_key].forEach(item => {
      const { par_day } = item;

      if (!parDays.includes(par_day)) {
        parDays.push(par_day);
      }
    }); 

    let par_days = parDays.sort((a,b)=>a-b)
    setParDays(par_days)
    par_days.map((day)=>{
      if (!parDayArrays[`PAR ${day}`]) {
        parDayArrays[`PAR ${day}`] = [];
      }
      let filtered_items = data[accessor_key].filter((val)=>val.par_day == day)
      parDayArrays[`PAR ${day}`]= filtered_items

    })
    let chart_data = []

    Object.keys(parDayArrays).map((obj_key)=>{
      let name =obj_key
      let data =  parDayArrays[obj_key]
      chart_data.push({name:name, data:data})
    })

    
    setcharts_value(prevState => ({
      ...prevState,
      ["get_portfolio_chart"]: chart_data
    }));
    setParChartDataOriginal(chart_data)
    setSelectedParDays(get_par_days(par_days))

  };

  const get_par_days = (days = ParDays)=>{
    return days && days.map((val,idx)=>({
      label:val,
      id:idx,
      value:`PAR ${val}`
    }))
  }

  


  const getChartsData = (item,currency_change=null) => {
     
    setChartLoader(true)
    
      var type = item.report_type ? item.report_type :item
      var key = item.key?item.key : ActiveChartKey
      var is_currency = item.is_currency ? item.is_currency : false
      var req = {
        type: type,
        "country_code":  Market.country_code,
        "report_type": type,
        "time_zone": get('market').time_zone,
        "acc_prvdr_code" : Accprvdr ? Accprvdr:null,
      
      }

      if(type=="get_portfolio_chart" ){
        req['start_date']= moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD')
        req['req_perc_data']  = true
        req['end_date']  =moment().format("YYYY-MM-DD")
      }
      else if(type=='cohort_report' || key =='recovery_cohort'){
        req['chart_wise_cohort'] =true
        req['date_range'] = "monthly"
        req['start_month'] =  moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD')
        var days =Config("cohort_chart_date_diff").monthly 
        var subtract_month = moment().subtract(days, 'days')
        req["end_month"] =  subtract_month.format('YYYY-MM-DD') 
        req['acc_prvdr_code_needed'] = true

      }
      else{
        req['founder_screen']  = true

      }
      getReport(req)
        .then((response) => {
          if (!response) { return }
          if (response.status == "success") {
            let not_curr = ['average_loan_duration', 'get_total_and_overdue_fa', 'ontime_payment', 'par_chart', 'loan_disbursed','recovery_cohort']
            
            if(currency_change!=null && !not_curr.includes(key) && currentCountryactiveCurrency.active_currency != ActiveCurrency.active_currency){
              let currency_val = ActiveCurrency.active_currency_val ? ActiveCurrency.active_currency_val : PastCurrencyDetails ? PastCurrencyDetails[get('market').country_code].forex_rates[ActiveCurrency.active_currency] : ""
              let chart_data = change_currency_value(response.data[key], 'currency', currency_val)
              setcharts_value(prevState => ({
                ...prevState,
                [type]: chart_data && chart_data.slice(-revenue_months_to_show)
              }));

              
            }
            else if(type=="get_portfolio_chart"){
              filter_chart_data(response.data)
              
              
            }
            else if(type == 'cohort_report' || key == 'recovery_cohort'){
              var cohort_data = response.data['percentage_wise']
              getReport({report_type : 'get_ontime_payments', country_code : req['country_code'], time_zone : req['time_zone'], acc_prvdr_code : req['acc_prvdr_code'], 'founder_screen' : true, 'start_month':moment().subtract(6, 'months').format('YYYYMM') }).then((res)=>{
                if(!res){
                  return
                }
                if(res.status == 'success'){
                  var ontime_data = res.data['ontime_payment'] ? res.data['ontime_payment'].slice(-revenue_months_to_show) : []
                  // var converted_ontime_data = ontime_data && ontime_data.map((val) => ({...val, y: val.y / 100}));
                  var converted_ontime_data = ontime_data
                }

                Object.entries(cohort_data).forEach(([key, subArray]) => {
                  if (subArray.length > converted_ontime_data.length) {
                      cohort_data[key] = subArray.slice(-revenue_months_to_show);
                  }
              });
      
                let keyValues = Object.entries(cohort_data); 
                keyValues.splice(0,0, ["ontime_payment",converted_ontime_data]);
                let newObj = Object.fromEntries(keyValues)
                generate_cohort_chart_data(newObj)

              })
              // generate_cohort_chart_data(response.data['percentage_wise'])
              
            }
            
            else if(type =='get_total_and_overdue_fa'){
                businessVolumeArr .map((val,idx)=>{
                  if(val.report_type == 'get_total_and_overdue_fa'){
                    val.key_array.map((item,idx)=>{
                      setcharts_value(prevState=>({
                        ...prevState,
                        [item]:response.data[item] && response.data[item].slice(-revenue_months_to_show)
                      }))
                        
                    })
                  }
                })


            }
            else{
              setcharts_value(prevState => ({
                ...prevState,
                [type]: response.data[key] && response.data[key].slice(-revenue_months_to_show)
              }));

            }
         
            if(item.report_type){
              setActiveChartData(item.report_type)
            }
          }
          setChartLoader(false)
        })

        
    
  }

  useEffect(()=>{
    get_portfolio_details()
  }, [active_toogle])

  const calculate_perc_diff=(old_value, new_value)=> {
    
    if(old_value == new_value){
      return 0
    }
    if (old_value === 0) {
        return new_value === 0 ? 0.00 : 100;
    }
    if(isNaN(new_value) || isNaN(old_value)){
      return 0.00
    }

    const percentage_diff = ((new_value - old_value) / Math.abs(old_value)) * 100;
    return Number(percentage_diff.toFixed(2))
}

const generate_cohort_chart_data = (data)=>{
  let chart_data = []
  let categories = []
  var max_month = revenue_months_to_show;
  Object.keys(data).map((obj_key)=>{
    let name = obj_key
    let data_array =[]
    if(Array.isArray(data[obj_key])){
        data[obj_key].map((val,idx)=>{
          if(!categories.includes(val.x)){
            categories.push(val.x)
          }
          data_array.push(val.y)
          // if(data[obj_key].length < max_month){
          //   max_month = data[obj_key].length
          // }
          
        })
          

      chart_data.push({data:data_array, name: name})
}})

  if(chart_data.length >0){
    setCohortChartData(
      {
        chart_data : chart_data,
        categories : categories
      }
    )
  }

  

}

  
  useEffect(()=>{
    get_portfolio_details()
  },[Month, activeMonth])


  const get_portfolio_details = ()=>{
    setLoader(true)
    var req = {
      time_zone:get('market').time_zone,
      founder_screen:true,
      report_type:'founder_screen_portfolio_report',
      report_month: activeMonth && activeMonth != '' ? activeMonth : Month && Month != ""? formated_year_month(Month): moment().format('YYYYMM') ,
      compared_to_last_month:active_toogle && active_toogle == 'last_month' ? true : false,
      compared_to_last_week:active_toogle && active_toogle == 'last_week' ? true : false,
      country_code: Market.country_code,
      acc_prvdr_code:Accprvdr,
      lender:null
    }
    getReport(req).then((response)=>{
      
      if(!response){ setLoader(false)
        return};
      if(response.status=='success'){
        setPortfolioDetails(response.data)
        setLastWeekComparable(response.data.last_week_comparable)
        setLastMonthComparable(response.data.last_month_comparable)
        filter_portfolio_data(response.data.records, Market.country_code, Accprvdr, LenderCode)
        setLoader(false)
        setPastCurrencyDetails(response.data.currency_data ? response.data.currency_data : [])
        
      }
      else{
        setLoader(false)
      }

    })
  }
  const filter_portfolio_data = (data, country_code, acc_prvdr_code, sub_lender_code) =>{
    if(data && data.length == 0){
      setPortfolioDetails(null)
      setFilteredPortFolioDetails(null)
      return
    };
      let filtered_items = JSON.parse(JSON.stringify(data.filter((item,idx)=> item.country_code == country_code && item.acc_prvdr_code == acc_prvdr_code && item.sub_lender_code == sub_lender_code)))


      if(Market.country_code != '*' && ActiveCurrency.active_currency != currentCountryactiveCurrency.active_currency){
        let currency_items = ['os_val_eom', 'os_fee_eom', 'od_amount', 'par15', 'par30', 'par60', 'par90', 'npl', 'max_os_val']
       
         filtered_items.forEach((item)=>{
          
          Object.keys(item).map((obj_key)=>{
            if(currency_items.includes(obj_key)){
              if(active_toogle){
                if(obj_key == 'max_os_val'){
                    let [value,date] = item[obj_key].value ? item[obj_key].value.split(',') : [null, null];
                    let [vs_value, vs_date] = item[obj_key]?.vs_value ? typeof item[obj_key].vs_value === 'string' ? item[obj_key].vs_value.includes(',') ? item[obj_key].vs_value.split(',') : [item[obj_key].vs_value, null] : [item[obj_key].vs_value, null] : [null, null];
                    let currency_based_val = parseInt(value) * ActiveCurrency.active_currency_val
                    let currency_based_vs_val = parseInt(vs_value) * ActiveCurrency.active_currency_val
                    item[obj_key].value = currency_based_val + ',' + date
                    item[obj_key].vs_value = currency_based_vs_val + ',' + vs_date

                }
                else{
                  item[obj_key].value = item[obj_key].value * ActiveCurrency.active_currency_val
                  item[obj_key].vs_value = item[obj_key].vs_value * ActiveCurrency.active_currency_val
                }
              }
              else{
                if(obj_key == 'max_os_val'){
                  let [value,date] = item[obj_key] ? item[obj_key].split(',') : [null, null];
                  let currency_based_val = parseInt(value) * ActiveCurrency.active_currency_val
                  item[obj_key] = currency_based_val + ',' + date
                  }
                  else{
                    item[obj_key] = item[obj_key] * ActiveCurrency.active_currency_val
                  }
                }
                
              }
            })
         })
        



      }
      

      setFilteredPortFolioDetails(filtered_items)


  }
  const customStyles = {
    option:(provided,state) =>({
      ...provided,
      color:'black',
      backgroundColor : state.isSelected ? 'white':'inherit'
    })
  };

  const handle_par_day_change =(option)=>{
    setSelectedParDays(option)
    let selected_portfolio_datas = ParChartDataOriginal && ParChartDataOriginal.filter((val) => option.find(({ value }) => val.name == value));

    setcharts_value(prevState=>({
      ...prevState,
      ['get_portfolio_chart'] : selected_portfolio_datas
    }))

}

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <span style={{color:"#050404",fontSize:"13px"}}>{props.label}</span>
      </components.Option>
    </div>
  );
};
  

const[ChartType,setChartType]=useState("bar")

  return (
    <div style={{ marginTop: '70px' }}>
       {MarketList?.length>1 &&
      <div className=" d-flex align-items-center" >
        <div style={{ fontSize: '16px', fontWeight: 400, color: 'white' }} className="mr-5">
          Market
        </div>
        <div className={`dropdown country_dropDownbg dark_theme_bg  p-0 `} style={{ backgroundColor: '#303E5266' }}>
          <div
            className="dropdown-toggle d-flex align-items-center dropdown_trig justify-content-between"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ border: '1px solid white', borderRadius: '10px', height: '40px', minWidth: '190px',backgroundColor: '#232c41' }}
          >
            {Market.country_code ? (
              <>
                {Market.country_code == "*" ? (
                  <>
                    <IoIosGlobe size={20} style={{ margin: "4px 0px" }} />
                    <span style={{ paddingLeft: 15 }}>{Market.country}</span>

                    <div className="ml-auto">
                      <span>
                        <i className="fa-solid fa-chevron-down"></i>
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    {/* <Flag
                      id="market"
                      name={Market.country_code}
                      format="png"
                      pngSize={24}
                      shiny={false}
                      alt={Market.country_code}
                      basePath="/img/flags"
                    /> */}
                    <CustomFlag country_code={Market.country_code} height={30} width={30}/>

                    <span style={{ paddingLeft: 15 }}>
                      {Market.country}
                    </span>
                    <div className="ml-auto">
                      <span>
                        <i className="fa-solid fa-chevron-down"></i>
                      </span>
                    </div>
                  </>
                )}
              </>
            ) : (
              ""
            )}
          </div>
          <ul
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton1"
            style={{
              minWidth: '190px',
              backgroundColor: "#1A2035",
              color: "white",
              zIndex:'200'
            }}
          >
            <li
              key={"global"}
              className="country_selector d-flex align-items-center mb-2 freshchat_dropdown"
              onClick={() => {
                setMarket({ country_code: '*', country: 'Global', currency_code:"USD" }); setAccprvdr(null); 
                document.getElementById(`country_choose`).click();
              }}
            >
              <input
                className="form-check m-0"
                type="checkbox"
                id="country_choose"
                style={{ borderRadius: "80%" }}
                name="country"
                checked={Market.country_code == "*" ? true : false}
              />
              <span
                style={{ paddingLeft: 5, paddingRight: 5 }}
                for="country_choose"
              >
                Global
              </span>
              <div className="ml-auto">
                <IoIosGlobe size={30} for="country_choose" />
              </div>
            </li>
            {MarketList &&
              MarketList.map((market, idx) => (
                <li
                  key={idx}
                  className="country_selector d-flex mb-2 freshchat_dropdown align-items-center"
                  onClick={() => {
                    handle_market_change(market)
                    document.getElementById(`country_choose${idx}`).click();
                  }}
                  htmlFor={`country_choose` + idx}
                >
                  <input
                    type="checkbox"
                    id={`country_choose` + idx}
                    style={{ borderRadius: "80%" }}
                    name="country"
                    checked={`${Market.country_code == market.country_code ? true : ""
                      }`}
                  />
                  <span style={{ paddingLeft: 5, paddingRight: 10 }}>{market.country}</span>
                  <div className="ml-auto">
                    
                    <CustomFlag country_code={market.country_code} height={25} width={25}/>
                  </div>
                </li>
              ))}
          </ul>
        </div>


      </div>}

      { (Market && Market.country_code != '*' )
        &&
        <div className={`row align-items-center mt-5 d-flex`} >
           
          
            <div className='col-md-12 no-padding'>
            <div className='d-flex align-items-center'>
             {AccprvdrList?.length>1 && 
              <div className="d-flex align-items-center">
              <label className='mx-2 mb-0' style={{ paddingRight: 15, fontSize: '16px', fontWeight: 400, color: 'white' }}>Provider</label>
              <button className={`filterBtns allBtn_size ${Accprvdr === null ? "" : "opac_50"}`}
                style={{
                  height: 30,
                  width: 60,
                  cursor: "pointer",
                  marginRight: 15
                }} key={0} onClick={() => setAccprvdr(null)}>ALL
              </button>
              {
                AccprvdrList.map((each, idx) =>
                  <div style={{
                    height: 30,
                    width: 60,
                    cursor: "pointer",
                    marginRight: 15
                  }} className={Accprvdr === null || Accprvdr === each ? "" : "opac_50"}
                    key={idx}
                    onClick={() => setAccprvdr(each)}>
                    <img className="img-fluid" src={`/img/${each}_logo_color.png`}
                      alt={each} />
                  </div>
                )
              }
              </div>
              }


             

              {(Market.country_code != "*") &&
              //  <div className="d-flex align-items-center d-flex justify-content-end mr">
                <div className="monthly_report_currency_selector ml-auto">
                  {ActiveCurrency?.currency_change && 
                  <div className='d-flex align-items-center' style={{ marginRight: '15px' }}>

                    <p className='no-margin text-white'
                      style={{ fontWeight: 600 }}>{ActiveCurrency.active_currency_val === "" ? "" : `1 ${ActiveCurrency.active_currency} =`}&nbsp;</p>
                    <p className='no-margin text-white'
                      style={{ fontWeight: 600 }}>{ActiveCurrency.active_currency_val === "" ? "" : Number(1 / ActiveCurrency.active_currency_val).toFixed(0)}&nbsp;{ActiveCurrency.active_currency_val === "" ? "" : PastCurrencyDetails?.[Market.country_code] && PastCurrencyDetails[Market.country_code]?.currency_code}</p>
                  </div>}
                  <ul className='monthly_report_currencySelector no-padding no-margin justify-content-end align-items-center'>
                    {conversion_currencies.map((val, idx) => {
                      return (

                        <li className={ActiveCurrency.active_currency === val ? ' active-currency' : " "}
                          style={{ cursor: "pointer" }}
                          onClick={() => setCurrencyHandler(val)}>
                          <p className='no-margin'>{val}</p>
                        </li>


                      )
                    })}

                    <li className={ActiveCurrency.active_currency === currentCountryactiveCurrency.active_currency ? ' border_right active-currency' : " border_right"}
                      style={{ cursor: "pointer" }}
                      onClick={() => setCurrencyHandler(currentCountryactiveCurrency.active_currency)}>
                      <p className='no-margin'>{currentCountryactiveCurrency.active_currency}</p>
                    </li>
                  </ul>
                </div>
              }
            </div>



          </div>

        </div>
      }
      {/* mid section */}
      <div className="row d-flex justify-content-between align-iems-center" style={{ marginTop: '4%' }}>
        <div className=" col-md-4 col-sm-12 col-lg-4">
          <div className="p-3 customer_metrics mb-4">
            <div className="mb-4 mt-1" style={{ fontSize: '20px', color: '#D9D9D9', fontWeight: 700 }}><span style={{ paddingRight: '2%' }}><IoIosPie color="blue" size={25} /></span>Customer Metrics</div>
            <div className="row m-0">
              {FilteredDataPast && FilteredDataPast.length > 0 && Object.keys(customer_metrics).map((key) => (
                <div key={key} className="col-md-12 col-sm-4 mb-3 founders_progress">
                  <div className=" d-flex justify-content-between m-0 p-0 align-items-center" style={{ width: '100%' }}>
                    <div className=" d-flex  align-items-center progress_bar_bg" >

                    </div>
                    <div className="d-flex align-items-center col-md-4 col-lg-6 col-sm-4" style={{ height: '50px', textAlign: 'left'}}>
                      {customer_metrics[key]}
                    </div>
                    <div className="d-flex align-items-center  col-md-auto" style={{ height: '50px',textAlign: 'right' }}>
                      {FilteredDataPast[0][key].value}
                    </div>
                  </div>
                </div>
              ))}
              {(switch_data) && FilterdDataLive && FilterdDataLive.length > 0 && (
                Object.keys(switch_customer_metrics).map((key) => (
                  <div key={key} className="col-md-12 col-sm-4 mb-3 founders_progress">
                    <div className="d-flex justify-content-between m-0 p-0 align-items-center" style={{ width: '100%' }}>
                      <div className=" d-flex  align-items-center progress_bar_bg" >
                      </div>
                      <div className="d-flex align-items-center col-md-4 col-lg-6 col-sm-4" style={{ height: '50px' , textAlign: 'left'}}>
                        {switch_customer_metrics[key]}
                      </div>
                      <div className="d-flex align-items-center col-md-auto" style={{ height: '50px' ,textAlign: 'right'}}>
                        {FilterdDataLive[0][key]}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          <div className="p-2 customer_metrics" style={{height:'55%'}}>
            <div className=" m-0 d-flex justify-content-end">
              <div className="col-md-6 mt-2" style={{ fontSize: '20px', color: '#D9D9D9', fontWeight: 700 }}>
                <span style={{ paddingRight: '2%' }}><RiMoneyDollarCircleFill color="#017EFA" size={25} /></span>
                Revenue
                <div>
                  <p style={{fontSize:'48%',marginLeft:'14%'}}>{(switch_data) ? "(FA + Switch)" : "FA"}</p>
                </div>
              </div>
              
              <div className="ml-auto">

                <div className="ml-auto mt-2">
                  <div style={{ fontSize: '14px', color: '#D9D9D9', fontWeight: 400 }}>

                    <div className='d-flex text-white mt-1' style={{fontSize:'20px'}} >
                      <RoundOffSplitter text_color = {'#D9D9D9'} val={TotalRevenue} unit={ActiveCurrency.active_currency ? ActiveCurrency.active_currency : 'USD'} align_center={false} position={"top"} />

                    </div>
                    {/* {ActiveCurrency.active_currency ? ActiveCurrency.active_currency : 'USD'}  {TotalRevenue ? TotalRevenue.toFixed(2) : 0} */}
                  </div>
                </div>
                <p className="mx-0 my-0 mr-1 text-white text-end" style={{fontSize:'14px'}}>{RevenueMonth}</p>
              </div>




            </div>
            <div className="mt-5 col-md-auto">
              <ReportChart type='bar' colors={['#14855c']} biz_ops_chart_height={350} show_toolbar={false} data={[{ data: RevenueChart, name: 'Revenue' }]} curr={ActiveCurrency.active_currency ? ActiveCurrency.active_currency : 'USD'} y_axis_tittle='Amount' x_axis_tittle='Months' legend={false} founders_chart={true} />
            </div>

          </div>
        </div>

        <div className="col-md-8 col-sm-12 col-lg-8 ">
          <div className="h-100 month_wise_datas">
            <div className={` m-0 d-flex align-items-center py-2 px-2 justify-content-between ${Loader ? "disable_div" : ''}`}
            style={{
              pointerEvents : Loader || (active_toogle && active_toogle == 'last_week') ? 'None' : '', opacity:Loader || (active_toogle && active_toogle == 'last_week') ? '0.7' : 1
            }}>
              <div className=" d-flex   m-0 col  pr-0 last_six_month_box">
                <div className={` ${Month && Month != "" ? 'col-3' : 'col-1' } px-0 py-3 founders_dashboard_date_picker relative`} >
                  <DatePicker
                    closeOnSelect={true}
                    dateFormat="MMMM YYYY"
                    timeFormat={false}
                    value={Month ? Month : ""}
                    onChange={(date) => { setMonth(moment(date).format('MMM YYYY')); setactiveMonth(""); handle_toggle_month_change(true) }}
                    maxDate={new Date()}
                    selected={Month}
                    isValidDate={(current)=>{
                      const today = moment().format("MMM YYYY")

                      return current.isSameOrBefore(today, 'month');
                    }}

                    inputProps={{ id: 'founders_dashboard_date_picker' }}
                  />
                  {!Month &&
                    <label className="m-0 bi_calender_icon pointer" for={'founders_dashboard_date_picker'}><BiCalendar size="20" color="white" /></label>
                  }
                </div>
                <div className="d-flex justify-content-around ml-auto col px-0 row m-0 align-items-center">
                  {lastSixMonths.monthNames && lastSixMonths.monthNames.length > 0 && lastSixMonths.monthNames.map((item, idx) => {
                    return (
                      <div className={`col-2 px-0  py-4 pointer ${(activeMonth == lastSixMonths.monthYears[idx]) ? "active_month" : ""} ${((activeMonth == lastSixMonths.monthYears[idx]) && (idx == lastSixMonths.monthNames.length-1)) ? "end_border" : ""}`} key={idx} onClick={() => { setactiveMonth(lastSixMonths.monthYears[idx]); setMonth(""); handle_toggle_month_change(true)}}>
                        <p className="m-0 text-white text-center">{item}</p>
                      </div>
                    )
                  })}
                </div>


              </div>

              <div className=" col-7  row mx-0 d-flex my-3 justify-content-between ">
                <div className="d-flex row m-0 col-6 px-1">
                  <div className="col-9 p-0">
                    <p className="m-0 text-white font_m" style={{ fontSize: '0.9vw' }}> <b>Compared to last Week</b></p>
                    <p className="m-0 text-white font_s mt-2" style={{ lineHeight: '12px' }}> This report compares last week’s data with current report</p>
                  </div>
                  <div className="col-3 p-0 toggle_pointer">
                    {/* <OverlayTrigger key='last_week' placement="top" overlay={(activeMonth && activeMonth !== '' && activeMonth !== moment().format('YYYYMM')) ||
                          (Month && Month !== '' && formated_year_month(Month) !== moment().format('YYYYMM')) ? <Tooltip id="last_week_toggle">Can' t Compare Last week for past month</Tooltip> : !LastWeekComparable ?  <Tooltip id="last_week_toggle">There is  No Data to Compare</Tooltip> :'' }>
                      <Form>
                        <Form.Check type="switch" id="last_week" className='' style={{ cursor: 'pointer' }} onChange={(e) => handle_toogle_state(e)}
                          checked={ToggleState.last_week ? true : false } disabled={ (activeMonth && activeMonth !== '' && activeMonth !== moment().format('YYYYMM')) ||
                          (Month && Month !== '' && formated_year_month(Month) !== moment().format('YYYYMM')) || !LastWeekComparable} />
                      </Form>
                    </OverlayTrigger> */}
                    {
                            !LastWeekComparable || (activeMonth && activeMonth !== '' && activeMonth !== moment().format('YYYYMM')) ||
                            (Month && Month !== '' && formated_year_month(Month) !== moment().format('YYYYMM')) ? (
                              <OverlayTrigger
                                key='last_week'
                                placement="top"
                                overlay={
                                  (activeMonth && activeMonth !== '' && activeMonth !== moment().format('YYYYMM')) ||
                                  (Month && Month !== '' && formated_year_month(Month) !== moment().format('YYYYMM')) ? (
                                    <Tooltip id="last_week_toggle">Can't Compare Last week for past month</Tooltip>
                                  ) : (
                                    <Tooltip id="last_week_toggle">There is No Data to Compare</Tooltip>
                                  )
                                }
                              >
                                <Form>
                                  <Form.Check
                                    type="switch"
                                    id="last_week"
                                    className=''
                                    style={{pointerEvents : Loader ? 'None' : ''}}
                                    onChange={(e) => handle_toogle_state(e)}
                                    checked={ToggleState.last_week ? true : false}
                                    disabled={
                                      (activeMonth && activeMonth !== '' && activeMonth !== moment().format('YYYYMM')) ||
                                      (Month && Month !== '' && formated_year_month(Month) !== moment().format('YYYYMM')) || !LastWeekComparable || Loader
                                    }
                                  />
                                </Form>
                              </OverlayTrigger>
                            ) : (
                              <Form>
                                <Form.Check
                                  type="switch"
                                  id="last_week"
                                  className=''
                                  style={{pointerEvents : Loader ? 'None' : ''}}
                                  onChange={(e) => handle_toogle_state(e)}
                                  checked={ToggleState.last_week ? true : false}
                                  disabled={
                                    (activeMonth && activeMonth !== '' && activeMonth !== moment().format('YYYYMM')) ||
                                    (Month && Month !== '' && formated_year_month(Month) !== moment().format('YYYYMM')) || !LastWeekComparable || Loader
                                  }
                                />
                              </Form>
                            )
                          }

                   
                  </div>

                </div>
                <div className="d-flex row m-0 col-6 p-0">
                  <div className="col-9 p-0">
                    <p className="m-0 text-white font_m" style={{ fontSize: '0.9vw' }}> <b>Compared to last month</b></p>
                  </div>
                  <div className="col-3 p-0 toggle_pointer">
                    {/* <OverlayTrigger key='last_month' placement="top" overlay={!LastMonthComparable ? <Tooltip id="last_month_toggle">There is  No Data to Compare</Tooltip>:<Tooltip></Tooltip>}>
                      <Form>
                        <Form.Check type="switch" id="last_month" className='' style={{ cursor: 'pointer' }} onChange={(e) => handle_toogle_state(e)}
                          checked={ToggleState.last_month ? true : false} disabled={!LastMonthComparable} />
                      </Form>
                    </OverlayTrigger> */}
                      {!LastMonthComparable ? (
                          <OverlayTrigger key='last_month' placement="top" overlay={<Tooltip id="last_month_toggle">There is No Data to Compare</Tooltip>}>
                              <Form>
                                  <Form.Check type="switch" id="last_month" className='' style={{pointerEvents:Loader ? 'None' : ''}} onChange={(e) => handle_toogle_state(e)} checked={ToggleState.last_month ? true : false} disabled={!LastMonthComparable || Loader} />
                              </Form>
                          </OverlayTrigger>
                      ) : (
                          <Form>
                              <Form.Check type="switch" id="last_month" className='' style={{pointerEvents:Loader ? 'None' : ''}} onChange={(e) => handle_toogle_state(e)} checked={ToggleState.last_month ? true : false} disabled={!LastMonthComparable || Loader} />
                          </Form>
                      )}
                  </div>

                </div>
              </div>

            </div>
            <div className="last_six_month_box px-3 py-2 mx-2">
            {Loader && Loader == true ? <div className="product_takeup_loader" style={{marginTop:'10vh', height:'550px'}}></div>:
              <>
              <div className="dashboard_title my-2 mt-3" ><span style={{ paddingRight: '2%' }}><IoIosPie color="blue" size={25} /></span>Portfolio Performance</div>
              
        <p className="mx-3 mb-0 pt-2  sizem text_gray"> <font size="3"> Outstanding Portfolio</font></p>

    {FilteredPortfolioDetails && FilteredPortfolioDetails.length >0  ? 
    <div className="row m-0 ">
    {os_portfolio && os_portfolio.map((item, idx) => {
      let value = active_toogle && FilteredPortfolioDetails && FilteredPortfolioDetails.length>0 ? FilteredPortfolioDetails[0][item.key].value : FilteredPortfolioDetails && FilteredPortfolioDetails.length>0 ? FilteredPortfolioDetails[0][item.key]: 0

      let vs_value = active_toogle && FilteredPortfolioDetails && FilteredPortfolioDetails.length >0 ? FilteredPortfolioDetails[0][item.key].vs_value : null
      let unit = item.unit == 'percentage' ? '%' : item.unit == 'amount' && !ActiveCurrency.active_currency ? 'USD':item.unit == 'count' ? '':ActiveCurrency.active_currency
      return (
        <div className="col-lg-4 col-md-4 col-sm-12 my-3 pr-0 founders_screen_p" style={{ borderBottom: '0.7px solid #2a2a2a', borderRight: '0.7px solid #2a2a2a' }}>
          <div className="d-flex align-items-center justify-content-between  mx-0 my-2" >

            <div className={` d-flex align-items-center ${!active_toogle ? "my-4" : "my-2"}`}>
              {!active_toogle &&
                <div className="performance_background p-2 mx-2 mr-3">
                  <p className="m-0">{item.icon}</p>
                </div>}

              <div>
                <p className="dashboard_text_color my-2">{item.label}</p>
                <div className='d-flex founders_screen_val mt-1'>
                  {FilteredPortfolioDetails && FilteredPortfolioDetails.length>0 &&
                  <RoundOffSplitter val={
                  (item.key == 'ontime_repayment_rate' ?  (value * 100): value
                  )}  
                  
                  unit={unit} align_center={false} position={"top"} format = {item.unit == "count" ? false : true}/>}

                </div>
              </div>
            </div>

            {active_toogle &&
              <div className="my-2 d-flex   justify-content-between align-items-center">

                <div>
                  <p className="dashboard_text_color my-2 disable_div"> <b> {(active_toogle == "last_week") ? "Last week" : "Last Month"}</b></p>
                  <div className='d-flex founders_screen_val mt-1'>
                    <RoundOffSplitter val={item.key=='ontime_repayment_rate'? vs_value * 100 :vs_value} unit={unit} align_center={false} position={"top"}  format = {item.unit == "count" ? false : true}/>


                  </div>
                </div>
                <div className="px-3">
                  <p className="m-0 text-white">{calculate_perc_diff(vs_value, value) == 0 ? '' :calculate_perc_diff(vs_value, value) > 0 ?<IoIosTrendingUp size="25" color={item.increase_color} /> : <IoIosTrendingDown size="25" color={item.decrease_color} />}</p>
                  <p className="m-0" style={{color:calculate_perc_diff(vs_value, value) == 0 ? 'white' :calculate_perc_diff(vs_value, value) > 0? item.increase_color: item.decrease_color}}>{calculate_perc_diff(vs_value, value)} %</p>
                </div>
              </div>
            }

          </div>
        </div>
      )
    })}

  </div>:
   <div className="text-center text-white" style={{marginTop:'60px', height:'10vh'}}>No Data Found</div>}

    <p className="mx-3 mb-0 pt-2  sizem text_gray"> <font size="3"> Portfolio at Risk</font></p>

    {
      FilteredPortfolioDetails && FilteredPortfolioDetails.length>0 ? 
      <div className="row m-0 ">
      {portfolio_at_risk && portfolio_at_risk.map((item, idx) => {
        let par_details = {
          par15_to_par30 : active_toogle ? FilteredPortfolioDetails[0].par15.value - FilteredPortfolioDetails[0].par30.value : FilteredPortfolioDetails[0].par15 - FilteredPortfolioDetails[0].par30,
          par30_to_par60 :active_toogle ? FilteredPortfolioDetails[0].par30.value - FilteredPortfolioDetails[0].par60.value : FilteredPortfolioDetails[0].par30 - FilteredPortfolioDetails[0].par60,
          par60_to_par90 : active_toogle ? FilteredPortfolioDetails[0].par60.value - FilteredPortfolioDetails[0].par90.value : FilteredPortfolioDetails[0].par60 - FilteredPortfolioDetails[0].par90,
          par90 : active_toogle ? FilteredPortfolioDetails[0].par90.value : FilteredPortfolioDetails[0].par90
        }

        let par_details_vs_value  =  active_toogle ? {
          par15_to_par30 : active_toogle ? FilteredPortfolioDetails[0].par15.vs_value - FilteredPortfolioDetails[0].par30.vs_value : FilteredPortfolioDetails[0].par15 - FilteredPortfolioDetails[0].par30,
          par30_to_par60 :active_toogle ? FilteredPortfolioDetails[0].par30.vs_value - FilteredPortfolioDetails[0].par60.vs_value : FilteredPortfolioDetails[0].par30 - FilteredPortfolioDetails[0].par60,
          par60_to_par90 : active_toogle ? FilteredPortfolioDetails[0].par60.vs_value - FilteredPortfolioDetails[0].par90.vs_value : FilteredPortfolioDetails[0].par60 - FilteredPortfolioDetails[0].par90,
          par90 : active_toogle ? FilteredPortfolioDetails[0].par90.vs_value : FilteredPortfolioDetails[0].par90
        } : ''
        
        return (
          <div className={` col-sm-12 founders_screen_p  ${active_toogle ? "col-lg-6 col-md-6 my-3" : "col-lg-3 col-md-6 my-4"} `} style={{ borderBottom: '0.7px solid #2a2a2a', borderRight: '0.7px solid #2a2a2a' }}>
            <div className="d-flex align-items-center justify-content-between  m-0" >
              <div className={`d-flex align-items-center ${!active_toogle ? "my-4" : ""} `}>
                <div className={`porfolio_risk_calender p-2 ${active_toogle ? "mx-4" : "mx-2 mr-3"} `}>
                  <p className="m-0"><BiCalendar size="20" color="white" /></p>
                </div>
                <div className="my-2">
                  <p className="dashboard_text_color my-2">{item.label}</p>
                  <div className='d-flex founders_screen_val mt-1'>
                    <RoundOffSplitter val={par_details[item.key]} unit={!ActiveCurrency.active_currency ? 'USD' :ActiveCurrency.active_currency} align_center={false} position={"top"} />

                  </div>
                  <p className="dashboard_text_color m-0">
                    { 
                      !active_toogle 
                      ? (isNaN((par_details[item.key] / FilteredPortfolioDetails[0]['os_val_eom']) * 100) 
                          ? 0 
                          : ((par_details[item.key] / FilteredPortfolioDetails[0]['os_val_eom']) * 100).toFixed(2))
                      : (isNaN((par_details[item.key] / FilteredPortfolioDetails[0]['os_val_eom'].value) * 100) 
                          ? 0 
                          : ((par_details[item.key] / FilteredPortfolioDetails[0]['os_val_eom'].value) * 100).toFixed(2))
                    } 
                    % of OS Principal
                  </p>

                </div>
              </div>
              {active_toogle &&
                <div className="my-2 d-flex   justify-content-between align-items-center">
                  <div>
                    <p className="dashboard_text_color my-2 disable_div">{active_toogle == 'last_week' ? 'Last Week': 'Last Month'}</p>
                    <div className='d-flex founders_screen_val mt-1'>
                      <RoundOffSplitter val={par_details_vs_value[item.key]} unit={!ActiveCurrency.active_currency ? 'USD' :ActiveCurrency.active_currency} align_center={false} position={"top"} />

                    </div>
                    <div className="px-3">
                      <p className="m-0 text-white">{calculate_perc_diff(par_details_vs_value[item.key], par_details[item.key]) == 0 ? '' : calculate_perc_diff(par_details_vs_value[item.key], par_details[item.key]) < 0? <IoIosTrendingDown size="25" color={item.decrease_color} />: <IoIosTrendingUp size="25" color={item.increase_color}/>}</p>
                      <p className="m-0" style={{color :calculate_perc_diff(par_details_vs_value[item.key], par_details[item.key]) == 0 ? 'white' : (calculate_perc_diff(par_details_vs_value[item.key], par_details[item.key]) >0 ? item.increase_color: item.decrease_color)}}> {calculate_perc_diff(par_details_vs_value[item.key], par_details[item.key])} %</p>
                    </div>
                  </div>

                </div>}
            </div>
          </div>
        )
      })}

    </div>:
    <div className="text-center text-white" style={{marginTop:'60px', height:'14vh'}}>No Data Found</div>
    }

    <div className="dashboard_title my-2" ><span style={{ paddingRight: '2%' }}><IoIosPie color="blue" size={25} /></span>Best Performance</div>
    {FilteredPortfolioDetails && FilteredPortfolioDetails.length >0 ?
    <div className="row m-0 ">
    { performance && performance.map((item, idx) => {
      let [value,date] = active_toogle ? 
              ((FilteredPortfolioDetails && FilteredPortfolioDetails.length > 0 && FilteredPortfolioDetails[0] && FilteredPortfolioDetails[0][item.key] && FilteredPortfolioDetails[0][item.key].value) ? FilteredPortfolioDetails[0][item.key].value.split(',') : []) : 
              ((FilteredPortfolioDetails && FilteredPortfolioDetails.length > 0 && FilteredPortfolioDetails[0] && FilteredPortfolioDetails[0][item.key]) ? FilteredPortfolioDetails[0][item.key]?.split(',') : []);
      return (
        <div className={`col-lg-6 col-md-6 col-sm-12 founders_screen_p ${!active_toogle ? "my-3" : ""}`} style={{ borderRight: '0.7px solid #2a2a2a', margin: '10px 0px' }}>
          <div className="d-flex align-items-center justify-content-between row m-0" >
            <div className="d-flex align-items-center ">
              <div className="performance_background p-2 mx-2">
                <p className="m-0"><BiSolidDollarCircle size="25" color="white" /></p>
              </div>
              <div className="my-2 mx-2">
                <p className="dashboard_text_color my-2">{item.label}</p>
                <div className='d-flex founders_screen_val mt-1'>
                  <RoundOffSplitter val={isNaN(value) ? 0 : item.key =='max_ontime_repay_rate' ? value * 100 : parseInt(value)} unit={item.key =='max_ontime_repay_rate' ? '%' : !ActiveCurrency.active_currency ? 'USD': ActiveCurrency.active_currency} align_center={false} position={"top"} />

                </div>


              </div>
            </div>

            <div className="my-2 d-flex   justify-content-between align-items-center">
              <div>
                {/* <p className="dashboard_text_color my-2 disable_div"> <b>{
                date == 'None' || !date ? 
                                    moment(activeMonth ? activeMonth : Month ? Month : '').format('YYYY MMM') : 
                                    item.key == 'max_os_val'? 
                                                        (date == 'None' || !date) ? moment().format('MMM YYYY') :  
                                                        moment(date).format('MMM YYYY') : 
                                                        moment(date).format('DD MMM YYYY')}</b></p> */}

                <p className="dashboard_text_color my-2 disable_div"> <b>
                  {item.key=='max_os_val' ?  (moment(date).isValid() ? (moment(date).format("MMM YYYY")) : (moment(activeMonth ? activeMonth : Month ? Month : '').format('YYYY MMM')))  : (!date ? moment(Month ? Month : activeMonth ? activeMonth : '').format('YYYY MMM') : moment(date).format('DD MMM YYYY') )
                  }</b></p>


              </div>

            </div>
          </div>
        </div>
      )
    })}

                </div>
                :
                <div className="text-center text-white" style={{marginTop:'80px', height:'14vh'}}>
                  No Data Found</div>}
                
                </>}
                  
                

                </div>

              </div>
            </div>

          </div>

      <div className="my-3">
        <Accordion >
          <Card style={{ background: '#212533' }}>
            <div className="card-header card-header-icon card-header-primary d-flex justify-content-between align-items-center " onClick={() => { setAccordian(!Accordian) }}>
              <div>
                <div className=" my-4 mt-3 card-title  d-inline-block" style={{ fontSize: '16px' }}><span className="pr-3"><IoIosPie color="blue" size={25} /></span> <b>Account Balance</b></div>

                {/* <h4 className="card-title text-white d-inline-block" style={{padding:'15px 0px 25px 0px'}}>RM Report</h4> */}
              </div>
              <div>
                <Accordion.Toggle className="col" as={Card.Header} eventKey="0" onClick={() => { setAccordian(!Accordian) }}>
                  <BsChevronDown className={Accordian ? "arrow-icon down" : "arrow-icon"} size="18" style={{ marginTop: '-15px' }} />
                </Accordion.Toggle>
              </div>
            </div>
            {Accordian ?
              <div className="card-body">
                <div className="acc_balance_table">
                  <BasicReactTable row_data={AccountBalance && AccountBalance.length >0 ? AccountBalance : []} column_data={ColumnDatas} csv_file={false} FName="Switch Reports" pagination={ AccountBalance?.length >10 } excess_time_action={false} default_page_size={10} />

                </div>
              </div>
              : " "
            }
          </Card>
        </Accordion>
      </div>

      <div className="my-3">
        <Accordion >
          <Card style={{ background: '#212533' }}>
            <div className="card-header card-header-icon card-header-primary d-flex justify-content-between align-items-center " onClick={() => { setbusinessVolume(!businessVolume) }}>
              <div>
                <div className=" my-4 mt-3 card-title  d-inline-block" style={{ fontSize: '16px' }}><span className="pr-3"><IoIosPie color="blue" size={25} /></span> <b>Business Volume</b></div>

                {/* <h4 className="card-title text-white d-inline-block" style={{padding:'15px 0px 25px 0px'}}>RM Report</h4> */}
              </div>
              <div>
                <Accordion.Toggle className="col" as={Card.Header} eventKey="0" onClick={() => { setbusinessVolume(!businessVolume) }}>
                  <BsChevronDown className={businessVolume ? "arrow-icon down" : "arrow-icon"} size="18" style={{ marginTop: '-15px' }} />
                </Accordion.Toggle>
              </div>
            </div>
            {businessVolume && businessVolumeArr ?
              <div className="card-body">
                <div className="acc_balance_table">

                  <div>
                    <div className='  d-flex justify-content-start p-3 py-4' style={{ background: '#1d212d', marginBottom:'60px' }}>
                      {businessVolumeArr.map((item, idx) => {
                        return (
                          <div key={idx} className={` d-flex justify-content-between mx-2 pointer  px-2 py-1 ${(ActiveTab == (item.report_type)) ? "active_chartTab" : ""}`} onClick={() => {
                            setActiveChartKey(item.key)
                            setChartType(item.chart_type)
                            setActiveTab(item.report_type ?item.report_type:item.label);
                            Market.country_code !== '*' && currentCountryactiveCurrency.active_currency !== ActiveCurrency.active_currency ? getChartsData(item.report_type ? item : null, 'currency'):getChartsData(item.report_type ? item : null)
                          }}>
                            <p className='m-0 text-white px-4 py-3' style={{fontWeight:500, fontSize:'14px'}}>{item.label}</p>
                          </div>
                        )
                      })}
                    </div>

                   {ChartLoader && ChartLoader ? <div style={{height:'100vh'}}><div className="product_takeup_loader" style={{marginTop:'50px'}} ></div></div>:
                   <>
                    <div className={`founders_chart_legend`}>

                        { ActiveTab=="get_portfolio_chart"  ? <>
                        <div style={{width:'25%', marginBottom:'20px'}}>
                          <ReactSelect options={get_par_days()} 
                          styles={customStyles} 
                          allowSelectAll={true}  
                          hideSelectedOptions={false} 
                          isMulti 
                          onChange ={(option)=>handle_par_day_change(option)}
                          defaultValue={SelectedParDays}
                          components={{Option}}
                          closeMenuOnSelect={false}
                          />
                        </div>

                        
                          <ReportChart 
                          type={ChartType} 
                          colors={["#00e396", "#e82ee2", "#fadd17", "#5a8ff2", "#db4b12"]} 
                          biz_ops_chart_height={600} 
                          show_toolbar={false} 
                          data={charts_value['get_portfolio_chart'] ?charts_value['get_portfolio_chart'] :[] }
                          curr={'%'} 
                          legend={true} 
                          founders_chart={true} 
                          legend_position={'bottom'}
                          dataLabel={true}
                          />
                          </>

                          :
                          ActiveTab=="get_total_and_overdue_fa"  ?
                          <ReportChart type={ChartType} colors={['#14855c','#ff3154']} biz_ops_chart_height={650} show_toolbar={false} data={[{data:charts_value['Total Disbursed'],name:'Total Disbursed'}, {data:charts_value['New Overdues'], name:'New Overdues'}]}   legend={true} founders_chart={true}  dataLabel={true} />
                          :
                          ActiveTab == 'cohort_report' ?
                          <ReportChart type={ChartType} colors={['#14855c','#A116C0','#E5D01A', '#19B1B3', '#0AF207', '#FA0C08']} biz_ops_chart_height={600} show_toolbar={false} data={CohartChartData && CohartChartData.chart_data.length>0 ? CohartChartData.chart_data : []} curr={ '%'}  legend={true} founders_chart={true} categories={CohartChartData && CohartChartData.categories.length > 0 ? CohartChartData.categories : []} xaxistype ='category'  stacked={true} biz_ops_colunm_width={'30%'} dataLabel={true} dataLabelColor={['#FFF']} inside_label={true} y_axis_min={90} y_axis_max={100} tick_amount={2}/>
                          :
                          ActiveChartData && charts_value ?
                          
                          <ReportChart type={ChartType} colors={['#14855c']} biz_ops_chart_height={600} show_toolbar={false} data={[{ data: charts_value[ActiveChartData], name: `${Market.country_code == "*" ? 'Global': Market.country_code}` }]} curr={ActiveChartKey=='average_loan_duration' || ActiveChartKey=='loan_disbursed' ?'': ActiveChartKey =='ontime_payment'? '%':ActiveCurrency.active_currency ? ActiveCurrency.active_currency : 'USD'}  founders_chart={true} biz_ops_colunm_width={ChartType == 'bar' ? '30%':''} dataLabel={true} 

                         

                          y_axis_tittle={ActiveChartKey == 'gross_txn_value' ? (Market.country_code == '*'?'Gross Value (USD)': `Gross Value(${ActiveCurrency.active_currency})`): ActiveChartKey == 'average_loan_duration' ? "Loan Duration (Days)" : ActiveChartKey == 'amount_disbursed' ? "Amount of Disbursal": ActiveChartKey == 'loan_disbursed' ? 'Loan Count': '' }  
                          legend = {ActiveChartKey == 'ontime_payment' || ActiveChartKey == 'gross_txn_value' || ActiveChartKey == 'average_loan_duration' || ActiveChartKey == 'average_loan_size' || ActiveChartKey == 'loan_disbursed' ? true : false  } 
                          
                          />
                          :''
                          

                          
                          

                        }


                        </div></>}
                  </div>
                </div>
              </div>
              : " "
            }
          </Card>
        </Accordion>
      </div>
    </div>



  )


}

export default FoundersHome