import { Badge, Box, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { Homeacc_prvdrLogoselector } from '../../../helpers/common_helper';
import { useSelector } from 'react-redux';

interface Provider {
    acc_prvdr_code: string;
}

interface AccProviderFilterProps {
    acc_prvdr_list: string[];
    rows: Provider[];
    handle_acc_prvdr: (provider: string) => void;
    selectedProviders:string[];
}
interface CommonSliceState {
    acc_prvdr_list: string[]
}
interface RootState {
    common_slice: CommonSliceState;
}

const AccProviderFilter: React.FC<AccProviderFilterProps> = ({  rows,selectedProviders, handle_acc_prvdr }) => {
    const { acc_prvdr_list } = useSelector((state: RootState) => state.common_slice);
    const [activeAccPrvdr, setActiveAccPrvdr] = useState<string>("");
    const theme = useTheme()
    const handleProviderSelect = (provider: string, event: React.MouseEvent): void => {
        event.stopPropagation(); 
        setActiveAccPrvdr(provider);
        handle_acc_prvdr(provider);
    };

    const all_bttn_sx = {
        // @ts-ignore
        backgroundColor:theme.palette.custom_colors.secondary_a_50,
        padding:'6px 20px',
        borderRadius:'5px',
        fontSize:'12px',
        margin:'10px',
        // @ts-ignore
        color:theme.palette.custom_colors.text,
        border: selectedProviders.includes('All') ? '2px solid white' : '',
        opacity: !selectedProviders.includes('All') ? '0.5' : ''
    }

    const renderBadge = (provider: string, label?: React.ReactNode) => {
        const count =
            provider === "All"
                ? rows.length
                : rows.filter(({ acc_prvdr_code }) => acc_prvdr_code === provider).length;

        return (
            <Badge
                key={provider}
                color="primary"
                badgeContent={count}
                showZero
                onClick={(event) => handleProviderSelect(provider, event)} 
                sx={
                    provider=="All" ? all_bttn_sx:
                    {
                    cursor: 'pointer',
                    // border: selectedProviders.includes(provider) ? '2px solid white' : '',
                    opacity: (!selectedProviders.includes(provider)&& !selectedProviders.includes('All')  )? '0.5' : '',
                    borderRadius: '5px',
                    mx: 1,
                 }}
            >
                {label || <Homeacc_prvdrLogoselector logotype={provider} />}
            </Badge>
        );
    };

    return (
        <Box>
            {acc_prvdr_list?.length>1 &&
            <>
            {renderBadge("All", "ALL")}
            {acc_prvdr_list.map(( acc_prvdr_code ) => renderBadge(acc_prvdr_code))}
            </>
            }
        </Box>
    );
};

export default AccProviderFilter;