import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { list_due_customers, list_overdue_customer } from "../../../../../actions/report_actions";
import { common_req } from "../../../../../helpers/helper";
import FilterTabs from "./FilterTabs";
import Table from "./Table";
import { Config } from "../../../../../helpers/storage_helper";

type PortFolioGetResProps = { id: string;[key: string]: any }[]; // Ensure each row has a unique `id`

const CollectionFollowUps = () => {
  const [mainTab, setmainTab] = useState<"due" | "overdue">("due");
  const [subMenu, setsubMenu] = useState({ due: "today", overdue: "all" });
  const stale_time = Config('collection_followup_cache_time')??5
  const fetchDueCustomers = async (id: string) => {
    const request = {
      type: subMenu[mainTab] === "all" ? null : id,
    };
    const response = await list_due_customers(common_req(request));
    if (response && response.status === "success") {
      return response.data;
    } else {
      throw new Error("Failed to fetch due customers");
    }
  };

  const fetchOverdueCustomers = async (id: string) => {
    const request = {
      type: id === "all" ? null : id,
    };
    const response = await list_overdue_customer(common_req(request));
    if (response && response.status === "success") {
      return response.data;
    } else {
      throw new Error("Failed to fetch overdue customers");
    }
  };

  

  const { data: dueCustomers, isLoading: isDueLoading } = useQuery<PortFolioGetResProps>({
    queryKey: ["due_customers", subMenu],
    queryFn: () => fetchDueCustomers(subMenu[mainTab]),
    enabled: mainTab === "due",
    staleTime: 1000 * 60 * stale_time
  });

  const { data: overdueCustomers, isLoading: isOverdueLoading } = useQuery<PortFolioGetResProps>({
    queryKey: ["overdue_customers", subMenu[mainTab]],
    queryFn: () => fetchOverdueCustomers(subMenu[mainTab]),
    enabled: mainTab === "overdue",
    staleTime: 1000 * 60 * stale_time

  });

  const tabHandler = (main_tab: "due" | "overdue", sub_tab: string) => {
    setmainTab(main_tab);
    setsubMenu((prevTab) => ({ ...prevTab, [main_tab]: sub_tab }));
  };

  const data = (mainTab === "due") ? dueCustomers : overdueCustomers;
  return (
    <Box>
      <FilterTabs tab_handler={tabHandler} />
      <Table rows={data} tab={mainTab} loader={isDueLoading || isOverdueLoading} />
    </Box>
  );
};

export default CollectionFollowUps;
