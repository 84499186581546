import React, { useEffect, useMemo, useState } from 'react'
import { useTable, useSortBy, useGlobalFilter,useBlockLayout,usePagination} from 'react-table'
import Papa from "papaparse";
import { useExportData } from "react-table-plugins";
import { GlobalFilter } from './global_filter';
import { Config, get } from './storage_helper';
import { get_csv,make_readable } from './common_helper';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { formatNumber } from './product_helper';
import { Link } from 'react-router-dom';
import CommonFilterShow from '../UI/loan_appl/component/CommonFilterShow';


export const BasicReactTable = ({row_data, column_data,excessTimeaction, appliedRowblink,paymentRowblinktime,footer, global_search, csv_file, pagination, default_page_size, FName = "report",searchData, hidetooltiptitle,export_csv_datas,header_props, defaultSorted = [],  header_class = "",blink = false,  server_side_pagination = null, total_page_count : controlledPageCount, filter_data, handleExport, csv_total_data,timeServer=null,appliedRowblinktime ,approveRowblinktime, consolidation_data = null, searched_filter_id = null, excepted_filters = null, modify_search = false, handleModify = null, callSearchApi, filter_total_data = null}) => {

    const export_csv_prv = Config("export_csv_prv");
    let role_code=get('role_codes');
    // if(role_code == 'operations_auditor' ){
    //     role_code=get('user_id');
    // }
    let title=searchData ?searchData[0]['title']:null;
    const addl_csv_prv_role=Config('addl_csv_prv_role'); 

    var file_name=FName;

   const data = React.useMemo(
     () => row_data,
   )

   const columns = React.useMemo(
     () => column_data,
   )
   const[currentPage,setcurrentPage]=useState(null)
   const[pagecount,setpagecount]=useState(1)
   const[pageNumberValue,setpageNumber]=useState(1)
   const[pageisClicked,setpageIsClickOrNot]=useState(false)
   const [csvLoader, setcsvLoader] = useState(false)
   const[Export, setExport] =useState(false)

   const [initial_render , setInitialRender] = useState(true)



   const {
     getTableProps,
     getTableBodyProps,
     headerGroups,
     prepareRow,
     exportData,
     state :{pageIndex, pageSize, globalFilter},       
     nextPage,
     previousPage,
     canNextPage,
     canPreviousPage, 
     page,  
     setPageSize,
     pageOptions,
     setGlobalFilter,
     gotoPage,    
     rows,
     footerGroups, 
   } = useTable({  columns,data,  
                    initialState : {
                                    pageIndex : server_side_pagination ? server_side_pagination.pagination_page :  0,
                                    pageSize: server_side_pagination ? server_side_pagination.pagination_count : default_page_size, 
                                    hiddenColumns:columns.map(column=>{
                                        if (column.show == false) return column.id
                                    }),

                                    sortBy: defaultSorted
                                },
                    getExportFileBlob,getExportFileName ,manualPagination : server_side_pagination ? true : false,
                    pageCount : server_side_pagination ? controlledPageCount : Math.ceil(row_data.length / default_page_size) },useGlobalFilter,useSortBy,useBlockLayout,usePagination,useExportData)   



   function getExportFileBlob({ columns, data, fileType, FName}) {

    if (fileType === "csv") {
        if(file_name == "Partial Payments") {
            const keyToUnset = 'View Transactions';
            columns = columns.filter(item => item.Header !== keyToUnset);
        }
        const custom_csv_data = export_csv_datas ? export_csv_datas : data;
        const headerNames =  header_props ? header_props: columns.map((col) => col.id);

        const csvString = Papa.unparse({ fields: headerNames, data:custom_csv_data });
        return new Blob([csvString], { type: "text/csv" });
        }
        return false;
    }
   
   function getExportFileName({}){
    return `${file_name ?   file_name : 'Export-Data'}`;
   }

   function getPageIndex(e){

    setpagecount(1)
    setcurrentPage(e.target.value)
   }

    function handleNextClick( next=null,Previous=null){
        setpageIsClickOrNot(false)
       var count=pagecount
       if(next!=null){
        setpagecount(count+1)
        setpageNumber(count+1)

        }
        else{
            setpageNumber(count-1)
            setpagecount(count-1)

        }

    }
   

//    const {globalFilter} = state 
//    const {pageIndex, pageSize} = state   


   const getClassName = (rowInfo) =>{
    if(excessTimeaction && rowInfo){
        if(appliedRowblink){                 
            if(paymentRowblinktime){
                const time_diff = moment.duration(moment(timeServer).diff(moment(rowInfo.original.updated_at)))                                                                                                 
               console.log('_milliseconds', time_diff,time_diff._milliseconds)
                return time_diff._milliseconds > (paymentRowblinktime*60000) ? 'redBlink_table' : '';
            }else if(blink){
                return 'redBlink_table'                                       
            }
            // console.log('rowInfo', rowInfo)
            const time_diff = moment.duration(moment(timeServer).diff(moment(rowInfo.original.loan_appl_date)))                                                                                                 
            
            let className = (time_diff._milliseconds > (appliedRowblinktime*60000) )? 'redBlink_table' : ''                                             
            return className;                     
        }else if(!appliedRowblink){                                
            const time_diff1 = moment.duration(moment(timeServer).diff(moment(rowInfo.original.loan_approved_date)))
            const time_diff2 = moment.duration(moment(timeServer).diff(moment(rowInfo.original.loan_appl_date)))                                                                                                                                                
            
            let  className = ((time_diff1.milliseconds > (approveRowblinktime*60000)) || (time_diff2._milliseconds > (approveRowblinktime*60000))) ? 'redBlink_table' : ''      
              
              return className;
            
        } 
        
                               
    }
    

    return{}  
   }

   const get_page_list = (default_page_size)=>{
    let page_list = [5,10,20,50,100]
    if(!page_list.includes(default_page_size) && default_page_size !=0 && default_page_size>0 &&  typeof default_page_size == 'number'){
        page_list.push(Number(default_page_size))
        page_list.sort((a,b)=> a-b)
    }
    return page_list
   }



   useMemo(()=>{
    if(server_side_pagination && !initial_render){
        filter_data(pageIndex, pageSize)
    }

    if(initial_render){
        setInitialRender(false)
    }

   },[pageIndex, pageSize])


   const csvprint = async (event) => {

    setcsvLoader(true)
    await handleExport(event)

    if(event == 'download'){
        setExport(true)
    }
    
    
};

const call_search_api = (event) => {
    callSearchApi(event)
}

const handleModifySearch  = (event) => {
    handleModify(event)
}

useMemo(()=>{
    if(server_side_pagination && csvLoader && Export){
        setcsvLoader(false)
    } 
}, [export_csv_datas])


   return (
    <> 

    {csv_file &&

     <>
     <div className={`row ${consolidation_data ? 'mb-5': ''}`}>
        <div className='col-9'>
        {consolidation_data && 
            <div className='row'>
                {consolidation_data.map((item, i)=>
                    <div className='col-auto' key={i}>
                        <div className='mr-5'>
                            <p className='text-white size_medium'>{item.key}</p>
                            <b className='text-white audit_kyc_text'>{item.key.toLowerCase().includes('count') ? item.value : formatNumber(item.value)}</b>
                            {!item.key.toLowerCase().includes('count') && 
                                <span className='text-white audit_kyc_text' >{` ${get('market').currency_code}`}</span>
                            }
                        </div>
                    </div>
                )}
            </div>
        }
        </div>
        <div className={`col-3 ${consolidation_data ? '': 'text-right'}`}>
        {modify_search ? <div><Link onClick={(e)=>handleModifySearch(e)} style={{fontSize:"16px"}}>Modify Search</Link></div> :null}
        {
        
         (export_csv_prv&& export_csv_prv.includes(role_code)) || ( addl_csv_prv_role && addl_csv_prv_role[role_code] && addl_csv_prv_role[role_code] != 'undifinded' && addl_csv_prv_role[role_code].includes(title)) ?

            // <button style={{float:'right'}} className='btn btn-info btn-sm rect_tablev7_export_button'
            // onClick={() => {exportData("csv", true)}}
            // >Export to CSV</button>
                (server_side_pagination ?  
                    csv_total_data && csv_total_data > Config("export_csv").limit ? 
                
                            ( <Link
                                style={{fontSize:"16px"}}
                            onClick={() => csvprint('email')}
                            >Export as CSV</Link>) 
                            : 
                    
                            // (<button style={{float:'right'}} className='btn btn-info btn-sm rect_tablev7_export_button'
                            //     onClick={() => csvprint('csv')}
                            //     >{!csvLoader ? '' : 'Export as CSV'}</button>)

                            (!csvLoader && !Export ? <Link type="button" className='size_medium' onClick={()=>csvprint('download')}>Prepare for CSV</Link> :
                                csvLoader ? <><Link type="button"style={{height:'25px', paddingLeft:'55px', paddingRight:'55px'}} >
                                
                                                   <div class="sms_modal_container p-2">
                                                        <div class="sms_modal_wrapper">
                                                            <div class="sms_modal_loader">
                                                                <div class="sms_modal_dot">
                                                                </div>
                                                            </div>
                                                            <div class="sms_modal_loader">
                                                                <div class="sms_modal_dot">
                                                                </div>
                                                            </div>
                                                            <div class="sms_modal_loader">
                                                                <div class="sms_modal_dot">
                                                                </div>
                                                            </div>
                                                            <div class="sms_modal_loader">
                                                                <div class="sms_modal_dot"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                </Link></>: 
                            <CSVLink filename={`${FName}.csv`} data={ export_csv_datas } style={{fontSize:"16px"}}>Export as CSV</CSVLink>)

                : 
                
                    <CSVLink filename={`${FName}.csv`} data={export_csv_datas ? export_csv_datas : data} style={{fontSize:"16px"}}>Export as CSV</CSVLink>) 
        :
            <Link  className='text-white'
            onClick={() => make_readable(searchData)}
            >Request for CSV</Link> }
            </div>
        </div>
     </>
     }
     
        {global_search &&
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
        }
        {searched_filter_id &&
            <CommonFilterShow excepted_filters = {excepted_filters} searched_filter_id = {searched_filter_id} total_count = {filter_total_data} call_search_api = {(e) => call_search_api(e)}/>
        }
        <div id="printablediv" className={'react_table_v7 table-responsive customer_list_reactTable border_bot'}>        
    

            <table {...getTableProps()}>
                <thead className={`${header_class} `}>
                    {headerGroups.map(headerGroup => (
                    <tr  className='no-margin no-padding' style={{border:"1px sold red",borderWidth:'100%'}} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                        <th {...(hidetooltiptitle ? column.getHeaderProps(column.getSortByToggleProps({ title: undefined })) : column.getHeaderProps(column.getSortByToggleProps()))}>

                            {column.render('Header')}
                            <span>
                                {column.isSorted ? (column.isSortedDesc ? " ⇑" : " ⇓") : ""}
                            </span>
                        </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}  className={`${getClassName(row)}`}>
                        {row.cells.map(cell => {
                            return (
                            <td
                                {...cell.getCellProps({className:cell.column.className})}                            
                            >
                                {cell.render('Cell')}
                            </td>
                            )
                        })}
                        </tr>
                    )
                    })}
                </tbody>{footer &&
                <tfoot style={{color:'white'}}>
                {footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()}>
                    {group.headers.map(column => (
                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                    ))}
                </tr>
                ))}
            </tfoot>}
            </table>
        </div>
     
        {pagination&&

        <div className='d-flex justify-content-center align-items-center'  style={{marginTop:'10px'}}>
            <button className='btn pagination_btn gds_table_pagination' onClick={()=>{previousPage();handleNextClick(null,"previous")}} disabled={!canPreviousPage}>Previous</button>
            <span style={{fontSize:'14px',color:'white',margin:'0px 30px'}}>
                Page<strong><span >

                
                   {/* <input className='btn btn-info no-margin'  style={{padding:'10px 5px 10px 9px',width:'40px'}} type={"number"} min="1" max={pageOptions.length} 
                   onClick={()=>{setpageIsClickOrNot(true)}}
                   value={pageIndex + 1}
                   onChange={(e)=>{
                       const pageNumber = e.target.value ? Number(e.target.value) -1 : 0
                       setpageNumber(pageNumber)
                       gotoPage(pageNumber)
   
                   }} /> */}

                {pageisClicked ?
                   <input className='btn pagination_btn no-margin'  style={{padding:'10px 5px 10px 9px',width:'5rem'}} type={"number"} min="1" max={pageOptions.length} 
                   onClick={()=>{setpageIsClickOrNot(true)}}
                   onChange={(e)=>{
                       const pageNumber = e.target.value ? Number(e.target.value) -1 : 0
                       setpageNumber(pageNumber)
                       
   
                   }} onBlur={()=>{gotoPage(pageNumberValue);}}></input>  :
                   <input className='btn pagination_btn no-margin'  style={{padding:'10px 5px 10px 9px',width:'5rem'}} type={"text"} min="1" max={pageOptions.length} value={ pageIndex + 1}
                   onClick={()=>{setpageIsClickOrNot(true)}}
                   onChange={(e)=>{
                       const pageNumber = e.target.value ? Number(e.target.value) -1 : 0
                       setpageNumber(pageNumber)
                       gotoPage(pageNumber)
   
                   }} ></input>
                
                }
                
                
               
            </span> of {pageOptions.length}</strong>
            </span>
           
            <select className='btn pagination_btn' value={pageSize} onChange={e=> {getPageIndex(e);setPageSize(Number(e.target.value));setpageNumber(1); gotoPage(0)}} style={{margin:'0px 30px',padding:'8px'}}>
                {   
                    
                    get_page_list(default_page_size).map((pageSize,idx) => (
                        <>
                         {/* {(currentPage ==null && idx==0 )&& default_page_size &&
                            <option key={pageSize} value={default_page_size}>  Rows of { default_page_size}  </option>

                         } */}
                               <option key={pageSize} value={pageSize}>  Rows of { pageSize}  </option>

                        </>
                    ))
                }
            </select>
            <button className='btn pagination_btn gds_table_pagination' onClick={()=>{nextPage();handleNextClick("next",null)}} disabled={!canNextPage}>Next</button>
        </div>
        }
        
     </>
   )
 }