import FlowComponent from '../common/component/core/FlowComponent';
import React from 'react';
import {borrowerSearch} from '../../actions/borrower_actions';
import {format_date_tbl,capitalize_first_letter, Homeacc_prvdrLogoselector,title_case, getValueFromLangJson} from '../../helpers/common_helper';
import { Link} from 'react-router-dom';
import {searchLead, updateAuditedby} from "../../actions/lead_actions";
import {TooltipLabel} from "../common/partial";
import { RMNameSplit } from '../../helpers/common_helper';
import { get } from '../../helpers/storage_helper';
import { listAccountProvider } from '../../actions/account_provider_actions';
import CommonReactTable from '../../helpers/commonReact_table';
import { sortDatecommon, sortBizcommon } from '../../helpers/sortingHelpers';
import { Button } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion';
import {Card} from 'react-bootstrap'
import {BsChevronDown} from 'react-icons/bs';
import { check_priv } from '../../helpers/storage_helper';
import {dd_value} from "../../actions/common_actions";
import { BasicReactTable } from '../../helpers/react_table';
import withRouter from '../../Router/Withrouter';
import MUIAccordion from '../../MUI/Components/MUIAccordion';
import HomeScreenTable from './HomeScreenTable';
import { GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import DataGridTable from '../CommonComponent/table';
import CustomTableGridToolbar from '../CommonComponent/table/components/customTableGridToolbar';
import AccProviderFilter from '../common/component/AccProviderFilter';
import { handleProviderSelection } from '../../helpers/helper';
import { connect } from 'react-redux';
class KycPendingList extends FlowComponent {

	state = {
		columns :[],
		lists : [],
		copy:[],
		dyn_Btn_selected:[],
		all_count:0,				
		filter_actions:{},
		all_Btn_status:false,
		all_Acc_pvdrs:[],	
		show_table: false,
		page: { page: 0, pageSize: 10 },
		selectedProviders: [],
        filteredRows: [],

	}

	handleToggle = (e) => {

        switch(e){
            case("Account"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
        }
    }
	
	componentWillMount() {		
		
		this.drawTable()		
    }

	

	drawTable () {
		const market = get('market')
		const market_name = market.country_code
		let lead_search_criteria = {status : 'pending_name_n_audit', last_30_days : true}
      	searchLead(this.req({lead_search_criteria}))
			.then((response) => {				
				if(!response){return };
				if(response.status === "success"){
					this.setState(state => state.columns = this.columndatas())									
					if (response.data.length > 0){																							
						this.setState({lists:response.data})
						this.setState(state=>state.all_count = response.data.length )	
                        this.handle_acc_prvdr("All",response.data)
						
					}											       		
				}
			})
	}

	handleAuditKyc = (e, row) =>{
        var audited_by = row.audited_by
        var auditor_name = row.auditor_name
        var resp = null
        if(audited_by == null && check_priv('lead','pending_kyc_list')){
            resp = window.confirm("You are going to initiate the audit KYC process for this lead. Once you start, no other auditor can act on this lead.Are you sure?")
            if(resp){
                updateAuditedby(this.req({id: row.id}))
                .then((response) => {
                    this.resp(response)
                    if(!response){return};
                    if(response.status === "success"){

                        // window.location.href = "/lead/audit_kyc/"+row.id;
						this.props.navigate("/lead/audit_kyc/"+row.id)
                    }
                })
            }
        }
		else if ( !check_priv('lead','pending_kyc_list')){
			window.alert(`You cannot take any action to this lead. However you can view the KYC record`)
			// window.location.href = "/lead/audit_kyc/"+row.id;
			this.props.navigate("/lead/audit_kyc/"+row.id)

		} 
		else if (audited_by != null && get('user_id') !=  audited_by){
            window.alert(`${auditor_name} has already initiated audit process for this KYC record. You can not take action. However you can view the KYC record`)
            // window.location.href = "/lead/audit_kyc/"+row.id;
			this.props.navigate("/lead/audit_kyc/"+row.id)


        }else if (audited_by != null && get('user_id') ==  audited_by){
            // window.location.href = "/lead/audit_kyc/"+row.id;
			this.props.navigate("/lead/audit_kyc/"+row.id)
        }  
		     
        
    }


	columndatas () {
		return [
			// 	{
			// 	Header : "Cust ID",
			// 	accessor: 'cust_id' ,
			// 	Cell: e =>  <Link to={"/borrower/indiv/view/"+e.value} target="_blank" >{e.value}</Link>,
			//
			// },
			{  
				headerName: 'Account Number',  
				field :'acc_prvdr_code',
				headerAlign: 'left',width:200, resizable: true, align: 'left',

				renderCell:params => <div className='d-flex align-items-center'> <Homeacc_prvdrLogoselector logotype={params.row.acc_prvdr_code}/> <p className='no-margin' style={{paddingLeft:'20px'}}> {params.row.account_num}</p> </div> ,
			},
			{
				headerName : "Biz Name",
				field: 'biz_name',
				width:200, resizable: true,			
				headerAlign: 'left', align: 'left',
			
				renderCell: params =><TooltipLabel full_text={params.row.biz_name} overlay={params.row.cust_name} no_reduce={true} />,	
			},
			{  
				headerName : "Lead Created Date",
				field: 'lead_date', 
				headerAlign: 'left', width:200, resizable: true, align: 'left',

				// sortMethod: (a,b) => this.sortDate(a,b),
				renderCell:params => format_date_tbl(params.row.lead_date),
		     },
			 {  
				headerName: "Mobile Number",  
				field :'mobile_num',
				headerAlign: 'left', width:200, resizable: true, align: 'left',

				renderCell : params =>(params.row.mobile_num),
				style : {textAlign:"center"} 
			},
			{  
				headerName: `${getValueFromLangJson("rm_label_short")} Name`,  
				field :'flow_rel_mgr_name',
				sortable:true,
				headerAlign: 'left', width:200, resizable: true, align: 'left',

				renderCell : params =>title_case(params.row.flow_rel_mgr_name),
			   
			},
			{
				headerName : "Type",
				field: 'type',
				headerAlign: 'left', width:200, resizable: true, align: 'left',
				
				renderCell:params=>dd_value(params.row.type, 'lead_type')
			},{
				headerName : "Initiated By",
				field: 'auditor_name',
				headerAlign: 'left', width:200, resizable: true, align: 'left',
				minWidth:250,	
				renderCell:params=> params.row.auditor_name ? params.row.auditor_name : "NA"
			},
			{
				headerName : "Audit Initiated",
				// sortable: false,
				field: 'audit_start_time',
				width:200, resizable: true,

				renderCell:params=>params.row.audit_start_time ? params.row.audit_start_time+" ago" : "NA"
			},
			{
				headerName : "Action",
				// sortable: false,
				field: 'id' ,	width:140, resizable: true,
				renderCell:  params  => (<Button variant="primary" onClick={e => this.handleAuditKyc(e, params.row)}>
					{check_priv('lead','pending_kyc_list')?'Audit KYC':'View KYC'}</Button> )

			}
					
			]
	}

	handle_acc_prvdr(provider , rows = null ) {
		let row_data = rows??this.state.lists ;

		handleProviderSelection(
		  provider,
		  this.state.selectedProviders,
		  this.props.acc_prvdr_list,
		  row_data,
		  (updated_prvdr) => this.setState({ selectedProviders: updated_prvdr }),
		  (filtered_rows) => this.setState({ filteredRows: filtered_rows })
		);
	  }

    render(){		 
        return (
			<MUIAccordion
			title={
						<div className="card-header card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between">
							<h4 className="card-title text-white tableHead_font mb-0 title-bottom">Leads with Pending KYC Audit</h4>
							<AccProviderFilter
                            rows={this.state.lists??[]} 
                            handle_acc_prvdr={(provider)=>this.handle_acc_prvdr(provider)}
                            selectedProviders ={this.state.selectedProviders}
                            />	
						</div>	
			} 
			body={
				<DataGridTable
                rows={this.state.filteredRows??[] } 
                paginationMode= {"client"}
                page={this.state.page}
                setpage={(newPage) => this.setState({ page: newPage })}
                // isLoading={ this.state.loader }
                rowCount={this.state.filteredRows?.length}
                columns={this.state.columns}
				slots={{ toolbar: CustomTableGridToolbar }}
				slotProps={{
				  toolbar: {
					showQuickFilter: true,
				  },
				}}
                
                />
			}
			total_count={ this.props.acc_prvdr_list?.length>1 ? 0: (this.state.lists?.length)}
			>

			</MUIAccordion>



			
        )

    }
}


const mapStateToProps = (state) => {
	return {
	  acc_prvdr_list : state.common_slice?.acc_prvdr_list

	};
  };

export default withRouter(connect(mapStateToProps)(KycPendingList));



