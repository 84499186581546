import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { Link} from 'react-router-dom';
import {getMasterData,dd_value} from '../../../actions/common_actions';
import {Button } from 'react-bootstrap';
import DatePicker from 'react-datetime';
import moment from 'moment';
import '../../../styles/flow.css';
import {getRefAccounts} from '../../../actions/account_txn_actions';
import $ from 'jquery';
import {check_priv, get,get_item} from '../../../helpers/storage_helper';
import {rt_algn,lbl_amt,Homeacc_prvdrLogoselector} from '../../../helpers/common_helper';
import {format_date_tbl} from '../../../helpers/common_helper';
import { list_lender_txn_stmts } from '../../../actions/lender_actions';
import CommonReactTable from '../../../helpers/commonReact_table';
import { AccountTransactionToolTipLabel,TooltipLabel,ExcesstimeAction } from '../../common/partial';
import {ImArrowUpRight,ImArrowDownLeft} from "react-icons/im";
import {reinitiateRecon} from '../../../actions/loan_actions';

import ReconStatus from '../component/ReconStatus';
import withRouter from '../../../Router/Withrouter';
import { BasicReactTable } from '../../../helpers/react_table';
import { find_id } from '../../../helpers/product_helper';
import DataGridTable from '../../CommonComponent/table';
import { Box } from '@mui/material';
import ExportCsv from '../../common/component/ExportCsv';
class UnknownTransactions extends FlowComponent{


    state = {
        txn_type : "",
        from_date : "",
        to_date : "",
        recon_status : "",
        rowdatas:[],
        columndatas:[],
        copy:[],
        loader:false,
        no_data:false,
        page: { page: 0, pageSize: 10 },

    }

    componentWillMount ()
    {
       this.get_datas()
    }

    get_datas(){
        if(this.props.today === 'true') {
            let recon_status = this.props.unimported === 'true' ? 'unimported_txns' : 'unknown_txns';
            const statement_search = {recon_status, stmt_txn_date__from: this.props.params.today, stmt_txn_date__to: this.props.params.today}
            this.unknown_txn_status(statement_search)
        }
        else {
            const today = new Date();
            const yesterday = new Date();
            let year = "";
            function formatDate(date, format) {
                format = format.replace('mm', date.getMonth() + 1)
                    .replace('yy', date.getFullYear())
	                .replace('dd', date.getDate());
                return format
            } 
            year = today.getFullYear()
            yesterday.setDate(today.getDate() - 1)
            if(formatDate(today, "yy-mm-dd") ==`${year}-1-1`){
                year = today.getFullYear()-1
            } 
            this.setState({from_date : `${year}-01-01`});        
            this.setState({to_date : formatDate(yesterday, "yy-mm-dd")}); 

            if(this.props.unimported === 'true'){
                const statement_search = {recon_status: 'unimported_txns', stmt_txn_date__from: this.props.params.today, stmt_txn_date__to: this.props.params.today}
                this.unknown_txn_status(statement_search)    
            }else{
                const statement_search = {recon_status: 'unknown_txns', stmt_txn_date__from: `${year}-01-01`, stmt_txn_date__to: formatDate(yesterday, "yy-mm-dd")}
                this.unknown_txn_status(statement_search)
            }
        }
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.get_datas()
        }
      }

    handleTxnType = (event) => {
      this.setState({'txn_type' : event.target.value})
    }

    handleReconStatus = (event) => {
        this.setState({'recon_status' : event.target.value});
    }

    handleDateChange = (id, value) => {
        value = moment(value).format("YYYY-MM-DD")
        if(id === "from_date"){
            this.setState({from_date : value});
        } else if(id === "to_date"){
            this.setState({to_date : value});
        }
      }

    handleClick = async () => {
        if(this.validate_search()){
            let statement_search;
            if (this.state.recon_status){
                statement_search = {stmt_txn_type: this.state.txn_type, recon_status: this.state.recon_status, stmt_txn_date__from: this.state.from_date, stmt_txn_date__to: this.state.to_date};
            } else {

                let recon_status = this.props.unimported === 'true' ? 'unimported_txns' : 'unknown_txns'
                statement_search = {stmt_txn_type: this.state.txn_type, recon_status, stmt_txn_date__from: this.state.from_date, stmt_txn_date__to: this.state.to_date};
            }
            // await list_lender_txn_stmts(this.req({statement_search}, false, 'statement_search'))
            // .then((response) => {
            //     this.resp(response)
            //     if(!response)
            //     {
            //         return null;
            //     }
            //     if(response.status === "success"){
            //     this.setState(state=>state.rowdatas = response.data.account_txns)
            //     this.setState(state=>state.copy = response.data.account_txns)
            //     this.setState(state=>state.columndatas=this.columndatas())
            //     this.setState({account_result:"show_div"});   
            //     }else{
            //       alert(response.message + "\n" + JSON.stringify(response.data));
            //     }
            // });
            this.unknown_txn_status(statement_search)

          }    	
        } 
        

    validate_search(){
        if(this.state.from_date && this.state.to_date){
            return true
        }else{
            alert("Please enter valid search criteria")
            return false
        }
    }

    chk_txn_type(record){
        const cr_amt = record.cr_amt
          if (cr_amt === 0) {
              return<span >{dd_value(record.stmt_txn_type)} <ImArrowUpRight className="ml-2" color="red"/></span>
          }
          else{
              return <span>{dd_value(record.stmt_txn_type)} <ImArrowDownLeft className="ml-2" color = "green"/></span>
          }
    }

    handleReinitiateRecon = (event, row)=>{
        const amount = row.stmt_txn_type == 'credit' ? row.cr_amt : row.stmt_txn_type == 'debit' && row.dr_amt
        const resp = window.confirm("Are you sure to reinitiate this recon transaction ?")
        if(resp == false){
          return;
        }

         reinitiateRecon(this.req({acc_stmt_id: row.id, txn_id : row.stmt_txn_id, amount : amount}))
        .then((response) => {
          this.resp(response)
          if(!response){return };
          if(response.status === "success"){
            alert(response.message);  
            this.handleClick()
        }
        })		
    }

     
    unknown_txn_status = async (statement_search) => {
        this.setState({loader:true,no_data:false,rowdatas:[]})
        await list_lender_txn_stmts(this.req({statement_search}, false, 'statement_search'))
            .then((response) => {
                this.resp(response)
                if(!response)
                {this.setState({loader:false,no_data:true})
                    return null;
                }
                if(response.status === "success"){
                this.setState(state=>state.rowdatas = response.data.account_txns)
                this.setState(state=>state.copy = response.data.account_txns)

                   this.setState({loader:false})
                if(response.data.account_txns.length==0){
                    this.setState({no_data:true})
                }
                if(this.state.txn_type == ''){
                    let column = this.columndatas()
                    column.splice(4, 1)
                    column.splice(5, 0, {
                        headerName : <div className='text-right'>Credit Amount</div>,
                        minWidth: 140,
                        field: 'cr_amt',
                        renderCell: params => {return (lbl_amt(params.value, this.currency_code))},
                        align: 'right',headerAlign: 'center', flex: 1

                    })
                    column.splice(5, 0, {
                        headerName : <div className='text-right'>Debit Amount</div>,
                        minWidth: 140,
                        field: 'dr_amt',
                        align: 'right',headerAlign: 'center', flex: 1,

                        renderCell: params => {return (lbl_amt(params.value, this.currency_code))}
                    })
                    this.setState(state=>state.columndatas=column)
                }else{
                    this.setState(state=>state.columndatas=this.columndatas())
                }
                this.setState({account_result:"show_div"});   
                }else{
                  alert(response.message + "\n" + JSON.stringify(response.data));  
                }
            });
    }
        
    columndatas(){
        let column = [
            {
                headerName : "Stmt Txn Type",
                field: "stmt Txn",
                width:150,
                align: 'center',headerAlign: 'center', resizable: true,
                renderCell:params => {return this.chk_txn_type(params.row)}
            },
            {
                headerName : "Account Number",
                field: "acc_number",
                sortable: false,
                align: 'center',headerAlign: 'center', 

                width:185, resizable: true,	
                renderCell: params => <div className='d-flex align-items-center'> <div><Homeacc_prvdrLogoselector logotype={params.row.acc_prvdr_code} style={{width:'30px',height:'20px'}} /></div>
                                <div style={{paddingLeft:'10px'}}>{params.row.acc_number}</div>
                                 </div>
            },
            {
                headerName : "Stmt Txn Date",
                align: 'center',headerAlign: 'center', 
                width:185, resizable: true,	
                field: 'stmt_txn_date',
                renderCell: params => format_date_tbl(params.value, true)
            }, 
            {
                headerName : "Stmt Txn ID",
                align: 'center',
                headerAlign: 'center', width:190,resizable: true,	
                field: 'stmt_txn_id'
            },
            {
                headerName : <div className='text-right'>Amount</div>,
                align: 'center',
                headerAlign: 'center', 
                width: 130,resizable: true,
                field: (this.state.txn_type==='debit') ? 'dr_amt':'cr_amt',
                renderCell: params => {return rt_algn(lbl_amt(params.value, this.currency_code))}
            },
            {
                headerName  : <div className='text-center'>Description</div>,
                field: 'descr',
                align: 'center',headerAlign: 'center', width:180,
                resizable: true,
                renderCell    : params => <div className='text-center'><TooltipLabel full_text={dd_value(params.value)} no_reduce={true} /></div>
            },
            {
                headerName :<div className='text-center'>Matching ID</div> ,
                minWidth:140,
                width:180, 
                align: 'center',headerAlign: 'center', resizable: true,
                field: 'loan_doc_id',               
                renderCell : params => <div className='text-center'>{this.chk_fa_id(params)}</div>
            },
            {
                headerName : "Matching Status",
                width:190,        
                field : 'recon_status',
                align: 'center',headerAlign: 'center', resizable: true,
                renderCell: params => <TooltipLabel full_text={dd_value(params.row.recon_status)} overlay={params.row.recon_desc} no_reduce={true} />
            },
            {
                headerName  : "Source",
                field: "source",
                width: 90,
                align: 'center',headerAlign: 'center',resizable: true,
                renderCell: params => {return dd_value(params.row.value)}
            },
            {
                headerName : "External Ref ID",
                width:80,
                align: 'center',headerAlign: 'center',resizable: true,

                field: 'external_ref_id'
            },
            {
                headerName : "Action",
                field: "action",
                width:120,
                align: 'center',headerAlign: 'center',resizable: true,
      
                renderCell:params => <ReconStatus show_btn = {true} data={params.row} onSync = {this.handleClick}/>
            },
            {
                headerName : "Action",
                field: "reinitiate",
               
                width: 180, 
                align: 'center',headerAlign: 'center', resizable: true,
                
                
        
                renderCell: params => <> { (params.row.recon_status != "80_recon_done" && params.row.recon_status != "10_capture_payment_pending" ) || (params.row.recon_status != null && (params.row.recon_status != "10_capture_payment_pending" && params.row.loan_doc_id == null)) ?
                                          <input id={params.row.id} stmt_txn_id = {params.row.stmt_txn_id} row = {params.row} amount = {params.row.amount} type="button" value="Recon Again" onClick={(e)=>{this.handleReinitiateRecon(e, params.row)}} className={`btn btn-primary btn-std}`}/>
                                          :
                                          <input disabled = {true} id={params.row.id}  type="button" value="Recon Again" onClick={this.handleReinitiateRecon} className={`btn btn-primary btn-std}`}/>
                                    }
        
                                  </>
              },
        ]
        return column
       
    }

    changeHandler = (e) => {		
        const toFilter = e
        const filterfrom = this.state.rowdatas
        const filteredData = filterfrom.filter(value => {
        return(
            value.stmt_txn_type !==null && value.stmt_txn_type.toLowerCase().includes(toFilter.toLowerCase()) ||
            value.stmt_txn_id !==null && value.stmt_txn_id.includes(toFilter) ||
            value.loan_doc_id !==null && value.loan_doc_id.toLowerCase().includes(toFilter.toLowerCase()) ||
            value.descr !==null && value.descr.toLowerCase().includes(toFilter.toLowerCase())         				
        )
        })
        this.setState(state=>state.copy = filteredData)		
    }


    chk_fa_id(record){

        const loan_doc_id = record.loan_doc_id
        const recon_status = record.recon_status
        const endpoint = find_id(loan_doc_id)

        if(loan_doc_id){
            return <Link to={endpoint+loan_doc_id} style = {{color:"grey", fontSize: '12px', fontWeight: 'bold'}}  target="_blank" >{loan_doc_id}</Link>
        }
        else{
            return "NA"
        } 
    }

    render(){
        return(
              
            <div className='container containerTopmargin max-width no-padding'>
                <h2 className='text-white text-center'>{this.props.unimported === 'true' ? 'Unimported Transactions' : 'Unknown Transactions'}</h2>
                <div className="date-range" style={{padding:15}}>          
                <div className = "form-row">
                    <div className = {`form-group col-md-3`}>
                        <label>Statement Transaction Type   </label>
                        <select id="stmt_txn_type" className = "form-control" value = {this.state.txn_type} onChange={(e) => {this.handleTxnType(e);}} type="text" required="required">
                            {this.getSelectOptions("stmt_txn_type")}
                        </select>
                    </div>
                    <div className = {`form-group col-md-3`}>
                        <label>Start Date   </label><font className="text-danger">   *  </font>
                        <DatePicker defaultValue = {this.props.today === 'true' ? moment(this.props.params.today,  "YYYY-MM-DD") : moment(this.state.from_date,  "YYYY-MM-DD")} closeOnSelect={true}  onChange={(value) => {this.handleDateChange("from_date", value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required" inputProps={{readOnly: true }}/>

                    </div>
                    <div className = {`form-group col-md-3`}>
                        <label>End Date   </label><font className="text-danger">   *  </font>
                        <DatePicker defaultValue = {this.props.today === 'true' ? moment(this.props.params.today,  "YYYY-MM-DD") : moment(this.state.to_date,  "YYYY-MM-DD")} closeOnSelect={true} onChange={(value) => {this.handleDateChange("to_date", value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required" inputProps={{readOnly: true }}/>
                    </div>
                    <div className = {`form-group col-md-3`}>
                        <label>Matching Status   </label>
                        <select id="recon_status" className = "form-control" value = {this.state.recon_status} onChange={(e) => {this.handleReconStatus(e);}} type="text" required="required" >
                            {this.getSelectOptions("recon_status").filter((e) => {
                                if (e.props.value != "80_recon_done"){
                                    return e
                                }
                            })}
                        </select>
                    </div>
                </div>
                </div>
                <div className="form-row justify-content-md-center mt-3">
                    <Button className="btn btn-secondory " onClick = {this.handleClick} >Search</Button> &nbsp;
                    {/*<Button type="reset" onClick={this.handleResetClick}>Reset</Button>*/}
                </div>
                
              {(this.state.rowdatas.length !== 0) && 
              <>
                <div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
                    <p className='no-margin text-white'><b>Total Entries : {this.state.copy.length}</b></p>
                    <div style={{position:'relative'}}>
                        <i class="fa-solid fa-magnifying-glass search_pos"></i>
                        <input placeholder='Search here' className='search_posInput' onChange={(e)=>this.changeHandler(e.target.value)}></input>
                        {/* <button className='btn-std btn btn-primary' onClick={()=>this.resetHandler()}>Reset</button> */}
                      </div>
                </div>
                    {/* <CommonReactTable showPag={true} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} resizable={false}  exportCSV={true} csvFile={"unknown_txns"} csvData={this.state.copy ? this.state.copy : []} searchData={[{txn_type:this.state.txn_type,from_date:this.state.from_date,to_date:this.state.to_date,title:'Unknown Transaction'}]}/>    */}

                    {/* <BasicReactTable row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columndatas} FName={"unknown_txns"} csv_file={true} pagination={true} default_page_size = {15} searchData={[{txn_type:this.state.txn_type,from_date:this.state.from_date,to_date:this.state.to_date,title:'Unknown Transaction'}]}  />  	 
                                                          */}
                    <Box display={'flex'} justifyContent={'end'}>
									<ExportCsv
									total_count={this.state.copy?.length ?? 0} 
									// handleExport={this.handleExportCSV} 
									export_csv_datas={ this.state.copy }
									csvFileName = {'Unknown Txns'}
									paginationMode= { "client"}
                                    columns={this.state.columndatas??[]}

									/>
					</Box>
                    <DataGridTable
                    rows={this.state.copy??[] }
                    columns={this.state.columndatas??[] }
                    paginationMode="client"
                    sortingMode="client"
                    rowCount={this.state.copy?.length  ?? 0}
                    page={this.state.page}
                    setpage={(newPage) => this.setState({ page: newPage })}
                    isLoading={undefined}
                    isError={false}
                    errorText=""
                    noOfColumnsToLoad={10}
                    columnVisibility={{
                        reinitiate :check_priv('recon', 'reinitiate_recon'),
                        traderName: true,  
                }}
                    
                />
              </>
              }
              {this.state.no_data&&<h6 className='text-center text-light p-5 m-5'>No Data Found</h6>}
        {this.state.rowdatas.length==0&&this.state.loader&&<>
          <p className='text-light mt-3 text-center'>Loading...</p>
          <div className="product_takeup_loader"></div>
        </>}
        </div> 
        )
    }
  
}
export default withRouter(UnknownTransactions) ;