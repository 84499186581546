import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { AiOutlineIdcard,AiFillEdit} from "react-icons/ai";

import FileUpload from '../../common/component/FileUpload';
import {get, Config} from '../../../helpers/storage_helper';
import {capitalize_first_letter,getLabel,get_dir_path,rekyc_modify_state_handler} from '../../../helpers/common_helper';
import DisplayImage from '../../common/component/DisplayImage';

import {dd_value} from '../../../actions/common_actions';

import moment from 'moment';
import PDFtoImage from './Pdf_to_image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {RoundOffSplitter} from '../../../helpers/common_helper';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';


class RekycAgreementandDataConsent extends FlowComponent{
  constructor() {
    super();
    this.state={
      cust_reg_json:'',
      pdf_view_error_old_json:true,
      pdf_view_error_new_json:true,
      pdf_view_old_json_pdfMOdal:false,
      pdf_view_new_json_pdfMOdal:false,
      pdf_view_data_consent_pdfModal:false
    }
  
    this.customer_name=["first_name","last_name"]
}
    
componentDidUpdate(prevProps, prevState, snapshot){
  if(this.props.Datas.agreements_verify_status &&( this.state.cust_reg_json!=prevProps.Datas.cust_reg_json.agreements)){
    
    return this.setState({cust_reg_json:prevProps.Datas.cust_reg_json.agreements})

  }
 
}

    audit_section_spinner(){
        return(
          <div class="audit_spinner d-flex justify-content-center" style={{margin:'20vh 0px'}}>
          <span class="level level-1"></span>
          <span class="level level-2"></span>
          <span class="level level-3"></span>
          <span class="level level-4"></span>
          <span class="level level-5"></span>
          <span class="level level-6"></span>
        </div>
        )
      }

      checkbox_spinner(){
        return(
          <div class="d-flex col-lg-10 py-2 px-5 mt-4 justify-content-center ml-5">
          <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
         </div>
        )
      }
      Ismobile=()=>{
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);
          if (isMobileDevice) {
            return true
          } 
          else {
            return false
          }
      }

      rekyc_modify_checking(json_arr,type=null){
        let a=false
       
       

          for(let i=0;i<json_arr.length;i++){
            if( type==null){
            if(((this.props.Datas.new_cust_reg_json.gps_address.biz_address[json_arr[i]].hasOwnProperty("modified") && (this.props.Datas.new_cust_reg_json.gps_address.biz_address[json_arr[i]].modified))||
            (this.props.Datas.new_cust_reg_json.gps_address.biz_address[json_arr[i]].hasOwnProperty("collected_n_modified") && (this.props.Datas.new_cust_reg_json.gps_address.biz_address[json_arr[i]].collected_n_modified)))){
              return true
            }
          }
          if(type!=null){
            if(((this.props.Datas.new_cust_reg_json.id_proof[json_arr[i]].hasOwnProperty("modified") && (this.props.Datas.new_cust_reg_json.id_proof[json_arr[i]].modified))||
            (this.props.Datas.new_cust_reg_json.id_proof[json_arr[i]].hasOwnProperty("collected_n_modified") && (this.props.Datas.new_cust_reg_json.id_proof[json_arr[i]].collected_n_modified)))){
              return true
            }
          }


          }


        
         
      }

      handleModalClose(state, type){
        this.setState({[state]: type=="open" ? true :false})
      }

      get_class_name(cust_reg_json,json_type,json_key,key,addl_key=null,idx=null){
        if(json_type=="collected_modified"){
          return " COLLECTED_MODIFIED";
      
         }else if(json_type == "collected"){
          return "Re-KYCed"
         }
         
         else{
          return "MODIFIED"
         }
        }

        pdf_viewer(link,type){

          const url = link;
          fetch(url, { method: 'HEAD' })
          .then(response => {
          const contentType = response.headers.get('Content-Type');
      
          if (contentType === 'application/pdf') {
            if(type=="old_cust_reg_json"){
              this.setState({pdf_view_error_old_json:true})
            }else if(type=="new_cust_reg_json"){
              this.setState({pdf_view_error_new_json:true})
            }
            console.log('The URL points to a PDF file.');
          } else {
            if(type=="old_cust_reg_json"){
              this.setState({pdf_view_error_old_json:false})
            }else if(type=="new_cust_reg_json"){
              this.setState({pdf_view_error_new_json:false})
            }
      
            console.log('The URL does not point to a PDF file.');
          }
        })
        .catch(error => {
          console.log('An error occurred:', error);
          // this.setState({pdf_view_error_old_json:false,pdf_view_error_new_json:false})
      
        });    
          }
          
      Pdf_modal(props) {


        return (
          <>
    
            <div  className="w-100 h-100 p-0  " style={{position:'relative'}}  >
            {( (props.type=="old_cust_reg_json"? props.pdf_view_error_old_json : props.pdf_view_error_new_json))?    
               <object type='application/pdf' className='bg-secondary' data={`${props.root}${props.modal_title}?#toolbar=0&navpanes=0&page=1`}  onLoad={()=>props.pdf_viewer(`${props.root}${props.modal_title}`,props.type)} width={'100%'} height={'100%'} allowtransparency='false'> 
                {/* {props.device&&<a href={`${props.root}${props.modal_title}`} className='btn btn-primary w-100 d-block'>View Pdf </a>} */}
                {props.device&& <PDFtoImage pdfUrl={`${props.root}${props.modal_title}`}/>}
                <p className='text-white float_acc_balance_view_bttn'>NO DATA </p>
               </object> 
               :
               <div class="d-flex align-items-center">
                <p className='text-center text-white float_acc_balance_view_bttn' >NO DATA</p>
               </div> 
               }
            </div>
            {/* <div className={`w-100 h-100 p-0 ${props.device?"":'position_ab'}`} style={{top:0}} data-toggle={`${props.device?"":'modal'}`} data-target={`.${props.type}`}></div> */}
            
            {/* <div className={`modal fade ${props.type} mt-0`} tabIndex="-1" role="dialog" aria-l  abelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg d-flex justify-content-center">
                <div className="modal-content" style={{width:'60vw'}}>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{width:'60vw'}}>
                  <object type='application/pdf' className='forpdf'  data={`${props.root}${props.modal_title}?#toolbar=0&navpanes=0&page=1`} width={'100%'} height={'485vh'} allowtransparency='false'>
                  {props.device&& <PDFtoImage pdfUrl={`${props.root}${props.modal_title}`}/>}
                  </object>
                  </div>
                  <div className="modal-footer d-flex">
                  <a href={`${props.root}${props.modal_title}`} className='btn btn-success btn-sm my-0 mx-3' target="_blank">Download Pdf</a>
                    <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Close</button>
                    
                  </div>
                </div>
              </div>
            </div> */}

            <Modal show={props.pdfMOdal} onHide={()=>props.handleModalClose(props.pdfMOdal, "close")} size = 'lg' >
                     <div className="row d-flex justify-content-center align-items-center ml-0">
                     <h5 className="modal-title text-center mb-0 mt-5 " id="">{props.title}</h5>

                    </div>
                    <Modal.Body >
                    <div className="modal-body py-0" >
                    <object type='application/pdf' className='forpdf'  data={`${props.root}${props.modal_title}?#toolbar=0&navpanes=0&page=1`} width={'100%'} height={'485vh'} allowtransparency='false'>
                  {props.device&& <PDFtoImage pdfUrl={`${props.root}${props.modal_title}`}/>}
                  </object>
                </div>
                <div className="modal-footer d-flex">
                <a href={`${props.root}${props.modal_title}`} className='btn btn-success btn-sm my-0 mx-3' target="_blank">Download Pdf</a>
                  <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal" onClick={()=>props.handleModalClose(props.pdfMOdal, "close")}>Close</button>
                  
                </div>
                    </Modal.Body>
            </Modal>
    
          </>
        );
    }

    
    render(){
      const audit_kyc = this.props.audit_kyc && this.props.audit_kyc[this.props.Datas.active_key]
      let address_fields = Config('addr_hiearchy')?.[get('market').country_code] ?? [];
      const show_witness_sign = this.props.Datas?.cust_reg_json?.show_witness_sign

        return(
            <div>
            {this.props.Datas.audit_kyc_wizard_menus.data_consent &&
             <div className='row no-margin justify-content-center'>
             {this.props.Datas.View_lead_section_loader ? <div className="d-flex justify-content-center"> {this.audit_section_spinner()}</div>:
              <>
             <div className='col-lg-12 py-3 mt-5 ' style={{background:' #01062c'}}>
               <h2 className='text-white text-center'>
               <b> {`${this.props.Datas.cust_reg_json.same_as_owner_person ? "7" :"8"} -  Data Consent Verification`}</b> 

                
               </h2>
               <h2 className=' d-flex mt-1 text-center justify-content-center align-items-center' ><img src='/img/pending_with_rm.png' height={'50'}/> <b className='ml-2 align-self-cente' style={{color:'#F3EA0B'}}> Pending with {this.props.Datas.active_section && (this.props.Datas.active_section.pending_with=="rm"?'RM':'YOU')}</b></h2>

              </div>
              <div className='col-lg-12 py-2 mt-5 old_json_text_bg_color' >
               <h2 className='text-white text-center'>
               <b>Business Details (As per FLOW)</b>  
               </h2>
              </div>

              {(this.props.Datas.cust_reg_json.data_consent) &&
              <> 
              <>
             
             

            
              <div className='col-sm-6 p-2 mt-4'>
                {this.props.Datas.cust_reg_json.data_consent&&
               <div className='m-2 text-light old_json_text_bg_color h-100 w-100' style={{background:'#202940'}} >
 
                 {(this.props.Datas.cust_reg_json.data_consent) &&
                 <div style={{height:'100%'}} onClick={()=>{!this.state.pdf_view_data_consent_pdfModal ?  this.handleModalClose('pdf_view_data_consent_pdfModal','open'):null}}>
                   {/* <this.Pdf_modal title={"Data Consent PDF"}  root={'http://127.0.0.1:8005'} device={this.props.Ismobile()} modal_title={'/files/UGA/borrowers/UFLW-256257753/agreement/pdf/AGRT-UFLW-231106125443-256257753.pdf?#toolbar=0&navpanes=0&page=1'} pdf_view_error={this.state.pdf_view_error} pdf_viewer={(a)=>{this.pdf_viewer(a)}} pdfMOdal={this.state.pdfMOdal}  handleModalClose ={(type)=>{this.handleModalClose(type)}}/> */}

                  <this.Pdf_modal  title={"Data Consent PDF"} root={this.props.Datas.root} device={this.Ismobile()} pdf_view_error_old_json={this.state.pdf_view_error_old_json} pdf_viewer={(a,b)=>{this.pdf_viewer(a,b)}} type={"old_cust_reg_json"} modal_title= {this.props.Datas.cust_reg_json.data_consent.signed_consent_path} pdfMOdal={this.state.pdf_view_data_consent_pdfModal}  handleModalClose ={(type)=>{this.handleModalClose('pdf_view_data_consent_pdfModal',type)}}/>

                  </div>
                  }
                  {this.props.Datas.active_section &&
                   <div>
                       {this.props.Datas.active_section.verify &&
                         <div className="closedStamp ">
                         <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                         </div>   
                       }
                   </div>
                  }
                 
                 
                
               </div>
    }
              </div>
              <div className='col-sm-6 p-2 mt-4'>
               <div className='m-2 text-light old_json_text_bg_color h-100 w-100  p-5 mb-5' style={{background:'#202940'}} >
 
                  
               {/* <p className='no-margin text-white  business_operations_text'>  BUSINESS NAME  </p>
               <h2 className='text-white audit_kyc_text'> <b>{ capitalize_first_letter(this.props.Datas.cust_reg_json.license_proof.biz_name.value)}</b></h2>
                    */}
               <p className='  no-margin text-white  business_operations_text pt-4'>CUSTOMER NAME</p>
               <h2 className='text-white audit_kyc_text bold sizel  '>{capitalize_first_letter(this.props.Datas.cust_reg_json.id_proof.first_name.value)}&nbsp;{capitalize_first_letter(this.props.Datas.cust_reg_json.id_proof.last_name.value)}</h2>
              
               <p className='no-margin text-white   business_operations_text pt-4'>MOBILE NUMBER</p>
               <h2 className=' bold sizel  text-white audit_kyc_text'>{this.props.Datas.cust_reg_json.mobile_numbers.mobile_num.value} </h2>
              
               {/* <p className=' no-margin text-white   business_operations_text pt-4 '>CONSENT DATE</p>
               <h2 className='text-white audit_kyc_text bold  sizel ' > {  this.props.Datas.cust_reg_json.data_consent.consent_signed_date ?moment(this.props.Datas.cust_reg_json.data_consent.consent_signed_date).format("DD-MMM-YYYY"):"-" }</h2>
               */}
               <p className=' no-margin text-white   business_operations_text pt-4 pb-3 '>SIGNATURE REFERENCE</p>
               <DisplayImage image_path={get_dir_path(this.props.Datas.cust_reg_json.id_proof.photo_id_proof_full_path.value,this.props.Datas.cust_reg_json.id_proof.photo_id_proof.value)} style={{height: '350px',width:'100%'}} file_name={this.props.Datas.cust_reg_json.id_proof.photo_id_proof.value} />

              </div>
              </div>
              
             {this.props.Datas.active_section&&
             <>
               
              <form className={`col-sm-12 mt-3 mb-3 ${(this.props.Datas.active_section.verify || (this.props.Datas.active_section.to_reassign==true) )? "disable_div":''}   ${ (((this.props.Datas.responseData.audited_by== get("user_id")) || (this.props.Datas.responseData.audited_by==null ))&&(this.props.Datas.active_section.pending_with !="rm")) ?"":'disable_div'}`}>
                     <div className='d-flex align-items-center'>
                     <input type="checkbox" id="vehicle1" className='pointer' style={{height:'19px',width:'20px'}} checked={audit_kyc && audit_kyc.consent_form_data_match && true} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"consent_form_data_match")} />
                     <label className='no-margin pl-3 audit_kyc_text pointer' for="vehicle1">The business name, customer name, mobile number and consent date matches with the data consent form </label><br/><br/>
                     </div>
                     {audit_kyc && audit_kyc.hasOwnProperty("signature_match") &&
                     <div className='d-flex align-items-center'>
                     <input type="checkbox" id="vehicle2" className='pointer' style={{height:'19px',width:'20px'}} checked={audit_kyc && audit_kyc.signature_match && true} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"signature_match")} />
                     <label className='no-margin pl-3 audit_kyc_text pointer' for="vehicle2">{`The signature of the customer in the data consent form matches with the signature in the ${getLabel('national_id')}`}</label><br/><br/>
                     </div>
                     }
                 </form>
                 
             </>}
             </>
             </>}
             </>}
             </div>
            }

            {this.props.Datas.audit_kyc_wizard_menus.agreements &&
             <div className='row no-margin justify-content-center'>
            {this.props.Datas.View_lead_section_loader ?  <div className='d-flex align-items-center justify-content-center'>{this.audit_section_spinner()}</div>:
            <> {(this.props.Datas.responseData.kyc_reason=="new_account_addn" || (this.props.Datas.responseData.kyc_reason=="upload_document")) ?
             <div className='col-lg-12 p-0'>
               <div className='col-lg-12 py-3 mt-5 ' style={{background:' #030c2c'}}>
               <h2 className='text-white text-center'>
                 <b> {`${this.props.Datas.cust_reg_json.same_as_owner_person ? "8" :"9"} -  Agreement Verification`}</b> 

               </h2>
               <h2 className=' d-flex mt-1 text-center justify-content-center align-items-center' ><img src='/img/pending_with_rm.png' height={'50'}/> <b className='ml-2 align-self-cente' style={{color:'#F3EA0B'}}> Pending with {this.props.Datas.new_cust_reg_json&& (this.props.Datas.new_cust_reg_json.agreements[0].pending_with =="rm"?'RM':'YOU')}</b></h2>

              </div>
              <div className='col-lg-12 py-2 mt-5 old_json_text_bg_color' >
               <h2 className='text-white text-center'>
               <b>Business Details (As per FLOW)</b>  
               </h2>
              </div>
              <div className='row justify-content-center   m-0'>
            

              <div className='col-sm-6 col-md-12 my-5 col-lg-6 p-0   ' style={{border:'2px solid #37b8d8',height:'1064px'}}>
              <div style={{height:'100%'}} onClick={()=>{!this.state.pdf_view_new_json_pdfMOdal ?  this.handleModalClose('pdf_view_new_json_pdfMOdal','open'):null}}>
              <this.Pdf_modal title={"Agreement PDF"} root={this.props.Datas.root} device={this.Ismobile()} modal_title={this.props.Datas.new_cust_reg_json.agreements[0].aggr_file_path}  pdf_view_error_new_json={this.state.pdf_view_error_new_json}  pdf_viewer={(a,b)=>{this.pdf_viewer(a,b)}} type={"new_cust_reg_json"}  pdfMOdal={this.state.pdf_view_new_json_pdfMOdal}  handleModalClose ={(type)=>{this.handleModalClose(type)}}/>

              </div>

                  {this.props.Datas.active_section &&
                   <div>
                       {this.props.Datas.active_section[0].verify &&
                         <div className="closedStamp ">
                         <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                         </div>   
                       }
                   </div>
                  }
               </div>
              <div className='col-sm-6 col-md-12 my-5 col-lg-6 p-0 '  style={{background:'#202940',border:'1px solid #37b8d8'}} >

               <div className=' text-light  h-100 w-100  px-4 pt-3' >
                {/* <div className={` my-3 py-2 `}>
               <p className='text-white  business_operations_text mt-3 '>BUSINESS NAME</p>
               <h2 className='text-light bold sizel mb-2 pb-1'>{capitalize_first_letter(this.props.Datas.old_cust_reg_json.biz_license.biz_name.value)}</h2>
               </div> */}
               <div className={ `  my-3 py-2`}>
               <p className='  text-white  business_operations_text '>CUSTOMER NAME</p>
               <h2 className='text-light bold sizel mb-2'>{capitalize_first_letter(this.props.Datas.old_cust_reg_json.id_proof.first_name.value)}&nbsp;{capitalize_first_letter(this.props.Datas.old_cust_reg_json.id_proof.last_name.value)}</h2>
               </div>
               <div >
               <p className=' text-white   business_operations_text '>FLOW CUST ID</p>
               <h2  className='text-light bold sizel mb-2 pb-1'>{this.props.Datas.old_cust_reg_json.cust_id}</h2>
               </div>
               <div className={`  my-3 py-2  `}>
               <p className='  text-white   business_operations_text '>ID PROOF NUM</p>
               <h2 className='text-light bold sizel mb-2 pb-1'>{this.props.Datas.old_cust_reg_json.id_proof.id_proof_num.value}</h2>
               </div>
               <div className={` my-3 py-2  `}>
               <p className=' text-white   business_operations_text '>MOBILE NUM</p>
               <h2 className='text-light bold sizel mb-2'>{this.props.Datas.old_cust_reg_json.mobile_numbers.mobile_num.value} </h2>
               </div>
               <div className={` my-3 py-2 `}>
              
                      
               <p className=' text-white   business_operations_text '>ADDRESS</p>

             <h2 className='text-light bold sizel mb-2 pb-1'>
                {address_fields.map((field, index) => {
                  const value = this.props.Datas.old_cust_reg_json.gps_address.biz_address[field]?.value;
                  return value ? dd_value(capitalize_first_letter(value)) + (field !== 'village' ? ',' : '') : null;
                })}
              </h2>             
               
              
               </div>
               {/* <div className='my-3 py-2'>
               <p className=' text-white  business_operations_text '>Date</p>
               <h2 className='text-light bold  sizel mb-2 '> {  moment(this.props.Datas.old_cust_reg_json.agreements[0].aggr_signed_date).format("DD-MMM-YYYY")  }</h2>
               </div> */}
              
              </div>
               </div>
             
            </div>

            {this.props.Datas.active_section && 
             <div className='col-lg-12'>
             <div className='row'>
              <div className='col-6'></div>
              <div className='col-6'>
             
              <form className={`col-sm-12 mt-3 p-0 mb-3 ${(this.props.Datas.active_section[0].verify || (this.props.Datas.active_section[0].to_reassign==true) )? "disable_div":''}   ${ (((this.props.Datas.responseData.audited_by== get("user_id")) || (this.props.Datas.responseData.audited_by==null )) &&this.props.Datas.active_section[0].pending_with !="rm") ?"":'disable_div'}`}> 
                   

                     <div className='d-flex '>
                     <input type="checkbox" id="vehicle1" className='pointer mt-1' style={{height:'19px',width:'34px'}} checked={audit_kyc && audit_kyc.agreement_data_match && true} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"agreement_data_match")} />
                     <label className='pointer no-margin pl-3 audit_kyc_text' for="vehicle1" style={{lineHeight:'25px'}}>{`The business name, customer name, flow cust ID, ${getLabel('national_id')}, mobile number, address and date matches with the agreement form.`}</label><br/><br/>
                     </div>
 
                     {audit_kyc && audit_kyc.hasOwnProperty("signature_match") &&
                     <div className='d-flex my-3 '>
                     <input type="checkbox" id="vehicle2" className='pointer mt-1' style={{height:'19px',width:'25px'}} checked={audit_kyc && audit_kyc.signature_match && true} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"signature_match")} />
                     <label className='pointer no-margin pl-3 audit_kyc_text' for="vehicle2" style={{lineHeight:'25px'}}>{`The signature of the customer in the Float Advance Agreement matches with the signature in the ${getLabel('national_id')}`}</label><br/><br/>
                     </div>
                     }
                    {audit_kyc && audit_kyc.hasOwnProperty("witness_contact_verified") && show_witness_sign &&
                     <div className='d-flex'>
                     <input type="checkbox" id="vehicle3" style={{height:'19px',width:'25px'}} className='pointer mt-1' checked={audit_kyc && audit_kyc.witness_contact_verified && true} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"witness_contact_verified")} />
                     <label className='pointer no-margin pl-3 audit_kyc_text' for="vehicle3" style={{lineHeight:'25px'}}>The witness contact verified matches the name input by RM</label><br/><br/>
                     </div>
                     }
 
                 </form>
               
              </div>

             </div>
             
               </div>
               }
            </div>
            
            :
              
              <>
             <div className='col-lg-12 py-3 mt-5 ' style={{background:' #030c2c'}}>
               <h2 className='text-white text-center'>
                 <b> {`${this.props.Datas.cust_reg_json.same_as_owner_person ? "9" :"10"} -  Agreement Verification`}</b> 

               </h2>
               <h2 className=' d-flex mt-1 text-center justify-content-center align-items-center' ><img src='/img/pending_with_rm.png' height={'50'}/> <b className='ml-2 align-self-cente' style={{color:'#F3EA0B'}}> Pending with {this.props.Datas.new_cust_reg_json&& (this.props.Datas.new_cust_reg_json.agreements[0].pending_with =="rm"?'RM':'YOU')}</b></h2>

              </div>
        
 
              <>
            

               <div className='row col-lg-12 w-100' >

                <div className='col-lg-6 col-md-12 old_json_text_bg_color p-0' style={{background:'#202940'}}>

                <div className='col-sm-6 col-md-12  col-lg-12 p-0' style={{height:'1076px'}}>
                <h2 className='text-white p-2 py-2  mb-3 old_json_text_bg_color text-center '> <b>As Per KYC</b></h2>

               <div className=' text-light pt-5  h-100 w-100' >
 
                 {this.props.Datas.cust_reg_json&&
 
            <div style={{height:'100%'}} onClick={()=>{!this.state.pdf_view_old_json_pdfMOdal ?  this.handleModalClose('pdf_view_old_json_pdfMOdal','open'):null}}>

                  <this.Pdf_modal title={"Agreement PDF"} root={this.props.Datas.root} device={this.Ismobile()} modal_title={this.props.Datas.old_cust_reg_json.agreements[0].aggr_file_path} pdf_view_error_old_json={this.state.pdf_view_error_old_json} pdf_viewer={(a,b)=>{this.pdf_viewer(a,b)}} type={"old_cust_reg_json"} pdfMOdal={this.state.pdf_view_old_json_pdfMOdal}  handleModalClose ={(type)=>{this.handleModalClose('pdf_view_old_json_pdfMOdal', type)}}/>
                  </div>
                 }

                  {this.props.Datas.active_section &&
                   <div>
                       {this.props.Datas.active_section[0].verify &&
                         <div className="closedStamp ">
                         <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                         </div>   
                       }
                   </div>
                  }
                 
                 
                
               </div>
               </div>
               <div className='col-sm-6 col-md-12 my-5 col-lg-12 p-0  ' >
               <div className=' text-light  h-100 w-100  px-4 pt-3'  style={{background:'#202940'}}>
                <div className={` my-3 py-2 `}>
               {/* <p className='text-white  business_operations_text mt-3 '>BUSINESS NAME</p>
               <h2 className='text-light bold sizel mb-2 pb-1'>{capitalize_first_letter(this.props.Datas.old_cust_reg_json.license_proof.biz_name.value)}</h2> */}
               </div>
               <div className={ `  my-3 py-2`}>
               <p className='  text-white  business_operations_text '>CUSTOMER NAME</p>
               <h2 className='text-light bold sizel mb-2'>{capitalize_first_letter(this.props.Datas.old_cust_reg_json.id_proof.first_name.value)}&nbsp;{capitalize_first_letter(this.props.Datas.old_cust_reg_json.id_proof.last_name.value)}</h2>
               </div>
               <div >
               <p className=' text-white   business_operations_text '>FLOW CUST ID</p>
               <h2  className='text-light bold sizel mb-2 pb-1'>{this.props.Datas.old_cust_reg_json.cust_id}</h2>
               </div>
               <div className={`  my-3 py-2  `}>
               <p className='  text-white   business_operations_text '>ID PROOF NUM</p>
               <h2 className='text-light bold sizel mb-2 pb-1'>{this.props.Datas.old_cust_reg_json.id_proof.id_proof_num.value}</h2>
               </div>
               <div className={` my-3 py-2  `}>
               <p className=' text-white   business_operations_text '>MOBILE NUM</p>
               <h2 className='text-light bold sizel mb-2'>{this.props.Datas.old_cust_reg_json.mobile_numbers.mobile_num.value} </h2>
               </div>
               <div className={` my-3 py-2 `}>
              
                      
               <p className=' text-white   business_operations_text '>ADDRESS</p>
             <h2 className='text-light bold sizel mb-2 pb-1'>
               {address_fields.map((field, index) => {
              const value = this.props.Datas.old_cust_reg_json?.gps_address?.biz_address[field]?.value;
              return value ? dd_value(capitalize_first_letter(value)) + (field !== 'village' ? ',' : '') : null;
            })}
                </h2>
        
              
               </div>
               {/* <div className='my-3 py-2'>
               <p className=' text-white  business_operations_text '>Date</p>
               <h2 className='text-light bold  sizel mb-2 '> {  moment(this.props.Datas.old_cust_reg_json.agreements[0].aggr_signed_date).format("DD-MMM-YYYY")  }</h2>
               </div> */}
              
              </div>
               </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                <h2 className='text-white p-2 px-3 ml-2 mb-3 new_re_kyc_text_bg_color text-center'> <b>  As Per Re-KYC</b></h2>

                <fieldset className= {`field_set Re-KYCed_field_set` } >
                <legend className={`legend_rekyc  Re-KYCed_text_color`}> 
                <b>Re-KYCed</b></legend> 

             
               <div className='col-sm-6 col-md-12 col-lg-12 p-2'style={{height:'1022px'}} >

               <div className='m-2 text-light  h-100 w-100'  >
 
                 {this.props.Datas.cust_reg_json&&
 
                    <div style={{height:'100%'}} onClick={()=>{!this.state.pdf_view_old_json_pdfMOdal ?  this.handleModalClose('pdf_view_old_json_pdfMOdal', 'open'):null}}>
 
                  <this.Pdf_modal title={"Agreement PDF"} root={this.props.Datas.root} device={this.Ismobile()} modal_title={this.props.Datas.new_cust_reg_json.agreements[0].aggr_file_path}  pdf_view_error_new_json={this.state.pdf_view_error_new_json} pdf_viewer={(a)=>{this.pdf_viewer(a)}} type={"new_cust_reg_json"} pdfMOdal={this.state.pdf_view_old_json_pdfMOdal}  handleModalClose ={(type)=>{this.handleModalClose('pdf_view_old_json_pdfMOdal',type)}}/>
                </div>
                 }
                  {this.props.Datas.active_section &&
                   <div>
                       {this.props.Datas.active_section.verify &&
                         <div className="closedStamp ">
                         <div className="stamp is-nope audit_kyc_verified_stamp">Verified</div>
                         </div>   
                       }
                   </div>
                  }
                 
                 
                
               </div>
               </div>
               <div className='col-sm-6 col-md-12 my-5 col-lg-12 p-0  ' >
               <div className=' text-light  h-100 w-100  px-4 pt-3'  style={{background:'#202940'}}>
                {/* <div className={`my-3 py-2  `}> */}
                {/* {this.props.rekyc_modify_state_handler(this.props.Datas,"new_cust_reg_json","license_proof","biz_name",null,null,`license_proof.biz_name`,"agreement_format")}  */}

               {/* <p className='text-white  business_operations_text mt-3 '>BUSINESS NAME</p>
               <h2 className='text-light bold sizel mb-2 pb-1'>{capitalize_first_letter(this.props.Datas.cust_reg_json.license_proof.biz_name.value)}</h2> */}
             
               <div className={`my-3 py-2  `}>
               <fieldset className= {`${this.rekyc_modify_checking(this.customer_name,"cust_name") ? ` px-3 modified_fieldset`:" " } ` } style={{background:'#202940'}}>
                      {(this.rekyc_modify_checking(this.customer_name,"cust_name") ) ?
                      <legend className={`legend_rekyc MODIFIED_text_color `}> 
                      <b> MODIFIED </b></legend> :"" }
               <p className='  text-white  business_operations_text '>CUSTOMER NAME</p>
               <h2 className='text-light bold sizel mb-2'>{capitalize_first_letter(this.props.Datas.cust_reg_json.id_proof.first_name.value)}&nbsp;{capitalize_first_letter(this.props.Datas.cust_reg_json.id_proof.last_name.value)}</h2>
             </fieldset>
               </div>
               <div className='my-3'>
               <p className=' text-white   business_operations_text '>FLOW CUST ID</p>
               <h2  className='text-light bold sizel mb-2 pb-1'>{this.props.Datas.cust_reg_json.cust_id}</h2>
               </div>
               <div className={`my-3 py-2  `}>
               {this.props.rekyc_modify_state_handler(this.props.Datas,"new_cust_reg_json","id_proof","id_proof_num",null,null,`id_proof.id_proof_num`,"agreement_format")} 

               {/* <p className='  text-white   business_operations_text '>NATIONAL ID</p>

               <h2 className='text-light bold sizel mb-2 pb-1'>{this.props.Datas.cust_reg_json.id_proof.id_proof.value}</h2> */}
               </div>
               <div className={`my-3 py-2  `}>
               {/* <p className=' text-white   business_operations_text '>MOBILE NUMBER</p> */}
               {this.props.rekyc_modify_state_handler(this.props.Datas,"new_cust_reg_json","mobile_numbers","mobile_num",null,null,`mobile_numbers.mobile_num`,"agreement_format")} 

               {/* <h2 className='text-light bold sizel mb-2'>{this.props.Datas.cust_reg_json.mobile_numbers.mobile_num.value} </h2> */}
               </div>

               <div className={`my-3  py-2 `}>
               <fieldset className= {`px-3 ${this.rekyc_modify_checking(address_fields) ? ` modified_fieldset`:" " } ` } style={{background:'#202940'}}>
                      { this.rekyc_modify_checking(address_fields) ?
                      <legend className={`legend_rekyc MODIFIED_text_color d-flex `} style={{color:"#FF4C29",fontSize:'16px'}}> 
                         <b className='mr-3'>MODIFIED </b>
                          <RoundOffSplitter val={"During the ReKYC process the RM has observed that this data field needs to be collected.This is found to be different from the data that already exists in FLOW's  KYC fields"}  unit={""} align_center={true} position={"top"} notify={true}/>     </legend>:""}
               <p className=' text-white   business_operations_text '>ADDRESS</p>
             
             <h2 className='text-light bold sizel mb-2 pb-1'>
              {address_fields.map((field, index) => {
              const value = this.props.Datas.new_cust_reg_json.gps_address.biz_address[field]?.value;
              return value ? dd_value(capitalize_first_letter(value)) + (field !== 'village' ? ',' : '') : null;
            })}
                </h2>
            
               </fieldset>
               </div>
               <div className='my-3  py-2 '>
               <p className=' text-white  business_operations_text '>Date</p>
               <h2 className='text-light bold  sizel mb-2 '> {  moment(this.props.Datas.cust_reg_json.agreements[0].aggr_signed_date).format("DD-MMM-YYYY")  }</h2>
               </div>
                <div className={`my-3  py-2`}>
               <p className=' no-margin text-white   business_operations_text '>SIGNATURE REFERENCE</p>
               <DisplayImage image_path={get_dir_path(this.props.Datas.cust_reg_json.id_proof.photo_id_proof_full_path.value,this.props.Datas.cust_reg_json.id_proof.photo_id_proof.value)} style={{height: '350px',width:'100%'}} file_name={this.props.Datas.cust_reg_json.id_proof.photo_id_proof.value} />
               </div>
               {show_witness_sign &&
               <div className={`my-3  py-2`}>

               {(!this.props.Datas.agreements_verify_status ? this.props.Datas.cust_reg_json.agreements :(this.state.cust_reg_json.length>0 ? this.state.cust_reg_json:[])).map((item,idx)=>{return(
              
              <div className='third_party_bg'>
              <div className='col-lg-12 py-3 mt-5 ' style={{background:' #01062c'}}>
               <h2 className='text-white text-center'>
               <b> { `Witness Mobile Number Verification - ${idx+1}` } </b> 
               </h2>
             </div>
            <div className={`d-flex pt-3 third_party_bg no-margin mb-4 justify-content-around row `}>
              <div className='col-md-12  mt-3 mb-3 col-lg-12' >
              <div className='h-100' style={{background: '#E4E7E9',minHeight:'295px'}}>
                <p  className="no-margin audit_kyc_text py-3 px-4" style={{color:'black'}}>Name as entered by RM</p><hr className='no-margin' style={{border:'1px solid black'}}/>
                <div className='p-3 '>

                <p  className="no-margin py-2 mt-2   px-3 pt-3 " style={{fontSize:'14px',color:'black'}}>{` WITNESS NAME (as per ${getLabel('national_id')})`}</p>
                <h4 className='my-1 px-3  audit_kyc_text mb-5  text_dark' style={{fontSize:'26px'}}><b>{capitalize_first_letter(item.witness_name)} </b> </h4>
                
                {/* <p  className="no-margin pt-3  px-3 audit_kyc_text " style={{fontSize:'14px',color:'black'}}>BUSINESS NAME (as per Biz License)</p>
                <h4 className='my-1 px-3 py-2 mb-4' style={{color:'black',fontSize:'26px'}}><b >{capitalize_first_letter(this.props.Datas.cust_reg_json.license_proof.biz_name.value)}</b> </h4> */}
                </div>
                
                </div>

              </div>
              
              <div className='col-md-6 mt-3 mb-3 col-lg-2' style={{alignSelf:'center'}}>
              {((!item.hasOwnProperty('holder_name'))) ? "" :  
              <div className='px-2 py-3' style={{background:` ${item.name_match ? '#22cd07':'red'}`,borderRadius:'30px'}}>
                <p className='no-margin text-white  text-center audit_kyc_text '> <b style={{fontSize:'25px'}}> { item.name_match ? "=" : '≠'}</b></p>
              </div>}
              </div>
              
              <div className='col-md-12 mt-3 mb-3 col-lg-12'  >
              <div  style={{background: '#E4E7E9',minHeight:'295px'}}>
            <p  className="no-margin audit_kyc_text py-3 px-4" style={{color:'black'}}> {(get('language_json'))?.holder_name} </p><hr className='no-margin' style={{border:'1px solid black'}}/>

            <div className='p-3 '>
            <div className='d-flex align-items-center  align-items-center my-2 mx-2 '>
               <div  className={`${(((this.props.Datas.responseData.audited_by== get("user_id")) || (this.props.Datas.responseData.audited_by==null ))  && this.props.Datas.active_section.pending_with !="rm")?"":'disable_div'}`}>
                  <OverlayTrigger  placement="top"  delay={{ show: 250, hide: 400 }} overlay={  <Tooltip id="button-tooltip">  click to change <br/> the Mobile Operator   </Tooltip>}>     
                    <AiFillEdit className='pointer mr-2' color='black' size={15} onClick={()=>{this.props.previous_comments_section(`witness_Mobile_Verification_${idx}`)}}/>
                  </OverlayTrigger>
                </div>

              <img src={`/img/${ item.witness_mob_num_serv_prvdr_code}_logo_color.png`} style={{width:'60px',height:'30px'}}/>
                    {/* <p className='no-margin text-white col-4 business_operations_text '>  COUNTRY</p> */}
                <p className='col-8 pl-4 no-margin audit_kyc_text' style={{color:'black'}}> <b>{ item.witness_mobile_num}</b></p>
            </div>
            {(this.props.Datas[`witness_Mobile_Verification_${idx}`] ) && (this.props.Datas.mobile_operators)?
                  <div>
                    { this.props.operators_selection("witness_mob_num_serv_prvdr_code","witness_mobile_num",`witness_Mobile_Verification_${idx}`,"witness Mobile Number 1")}
                  </div>:''
              }
            {(this.props.Datas.seconds[idx+15]!=0 && (!item.hasOwnProperty('holder_name')) && get('app_config').audit_kyc_line[this.props.Datas.cust_reg_json.agreements[idx].witness_mob_num_serv_prvdr_code]=="ussd") ?
            this.checkbox_spinner()
            :
              <>
            {item.hasOwnProperty('holder_name') ?

            <>
            {item.name_match ?
            <>
              <p  className="no-margin  mt-4 mb-2 pt-3 " style={{fontSize:'14px',color:'black'}}>Name as per {item.witness_mob_num_serv_prvdr_code.value} records</p>
              <h4 className='my-1 mt-3 px-3 py-2 audit_kyc_text mb-3' style={{background:'rgba(0, 0, 0, 0.3)',color:'black'}}><b>{ item.holder_name}</b> </h4>
              </>
            :
            <div> 
              <h2 className='my-1 mt-3 px-3 py-2 audit_kyc_text '  style={{background:'rgba(0, 0, 0, 0.3)'}}><b>{item.holder_name}</b> </h2>
              <div className='mt-4' style={{color:'black'}} ><label className='audit_kyc_text-black no-margin'>Account Holder Name Mismatch Reason </label>
              {item.hasOwnProperty("mismatch_reason") ?  <b><p className='audit_kyc_text no-margin 'style={{color:'black'}} >{dd_value(item.mismatch_reason)}</p></b>:
                <select id="holder_name_mismatch_reason" className = "form-control col-md-12"  value={this.props.Datas[`agreements_witness_mismatch_reason_${idx}`] ? this.props.Datas[`agreements_witness_mismatch_reason_${idx}`]:''} type="text" required="required" onChange={(e)=>this.props.account_owner_name_handler(e.target.value,`agreements_witness_mismatch_reason_${idx}`,null,null,null,null,null,"agreements_verification")} style={{color:'black'}}>
                  {this.getSelectOptions("holder_name_mismatch_reason")}
                </select>
                }
                </div>

                { (this.props.Datas[`agreements_witness_mismatch_reason_${idx}`] =="retrieved_franchise_name" )&&
                     <div className='col-12 no-padding'>
                     <div class="form-group mt-4 mb-2">
                     <input type="text" className='p-3' id="lname" name="lname" style={{width:'100%',background:'#a0a2a3',color:'black',border:'hidden',fontSize:'14px'}} autocomplete="off" placeholder='ENTER HOLDER NAME' onChange={(e)=>this.props.account_owner_name_handler(e.target.value,`agreements_witness_name_${idx}`)}/>
                   </div>
                   
                   <FileUpload className="audit_kyc_file_upload" lead_id = {this.props.lead_id} id="account_holder_name_proof"  mode = "create" entity ='leads' type="image"          
                     onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}} onCreateUpload={this.props.setAuditLeadState} />
                   <div className='mt-2'>
                   <button type="button" class="btn btn-info btn-sm " disabled={(this.props.Datas.lead.account_holder_name_proof && this.props.Datas[`agreements_witness_name_${idx}`]) ? false :true} onClick={()=>this.props.file_upload_submit_handler(this.props.Datas[`agreements_witness_name_${idx}`],`witness_num_${idx}`,"account_holder_name_proof",item.witness_mobile_num)}>submit</button>
                   </div>
                   <div className='col-lg-12 p-3 mt-2 d-flex align-items-center' style={{background:'#fe9f9f'}}>
                        <p className='no-margin  pl-3' style={{color:'black',fontSize:'10px'}}> <b style={{color:'red'}}>Note : </b>If the  SMS is shown on the mobile screen, please merge them into a single image using any photo editing software and upload it.</p>
                    </div>
                   </div>
                   }
            </div>
            }

            {item.hasOwnProperty('holder_name_proof') && (this.props.Datas[`agreements_witness_mismatch_reason_${idx}`] !="retrieved_franchise_name" )&&
            <div className='m-2'>
              <DisplayImage image_path={get_dir_path(item.holder_name_proof_path,item.holder_name_proof)} style={{height: '50px',width:'90px'}} file_name={item.holder_name_proof} />
            </div>
            }
            </>
            :
            <div className='col-12 no-padding'>
              <div class="form-group mt-3 mb-4">
              <input type="text" className='p-3' id="lname" name="lname" style={{width:'100%',background:'#a0a2a3',color:'black',border:'hidden',fontSize:'14px'}} autocomplete="off" placeholder='ENTER HOLDER NAME' onChange={(e)=>this.props.account_owner_name_handler(e.target.value,`account_holder_name_${idx}`)}/>
            </div>
            
            <FileUpload className="audit_kyc_file_upload" lead_id = {this.props.lead_id} id="account_holder_name_proof"  mode = "create" entity ='leads' type="image"          
              onEditUpload={(e) => {this.handleChangeForEdit(e); this.handleSave(e, "reload")}}  onCreateUpload={(e)=>this.props.setAuditLeadState(e)} />
            <div className='mt-4'>
            <button type="button" class="btn btn-info btn-sm " disabled={(this.props.Datas.lead.account_holder_name_proof && this.props.Datas[`account_holder_name_${idx}`]) ? false :true} onClick={()=>this.props.file_upload_submit_handler(this.props.Datas[`account_holder_name_${idx}`],`witness_num_${idx}`,"account_holder_name_proof",item.witness_mobile_num)}>submit</button>
            </div>
            </div>

            }
              </>
                } 
            </div>
            </div>
              </div>
            
            </div>
            { ( item.hasOwnProperty("name_match") && !(item.name_match ))&&
                <div className='row  pb-5 justify-content-center no-margin pt-2  px-4' >
                <div className='col-lg-12 p-3 d-flex align-items-center' style={{background:'#fe9f9f'}}>
                  <h2 className='d-flex '> <b className='text-danger'>Note</b> <p className='no-margin audit_kyc_text pl-3' style={{color:'black'}}>The name of the customer doesn’t match either with customer name nor business name.</p></h2>
                </div>
                </div>
                }
              </div>
                )})}
               </div>
               }

              </div>
               </div>
              </fieldset>
               </div>
                </div>
             
              
             {this.props.Datas.active_section &&
             <div className='col-lg-12'>
             <div className='row'>
              <div className='col-6'></div>
              <div className='col-6'>
             
              <form className={`col-sm-12 mt-3 p-0 mb-3 ${(this.props.Datas.active_section[0].verify || (this.props.Datas.active_section[0].to_reassign==true) )? "disable_div":''}   ${ (((this.props.Datas.responseData.audited_by== get("user_id")) || (this.props.Datas.responseData.audited_by==null )) && this.props.Datas.active_section[0].pending_with !="rm") ?"":'disable_div'}`}> 
                   

                     <div className='d-flex row'>
                     <input type="checkbox" id="vehicle1" className='pointer mt-1 col-1' style={{height:'19px',width:'34px'}} checked={audit_kyc && audit_kyc.agreement_data_match && true} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"agreement_data_match")} />
                     <label className='pointer no-margin col-11 px-0 audit_kyc_text' for="vehicle1" style={{lineHeight:'25px'}}>{`The business name, customer name, flow cust ID, ${getLabel('national_id')}, mobile number, address and date matches with the agreement form.`}</label><br/><br/>
                     </div>
 
                     {audit_kyc && audit_kyc.hasOwnProperty("signature_match") &&
                     <div className='d-flex my-3 row '>
                     <input type="checkbox" id="vehicle2" className='pointer mt-1 col-1' style={{height:'19px',width:'25px'}} checked={audit_kyc && audit_kyc.signature_match && true} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"signature_match")} />
                     <label className='pointer no-margin px-0 audit_kyc_text col-11' for="vehicle2" style={{lineHeight:'25px'}}>{`The signature of the customer in the Float Advance Agreement matches with the signature in the ${getLabel('national_id')}`}</label><br/><br/>
                     </div>
                     }
                    {audit_kyc && audit_kyc.hasOwnProperty("witness_contact_verified") &&
                     <div className='d-flex row'>
                     <input type="checkbox" id="vehicle3" style={{height:'19px',width:'25px'}} className='pointer mt-1 col-1' checked={audit_kyc && audit_kyc.witness_contact_verified && true} onChange={(e)=>this.props.checkbox_verification(e.target.checked,"witness_contact_verified")} />
                     <label className='pointer no-margin px-0 audit_kyc_text col-11' for="vehicle3" style={{lineHeight:'25px'}}>The witness contact verified matches the name input by RM</label><br/><br/>
                     </div>
                     }
 
                 </form>
               
              </div>

             </div>
             
               </div>
               }
               </>
              </>
             }
            </>
               }
             </div>
            }
            </div>

        )        
     }
}


const mapStateToProps = (state) => {
  return {
    audit_kyc: state.common_slice,
  };
  };

export default connect(mapStateToProps)(RekycAgreementandDataConsent);
