import { listAccountProvider } from "../actions/account_provider_actions";
import { lender_list, select_market } from "../actions/common_actions";
import { updateValuesIntoRedux } from "../Redux/Slices/CommonSlice";
import { changeHeaderSlice } from "../Redux/Slices/HeaderSlice";
import { req } from "./common_helper";
import { get, set, sync_master_data } from "./storage_helper";

export const switch_market = async (country, dispatch, navigate, navigate_url = null) => {
	set('market', country);
	dispatch(changeHeaderSlice({ "market": country }));

	const country_code = country.country_code;
	const time_zone = country.time_zone;
	const status = null;
	const req = { country_code, time_zone, status };
     select_market(req)
     .then(response => {
        if (response && response.status === "success") {
			localStorage.setItem('privileges', JSON.stringify(response.data.privileges));
			sync_master_data(response.data.master_data);
			response.data.master_data
			let Country = {};
			Country["country_code"] = country_code.slice(0, -1);
			set("app_config", response.data.app_config);
			set("market_details", country);
			if (navigate_url) {
				navigate(navigate_url);
			}else{
				navigate(0);
			}
		} else {
			alert(response.message + "\n" + JSON.stringify(response.data));
		}
     })
		
	
};

export const markets_with_lender =  async (dispatch) =>{
	lender_list()
	.then(response=>{
		if(response&&response.status=="success"){
			console.log('response____>', response)
			dispatch(updateValuesIntoRedux({ "markets_with_lender":response.data  }));

		}
	})

}

export const list_acc_prvdr = async () => {
    const request = { country_code: get('market')?.country_code, time_zone: get('market')?.time_zone, status: "" };
    try {
        const response = await listAccountProvider({ ...request, status: 'enabled', biz_account: true });
        if (response?.status === "success") {
            return response.data?.list; 
        }
    } catch (error) {
        console.error('Error fetching account providers:', error);
        return []; 
    }
};


