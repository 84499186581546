import React, {useEffect, useState} from "react";
import { Config, get } from "../../../helpers/storage_helper";
import { admin_path, call_fetch } from "../../../helpers/fetch_helper";
import { amount_currency_label, short, split_amount } from "../../../helpers/common_helper";
import CustomButton from "../component/CustomButton";
import { BsArrowUp, BsArrowDown} from "react-icons/bs";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import ConfirmModal from "../../common/component/ConfirmModal";
import PropTypes from 'prop-types';
import { Modal } from "react-bootstrap";
import { IoArrowForward, IoArrowBack } from 'react-icons/io5';
import { formatNumber, lbl_amt } from "../../../helpers/product_helper";




const DisbAccountSwitch = ()=>{



    // const acc_prvdrs = ['UEZM', 'UMTN', 'UATL']

    // const switchable_providers = ['UATL']

    const acc_prvdrs = Config('manual_switch_acc_prvdrs')[get('market').country_code]

    const switchable_providers = Config('switchable_acc_prvdrs')[get('market').country_code]

    const [AccPrvdr, setAccprvdr] = useState('UATL')
    const [Market, setMarket] = useState(get('market'))

    const [AccountDetails,setAccountDetails] = useState(null)
    const [RepaymentAccount, setRepaymentAccount] = useState(null)
    const [SwitchAccount ,setSwitchAccount] = useState(null)
    const [SwapAccounts, setSwapAccounts] = useState(null)
    
    const [CurrentDisbAccount, setCurrentDisbAccount] = useState(null)
    const [disbAccount, setDisbAccount] = useState(null)

    const [Loader, setLoader] = useState(false)
    const [isSwaped,setSwaped] = useState(false);
    const [swappedStates, setSwappedStates] = useState({
        float_switch : false , 
        disbursement : false
    });
    
    const [categorizedAccounts, setCategorizedAccounts] = useState({
            disbursement: false,
            floatSwitch: false,
            repayment: false
        });
    const [swapAccount,setswapAccount] = useState(null);
    const [selectId, setSelectId] = useState(null);
    const [isTransferInitiated, setTransferInitiated] = useState(false);
    const [transferAmount, setTransferAmount] = useState(0); // Stores input value
    const [isSubmit, setisSubmit] = useState(false);
    const [data, setData] = useState([]); // State to hold the data
    const [Accordian,setAccordian] = useState({});
    const [screenWidth,setscreenWidth] = useState(0);
    const [confirmMessage,setconfirmMessage] = useState(false);
    const [separateAcc, setSeparateAcc] = useState(false);
   
    const [accounts, setAccounts] = useState({});
    const [selected, setSelected] = useState('disbursement')
    const [isSwap, setIsSwap] = useState(false);
    const [isTransferSuccess, setIsTransferSuccess] = useState(false);

    // const [separateAcc, setSeparateAcc] = useState({
    //     checked : false,
    //     label : 'disbursal'
    // });

    
    useEffect(()=>{
        get_disb_acc_details()
        setscreenWidth(window.screen.availWidth)
    },[AccPrvdr])

    const Header = ()=>{

        return acc_prvdrs.map((val)=>
            <div style={{ cursor:'pointer', opacity:AccPrvdr == val ? 1 : 0.6}} onClick={()=>handle_acc_prvdr_change(val)}>
                <img  style={{height:33, width:65}} src={`/img/${val}_logo_color.png`} className="img-fluid"/>
            </div>)

    }

    const handle_acc_prvdr_change = (acc_prvdr)=>{
        if(switchable_providers.includes(acc_prvdr)){
            setAccprvdr(acc_prvdr)
            setSelectId(null)
            setswapAccount(null)
            setSwaped(false)
            setSeparateAcc(false)
            setCurrentDisbAccount(null)
            setAccounts({});
        }
        else{
            alert(`Switching from ${AccPrvdr} to ${acc_prvdr} is currently not possible`)
        }
        
    }

   

    const get_disb_acc_details = async (alert_message = null) => {
        const disbursement_accounts = [];
        const floatSwitch_accounts = [];
        const repayment_accounts = [];
        setLoader(true);
        setSwappedStates({});
        var req = { time_zone: Market.time_zone, country_code: Market.country_code, network_prvdr_code: AccPrvdr };
    
        await call_fetch(admin_path + '/account/get_disb_acc_details', req).then((response) => {
            if (!response) {
                setLoader(false);
                return;
            }
    
            if (response.status === 'success') {
                const responseData = response.data; 
                setData(responseData);
                responseData.forEach((acc) => {
                    if (acc.type === "disbursement") {
                        disbursement_accounts.push(acc);
                    } else if (acc.type === "float_switch" || acc.is_switch_account) {
                        floatSwitch_accounts.push(acc);
                    } else {
                        repayment_accounts.push(acc);
                    }
                });
    
                const modifiedArr = [...disbursement_accounts, ...floatSwitch_accounts, ...repayment_accounts];
                setAccountDetails(modifiedArr);
                setDisbAccount(disbursement_accounts);
                setCurrentDisbAccount(disbursement_accounts);
                setRepaymentAccount(repayment_accounts);
                setSwitchAccount(floatSwitch_accounts);
    
                if (alert_message != null) {
                    alert(alert_message);
                    setLoader(false);
                    setswapAccount(null);
                } else {
                    setLoader(false);
                }
    
                return;
            }
    
            setLoader(false);
            return;
        });
    };
    
     const  findAcc = () => {
        let selectedAcc = accounts;
        if(selectedAcc.hasOwnProperty('disbursement') && selectedAcc.hasOwnProperty('float_switch')){
            return true;
        };
        return false;
      };
    const change_disb_acc = async (type=null)=>{

        if(type=="confirm"){
        setLoader(true)
        var req = {
            time_zone : Market.time_zone,
            country_code : Market.country_code,
            current_disb_acc_num: findAcc() ? disbAccount[0].acc_number :CurrentDisbAccount[0].acc_number,
            switch_acc_number : swapAccount.acc_number,
            network_prvdr_code : swapAccount.acc_prvdr_code,
            is_separate : findAcc(),
            accounts : accounts
        }

        await call_fetch(admin_path + '/account/manual_switch_disb_line', req).then((response)=>{
            if(!response){
                setLoader(false)
                setSwappedStates({})
                return
            }
            if (response.status == 'success'){
                get_disb_acc_details(response.message)
                setAccounts({})

            }

        })
       
        setIsSwap(false)
        setSwaped(false)
    }
    }

    const toggle_modal =(type=null)=>{
        if(type =="confirm"){
            change_disb_acc("confirm")
        }
        setconfirmMessage(!confirmMessage)
    }


    const button_style = {

        backgroundColor : 'rgba(26, 32, 54, 1)',
        color : 'rgba(255, 255, 255, 1)',
        fontSize : '20px',
        fontWeight:'500'

    }

    const openAccordian = (idx,key) => {
      var key = key+"_"+idx
        setAccordian({
            ... Accordian,
             [key]: !Accordian[key]
    })

    }



    const handleCheckboxChange = (value, checked, type = null) => {
       
        const newState = {
            float_switch: false,
            disbursement: false,
            [type ? type : value.type]: checked
        };
        setIsSwap(type != null ? true : false);
        setSwappedStates(newState);
    
        let toAddAcc = ((value.type == 'float_switch' && checked == true ) || (value.type == 'disbursement'  && separateAcc && type != 'float_switch') || (value.type == 'repayment' && type == 'float_switch'))  ? disbAccount : (value.type == 'disbursement' && !value.is_switch_account)  ?  SwitchAccount : [];
      
        if(toAddAcc != null && toAddAcc.length > 0 ){
          const updatedAccounts = RepaymentAccount.filter(account => account.acc_number !== value.acc_number);
            setSwapAccounts([...updatedAccounts, toAddAcc[0]])
            
        }else{
            if(type != null ){
                setSelected(type);
                const updatedAccounts = RepaymentAccount.filter(account => account.acc_number !== value.acc_number);
                setSwapAccounts(updatedAccounts);
            }else{
                setSwapAccounts(RepaymentAccount)
            }
        }
        
        setSelectId(null)
        setSwaped(checked)
        setswapAccount(null)
        setCurrentDisbAccount([value])
        if(type != 'float_switch'){
            setAccounts({});
        }
       
    };

    const handleSeparate = (val, checked) => {
        // setSeparateAcc({
        //     'checked' : checked,
        //     'label' : val
        // })
        setSeparateAcc(checked)
    }

    const handleSetAccounts = (val) =>{
        // if(!separateAcc) return;
        const key = Object.keys(swappedStates).find(k => swappedStates[k] === true);
        if (key) {
            setAccounts({
                ...accounts,
                [key]: val.acc_number

            });
        }

    }
    const handleTransferClick = (value, checked, type = null) => {
        if (type != null) {
            setSelected(type);
            const categorizedAccounts = RepaymentAccount.filter(
                (account) => account.acc_number !== value.acc_number
            );
            setSwapAccounts(categorizedAccounts);
    
            
            setTransferInitiated(checked);
    
           
        } else {
            console.warn("Type is null; no action taken.");
        }
    };
    
    
      const handleInputChange = (e) => {
        const value = e.target.value;
          setTransferAmount(value); 
        
      };
      const handleConfirm = async () => {
        if (!swapAccount) {
            alert("Please select an account before confirming.");
            return;
        } 
    
        const req = { 
            data: {
                acc_number: swapAccount.acc_number,
                acc_prvdr_code: swapAccount.acc_prvdr_code,
                balance: swapAccount.balance,
                dr_threshold_limit: swapAccount.dr_threshold_limit,
                id: swapAccount.id,
                thresh_hold_limit: swapAccount.thresh_hold_limit,
                total_cr_amt: swapAccount.total_cr_amt,
                total_txn_amount: swapAccount.total_txn_amount,
                txn_limit: swapAccount.txn_limit,
                type: swapAccount.type,
                token: swapAccount.token,
                transferAmount: transferAmount, 
                CurrentDisbAccount:CurrentDisbAccount
            }
        };
    
        try {
            const response = await call_fetch(admin_path + '/account/internal_trans', req);
    
            if (!response) {
                setisSubmit(false);
                alert('No response from the server. Please try again later.');
                return;
            }
    
            if (response.status === 'success') {
                setIsTransferSuccess(true); 
            }
             else {
                alert(response.message || 'Transfer failed. Please try again.');
            }
        } catch (error) {
            console.error('Error during transfer:', error);
            alert('An error occurred. Please try again later.');
        } finally {
            setisSubmit(false);
            setTransferInitiated(false);
        }
    };
    
   

    const handleCancel = () => {
        setisSubmit(false); 
    };

    const amount_format = (amount) => {
        let value = formatNumber(amount)
        return value + " " +  Market.currency_code;	
    }



    const switchAccount=(val,idx,type,length=null)=>{
        return(
            <div className={` ${type=="repayment" ? "mx-3 mb-3" :" mb-4"} my-2 `}>

                {type!="repayment" &&
                    <p className="m-0 text-white"> {(val.type == "disbursement") ?"Default Disbursal Account":((val.type == "float_switch" )|| (val.is_switch_account))? "Switch Account": (AccountDetails[idx-1].type!="repayment")? `Repayment Account${RepaymentAccount.length > 1 ? "s" :""}`:""}</p>
                }

                <div className= {` ${type=="repayment" ? "row col-12 d-flex mx-0":""} swap_acc_div my-2 mt-4 px-3 py-3 rel_position`}>
                    {(type=="repayment") &&
                        <div className="col-1 pl-0">
                             <input type="radio" className="switch_acc_radio" id={`repayment_acc_${idx}`} checked={(selectId && selectId==idx) ? true : false}  onChange={(e)=>{handleSetAccounts(val);setswapAccount(val);setSelectId(idx)}} name="repayment_acc" />

                        </div>
                    }

                    <div className={`${type=="repayment" ?"col-11":""}`}>
                        
                        <div className="row m-0 col-12 px-0 d-flex align-items-center justify-content-around">
                            <div className={`col-8 ${type=="disbursement" ? "pr-0":"px-0"} `}>

                                {((val.type=="disbursement" || val.type == 'float_switch') && type != 'repayment') &&
                                    <>
                                    <p className="enabled_bg m-0 px-2 py-1 text-white"> Enabled</p>

                                    <p className="mb-0 my-3 text-white agreementDateHeaderBoxdate"><b>{val.type == 'float_switch' ? "Switch Account" :val.is_switch_account ? "Disbursal & Switch Account" : "Disbursal Account" }</b> <span className="sizem"> (Primary)</span></p>
                                    </> 
                                }

                                {type=="repayment" && 
                                    <>
                                        <p className="mb-0 my-3 text-white agreementDateHeaderBoxdate"><b>{val.type == 'disbursement' ? "Disbursal" : val.type == 'float_switch' ? "Switch" : "Repayment" } Account </b></p>
                                    </>
                                }
                                <div className="d-flex align-items-center my-2">
                                    <img src={`/img/${val.acc_prvdr_code}${val.acc_prvdr_code == "UATL" ? "_disbursal_logo.png":"_logo_color.png"}`} style={{width:'51px',height:'23px'}}/>
                                
                                    <p className="mb-0 ml-2 text-white agreementDateHeaderBoxdate"><b> {val.acc_number} </b> </p>

                                    {val.is_switch_account && !isSwaped &&
                                        <>
                                            <input type="checkbox" className="ml-5" onChange={(e)=>{handleSeparate('disbursal', e.target.checked)}}/>
                                            <p className="mb-0 ml-2 text-white">Separate Accounts</p>
                                        </>
                                    }

                                </div>
                            </div>
                    
                            <div className={` ${(type=="repayment") ? "d-flex justify-content-end":"" } col-4 px-0`}>
                            {!isTransferInitiated ? (
  (val.type === "disbursement" || val.type === "float_switch") && type !== "repayment" ? (
    <div className="swap_button r" id="swap_button-9">
      <input type="checkbox" className="swap_checkbox" checked={swappedStates[val.type] || isSwap} onChange={(e) => { handleCheckboxChange(val, e.target.checked);}}/>
      <div className="toggle_left">
        <span></span>
      </div>

      <div className="toggle_right">
        <span className={ swappedStates[val.type] || isSwap  ? "swap_text_left" : "swap_text_right" }>  Swap </span>
      </div>
    </div>
  ) : (
    <div
      className={`${ type !== "repayment" ? "d-flex" : ""  } align-items-center justify-content-end`} >
      <p className="enabled_bg mb-0 mx-4 px-2 py-1 text-white"> Enabled</p>
      <div
        className={`${  type === "repayment" ? "d-flex justify-content-center ml-2 col-12 mt-2" : "" }`} >
        {Accordian[`${type}_${idx}`] ? (
          <IoIosArrowDropup
            className="mr-2 pointer"
            onClick={() => {
              openAccordian(idx, type);
            }}
            size={25}
            color="white"
          />
        ) : (
          <IoIosArrowDropdown
            className="mr-2 pointer"
            onClick={() => {
              openAccordian(idx, type);
            }}
            size={25}
            color="white"
          />
        )}
      </div>
    </div>
  )
) : null}

                            </div>
                    
                    
                         </div>

                        <p className="swap_acc_bottom disable_div mt-3"></p>
                        <div >
                            {(Accordian[`${type}_${idx}`] || (((val.type =="disbursement") || (val.type =="float_switch")) && type!='repayment')) &&
                                <>
                                    {(val.type!="repayment"  || (type=="repayment")) &&
                                        <div className={`${type=="disbursement" ? "mx-3":""}`}>
                                            <div className={`d-flex align-items-center`}>
                                                <p className="m-0 text-white sizem"> {(val.is_switch_account ||  val.type=="float_switch")? "Float Out": "Today’s Debit"}</p>
                                                {(val.type=="disbursement" || val.type=="float_switch") &&  (val.thresh_hold_limit != null && val.total_txn_amount >= val.thresh_hold_limit ) &&
                                                    <p className="disabled_status my-0 ml-5">Transaction limit reached </p>
                                                }
                                            </div>
                                            <p className="mb-0 mt-3 text-white agreementDateHeaderBoxdate"><b>{amount_format(val.total_txn_amount)} {val.txn_limit ? `/ ${amount_currency_label(val.txn_limit, 0, Market.currency_code)}` : ''} </b> 
                                            <span className="text-danger ml-3" style={{fontSize:'12px'}}>
                                            Debited <BsArrowUp color='red'/>

                                            </span> </p>
                                        
                                            { ((val.type=="disbursement" || val.type =="float_switch") && type != 'repayment' ) &&
                                                <p className="mb-0   text-white" style={{color:'#576882'}}>Swap the account when the disbursement limit reached </p>
                                            }
                                        </div>
                                    }
                                    <div className={`${type=="disbursement" ? "mx-3":""}`}>
                                        <p className="mb-0 mt-3 text-white  sizem"> {(val.is_switch_account || val.type=="float_switch") ? "Float In": "Today’s Credit"} </p>
                                        <p className="mb-0 mt-3 text-white agreementDateHeaderBoxdate"><b>{amount_format(val.total_cr_amt)} {val.txn_limit ? `/ ${amount_currency_label(val.txn_limit, 0, Market.currency_code)}` : ''} </b> 
                                        <span className="text-success ml-3" style={{fontSize:'12px'}}>
                                        Credited <BsArrowDown className="text-success"/>

                                            </span>
                                        </p>
                                    </div>
                                    <p className="swap_acc_bottom disable_div mt-3"></p>

                                </>
                            }
                        </div>
                    
                        <div className={`d-flex align-items-center mb-1 justify-content-between ${type=="disbursement" ?"mx-3":""}`}>
                            <p className="mb-1  text-white agreementDateHeaderBoxdate">Available Balance</p>

                            <p className="mb-1  text-white agreementDateHeaderBoxdate"><b>{split_amount(val.balance, Market.currency_code)}</b></p>

                        </div>
                        {/* {(
                        <div className={`d-flex justify-content-end ${type === "disbursement" ? "mx-3" : ""}`}>
                             {!isTransferInitiated && !isSwaped && (
                        <button className="btn btn-primary" onClick={() => handleTransferClick(val.acc_number, true, "TransferType")}>Transfer</button>
                             )}
                        </div>
                         )} */}
                          </div>
                </div>
                {type=="repayment" &&  (idx != length )&&
                
                    <p className="swap_acc_bottom disable_div mt-3 " style={{border:'1p solid '}}></p>

                }
            </div>
            )
    }

        
    

    

    return(


        <div className={confirmMessage?"disable_div":""}>
            <div className="d-flex  row justify-content-between" style={{marginTop:'40px'}}>
                <div className="d-flex clo-6 mx-2" style={{fontSize:"27px", fontWeight:500, color:"#DADCDF"}}>
                    Swap Account Lines
                </div>
                <div className="d-flex col-2 justify-content-between ml-auto">
                    {Header()}
                </div>
            </div>

            {
                Loader && Loader ? (
                    <div className=" d-flex justify-content-center" style={{marginTop:'30vh'}}>
                        <div className="acc_switch_loader" ></div>

                     </div>)
                     : 
                (
                    <div className={`mt-5 row d-flex  mx-0 `}>
                    <div className="col-md-12 col-sm-12 col-lg-5">
                        {AccountDetails && AccountDetails.map((val,idx)=>{
                                return(
                                switchAccount(val,idx,"disbursement")
                                )    
                        }
                        
                        )}
                    </div>
                    {isSwaped && !isTransferInitiated &&
                    <div className={`col-md-12 col-sm-12 py-2 col-lg-5 ${screenWidth > 992 ? "ml-5":""}`}>
                      <div className="disb_account_list mt-5">
                      <p className="mb-0 d-flex justify-content-center text-white disb_acc_bg w-100 px-3 py-3 agreementDateHeaderBoxdate "><b style={{fontSize:'20px'}}>{`Choose your ${swappedStates.float_switch ? 'switch' : (swappedStates.disbursement && CurrentDisbAccount[0].is_switch_account && !separateAcc) ? 'disbursal & switch' : 'disbursal'} account`}</b> </p>

                      {SwapAccounts && SwapAccounts.map((val,idx)=>{
                            // if(val.type == 'repayment' && val.switch_btn == true){
                                return(
                                    switchAccount(val,idx+1,"repayment",SwapAccounts.length-1   )
                                )
                            
                            // }
                        }
                        
                        )}

                        {
                            separateAcc ? 
                                <div className="d-flex align-items-center  mb-3   my-1 justify-content-center">
                                    <button type="button" class="btn btn-primary" disabled={swapAccount ? false:true} onClick={()=>{setSeparateAcc(false);handleCheckboxChange(swapAccount,true, 'float_switch')}}>Next</button>
                                </div>
                                :
                                <div className="d-flex align-items-center  mb-3   my-1 justify-content-center">
                                    <button type="button" class="btn btn-primary" disabled={swapAccount ? false:true} onClick={()=>{setconfirmMessage(true)}}>Submit</button>
                                </div>
                        }

                        
                         {confirmMessage && !isTransferInitiated && 
                         <ConfirmModal 
                            alert_message={ selected == 'float_switch' ?  <span>Are you sure you want to switch the accounts?</span> : <span>Are you sure you want to switch your <b>{swapAccount.type == 'repayment' ? "Repayment Account" : (swapAccount.type == 'float_switch' ? "Float Switch" : "Disbursement")} ({swapAccount.acc_number})</b> to the {CurrentDisbAccount[0].type == 'float_switch' ? "switch" : "disbursal"} account?</span>} 
                            note ={" The default disbursal and switch account resets daily."} is_toggle={(a)=>toggle_modal(a)} is_swap_acc={true}/>
                         
                         }

                      </div>
                    </div>
                    }

{isSubmit && (
                <Modal className="confirm_modal"  show={isSubmit}  onHide={handleCancel} >
                
                   <Modal.Header closeButton style={{ borderBottom: '1px solid gray', paddingBottom: '10px' }}>
                    <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ color: 'white' }}>
                        <div>
                            <span>Are you sure you want to transfer the below amount to this <b>Account ({swapAccount?.acc_number})</b>?</span>
                            <div style={{ marginTop: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                            <label htmlFor="transferAmount" className="form-label mb-0 mr-2"style={{ marginRight: '10px', fontWeight: 'bold' }} >Transfer Amount </label>
                                <input  autoComplete="off"  type="number" className="form-control" placeholder="0"  id="transferAmount" value={transferAmount}   onChange={(e) => handleInputChange(e)}
                                    style={{  width: '150px',  padding: '10px',  border: '1px solid #ced4da', borderRadius: '5px',  pointerEvents: 'auto',  }}  />
                            </div>
             <p className="text-blue mt-2" style={{ marginTop: '15px', color: 'blue', fontSize: '0.9rem' }} >Note: The entered amount will be transferred to the selected account.</p>
                        </div>

                        <div style={{ marginTop: '20px', display: 'flex',  justifyContent: 'flex-end',  gap: '20px',}} >
                            
                            <button
                                type="button" style={{backgroundColor: '#2988DB', border: 'none', padding: '10px 20px', fontSize: '1rem', borderRadius: '20px',color: '#fff',cursor: 'pointer', pointerEvents: 'auto', }} onClick={handleConfirm} > Confirm
                            </button>
            
                            <button type="button" style={{ backgroundColor: '#6c757d',  border: 'none',padding: '10px 20px', fontSize: '1rem', borderRadius: '20px', color: '#fff', cursor: 'pointer',pointerEvents: 'auto', }} onClick={handleCancel}> Cancel </button>
                        </div>
                    </Modal.Body>
                
            </Modal>
                        )}
                        {isTransferSuccess && (
    <Modal
        className="confirm_modal"
        show={isTransferSuccess}
        onHide={() => setIsTransferSuccess(false)}
    >
           {/* First Arrow on the First Line */}
       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <IoArrowBack size={50} color="#2988DB" style={{ fontWeight: 'bold' }} />
       </div>

       {/* Second Arrow on the Second Line */}
       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           <IoArrowForward size={50} color="#2988DB" style={{ fontWeight: 'bold' }} />
       </div>

        <Modal.Body style={{ color: 'white', textAlign: 'center' }}>
        <div style={{ margin: '0', fontWeight: 'bold' }}>
           <p >Transfer</p>
           <p >Initiated</p>
           <p >Successfully!</p>
       </div>


            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center',  }} >
                <button type="button" style={{ backgroundColor: '#2988DB', border: 'none',padding: '10px 20px', fontSize: '1rem',borderRadius: '20px', color: '#fff', cursor: 'pointer', pointerEvents: 'auto', }} onClick={() => setIsTransferSuccess(false)} > OK </button>
            </div>
        </Modal.Body>
    </Modal>
)}


{isTransferInitiated && (
  <div className={`col-md-12 col-sm-12 py-2 col-lg-5 ${ screenWidth > 992 ? "ml-5" : "" }`} >
    <div className="disb_account_list mt-5">
      {/* Title for Destination Account */}
      <p className="mb-0 d-flex justify-content-between align-items-center text-white disb_acc_bg w-100 px-3 py-3 agreementDateHeaderBoxdate">
        <b style={{ fontSize: "20px" }}>Choose your destination account</b>
        <button
          className="close-button"
          style={{ background: "transparent",border: "none", color: "white", fontSize: "20px", cursor: "pointer", }} onClick={() => setTransferInitiated(false)}  >
          &times;
        </button>
      </p>

      {/* Display Accounts */}
      {data && data.length > 0 ? (
        data
          .filter((val) => val.acc_number !== disbAccount[0].acc_number) // Exclude the current account
          .map((val, idx) =>
            switchAccount(val, idx + 1, "repayment", data.length - 1)
          )
      ) : (
        <p className="text-center text-muted py-3"> No accounts available to display. </p>
      )}

      {/* Submit Button */}
      <div className="d-flex align-items-center mb-3 my-1 justify-content-center">
        <button type="button" className="btn btn-primary" disabled={!swapAccount}  onClick={() => setisSubmit(true)} > Submit </button>
      </div>
    </div>
  </div>
)}


                    </div>
             
               ) 
            }
        </div>
    )

}


export default DisbAccountSwitch